
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/delta/en-UK.json";

import EditInvoiceProject from "@/components/CRUXEntity/EditInvoiceProject.vue";
import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";

import { GET_INDEPENDENT_INVOICES } from "@/graphql/invoice/queries";

import { Invoice } from "@/helpers/invoice/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const invoices = ref();
    const clickedInvoice = ref();

    const loading = ref(false);

    return {
      t,
      invoices,
      clickedInvoice,
      loading,
    };
  },
  data() {
    return {
      enUK,
      GET_INDEPENDENT_INVOICES,
      isModalVisible: false,
    };
  },
  emits: [],
  provide: {
    columnsOrder: {
      company: null,
      date: null,
      invoiceNumber: null,
      total: null,
      invoiceStatus: null,
    },
  },
  methods: {
    showModal(invoice: Invoice): void {
      this.clickedInvoice = invoice;
      this.isModalVisible = true;
    },
    closeModal(): void {
      this.isModalVisible = false;
    },
  },
  components: {
    EditInvoiceProject,
    PaginatedEntitiesList,
  },
});
