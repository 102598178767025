
import { defineComponent, ref, PropType } from "vue";
import Popover from "@/components/Popover.vue";

export default defineComponent({
  setup() {
    const popover = ref(null);
    return { popover };
  },

  props: {
    reference: {
      type: HTMLElement as PropType<HTMLElement | null>,
      required: true,
    },
  },
  components: {
    Popover,
  },
});
