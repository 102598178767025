import { Entity, PaginatedResult } from "../typesDefinition";

export function removeNonIdenticalPaginatedEntity<T extends Entity>(
  comparator: (t1: T, t2: T) => boolean,
) {
  return (entityToRemove: T, cacheResult: PaginatedResult<T>): T[] => {
    const entities = Array.from(cacheResult.items);
    const index = entities.findIndex((entity) =>
      comparator(entity, entityToRemove),
    );
    if (index < 0) return entities;
    entities.splice(index, 1);
    return entities;
  };
}

export function removePaginatedEntity<T extends Entity>(
  entityToRemove: T,
  cacheResult: PaginatedResult<T>,
): T[] {
  const entities = Array.from(cacheResult.items);
  const index = entities.findIndex((entity) => entity.id === entityToRemove.id);
  if (index < 0) return entities;
  entities.splice(index, 1);
  return entities;
}

export function removeEntity<T extends Entity>(
  entityToRemove: T,
  cacheResult: T[],
): T[] {
  const entities = Array.from(cacheResult);
  const index = entities.findIndex((entity) => entity.id === entityToRemove.id);
  if (index < 0) return entities;
  entities.splice(index, 1);
  return entities;
}
