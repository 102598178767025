
import { computed, defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/theme/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { CREATE_THEME } from "@/graphql/project/mutations";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    return { t };
  },
  data() {
    return {
      enUK,
      entityProps: {
        name: undefined,
      },
      unmutableEntityProps: { projectId: this.$route.params.id },
      validationRules: computed(() => ({
        name: {
          required,
        },
      })),
      CREATE_THEME,
      messages: {
        formButtonLabel: this.t("buttons.add"),
        error: this.t("errors.add"),
        success: this.t("success.add"),
        title: this.t("titles.add"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
