import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MutateEntity = _resolveComponent("MutateEntity")!

  return (_openBlock(), _createBlock(_component_MutateEntity, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
    enUK: _ctx.enUK,
    entityProps: {
      id: _ctx.client.id,
      name: _ctx.client.name,
    },
    validationRules: _ctx.validationRules,
    updatePaginatedQueryCache: _ctx.updateClient,
    mutation: _ctx.UPDATE_CLIENT,
    mutationName: "updateClient",
    messages: _ctx.messages
  }, null, 8, ["enUK", "entityProps", "validationRules", "updatePaginatedQueryCache", "mutation", "messages"]))
}