
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useQuery, useResult } from "@vue/apollo-composable";
import { useToast } from "vue-toastification";

import enUK from "@/locales/project/en-UK.json";

import EntitiesList from "@/components/list/EntitiesList.vue";
import RecurringOrderDetails from "@/components/order/RecurringOrderDetails.vue";

import { RecurringOrder } from "@/helpers/order/typesDefinition";
import { GET_PROJECT_RECURRING_ORDERS } from "@/graphql/project/queries";
import checkHasAccess from "@/helpers/permissions/checkHasAccess";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: false,
    },
  },
  emits: ["openModal"],
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const { result, loading, onError, onResult } = useQuery(
      GET_PROJECT_RECURRING_ORDERS,
      () => ({
        id: props.id,
      }),
    );

    const recurringOrders = useResult(
      result,
      [],
      (data: { project: { recurringOrders: RecurringOrder[] } }) => {
        return data.project.recurringOrders;
      },
    );

    onError((error) => {
      console.log(error);
      toast.error(
        t("errors.getRecurringOrders", {
          message: t("errors.internal"),
        }),
      );
    });

    const hasAdminAccess = ref(false);

    onMounted(async () => {
      hasAdminAccess.value = await checkHasAccess(
        result.value?.project?.client?.id,
        props.id,
        "admin",
        toast,
        t("errors.getUserDetails"),
      );
    });

    onResult(async (result) => {
      const clientId = result.data?.project?.client?.id;
      if (!hasAdminAccess.value)
        hasAdminAccess.value = await checkHasAccess(
          clientId,
          props.id,
          "admin",
          toast,
          t("errors.getUserDetails"),
        );
      await nextTick();
    });

    const isModalDetailsVisible = ref(false);
    const modalDetailsId = ref("");

    function openOrderDetailsModal(orderId: string): void {
      isModalDetailsVisible.value = true;
      modalDetailsId.value = orderId;
    }

    function closeOrderDetailsModal(): void {
      isModalDetailsVisible.value = false;
      modalDetailsId.value = "";
    }

    if (props.orderId) openOrderDetailsModal(props.orderId);

    return {
      t,
      loading,
      recurringOrders,
      isModalDetailsVisible,
      modalDetailsId,
      openOrderDetailsModal,
      closeOrderDetailsModal,
      hasAdminAccess,
    };
  },
  provide: {
    columnsOrder: {
      title: null,
      startDate: null,
      endDate: null,
      status: null,
      price: null,
      company: null,
    },
  },
  components: { EntitiesList, RecurringOrderDetails },
});
