import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "paginatedEntitiesList" }
const _hoisted_2 = {
  ref: "endOfList",
  id: "endOfList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntitiesList = _resolveComponent("EntitiesList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_ctx.paginatedResult)
        ? (_openBlock(), _createBlock(_component_EntitiesList, {
            key: 0,
            onUpdateCheckBoxes: _ctx.updateCheckBoxes,
            checkBoxes: _ctx.modelCheckBoxes,
            "onUpdate:checkBoxes": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelCheckBoxes) = $event)),
            hasCheckBox: _ctx.hasCheckBox,
            formatters: _ctx.formatters,
            elements: _ctx.paginatedResult.items,
            title: _ctx.title,
            onGoEntityDetails: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('actionOnClick', $event))),
            hasIcon: _ctx.hasIcon
          }, null, 8, ["onUpdateCheckBoxes", "checkBoxes", "hasCheckBox", "formatters", "elements", "title", "hasIcon"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", { class: "loading apollo" }, _toDisplayString(_ctx.t("loading")), 513), [
        [_vShow, _ctx.loading]
      ])
    ], 512)
  ], 64))
}