
import { computed, defineComponent } from "vue";
import { decimal, minValue, required } from "@vuelidate/validators";
import { ApolloCache } from "@apollo/client";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/project/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { CREATE_PROJECT } from "@/graphql/project/mutations";
import { GET_CLIENTS } from "@/graphql/client/queries";

import ProjectsCacheInfoHolder from "@/helpers/project/ProjectsCacheInfoHolder";
import { insertNewEntityPaginated } from "@/helpers/cache/insertNewEntity";
import { paymentOptions } from "@/helpers/order/utils";
import {
  Project,
  ProjectPaginatedResponse,
} from "@/helpers/project/typesDefinition";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    function insertNewProject(
      cache: ApolloCache<ProjectPaginatedResponse>,
      mutatedItem: Project,
    ) {
      return updatePaginatedQueryCache(
        cache,
        mutatedItem,
        ProjectsCacheInfoHolder,
        insertNewEntityPaginated,
      );
    }

    return { t, insertNewProject };
  },
  data() {
    return {
      enUK,
      entityProps: {
        name: undefined,
        clientId: undefined,
        defaultPaymentOption: undefined,
        budget: undefined,
      },
      validationRules: computed(() => ({
        name: {
          required,
        },
        budget: {
          decimal,
          minValue: minValue(1),
        },
        clientId: {
          required,
        },
        defaultPaymentOption: {
          required,
        },
      })),
      formQueries: {
        clientId: GET_CLIENTS,
      },
      formOptions: {
        defaultPaymentOption: paymentOptions,
      },
      CREATE_PROJECT,
      messages: {
        formButtonLabel: this.t("buttons.add"),
        error: this.t("errors.add"),
        success: this.t("success.add"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
