import { Project, WorkingPeriod } from "../project/typesDefinition";
import { isInt } from "../utils";
import { Order, OrderLine, RecurringOrder } from "./typesDefinition";
import dayjs, { Dayjs } from "dayjs";
import { Delta } from "@/helpers/delta/typesDefinition";
import { isDelta } from "@/helpers/delta/utils";

export function isOrder(order: unknown): order is Order {
  return (order as Order).__typename === "Order";
}

export function orderDefaultTitle(
  project: Project,
  orderInTitle: string,
  nextOrderNumber: number,
): string {
  return `${project.client.name} - ${project.name} - ${orderInTitle} ${nextOrderNumber}`;
}

export const formatMonthDate = (date: Dayjs): string => {
  return date.format("MMMM YY");
};

export const formatMonthDateShort = (date: Dayjs): string => {
  return date.format("MMM YY");
};

export const createPeriodFormatter =
  (formatter: (date: Dayjs) => string) =>
  (period: Order | RecurringOrder | Delta | WorkingPeriod): string => {
    if (isDelta(period) || isSingleMonth(period))
      return formatter(dayjs(period.startDate));
    return `${formatter(dayjs(period.startDate))} - ${formatter(
      dayjs(period.endDate).subtract(1, "day"),
    )}`;
  };

export const formatPeriodDate = createPeriodFormatter(formatMonthDate);

export const formatPeriodDateShort =
  createPeriodFormatter(formatMonthDateShort);

export const paymentOptions = {
  post_paid: "Post-paid",
  pre_paid: "Retainer",
} as { [key: string]: string };

export const legalNotices = {
  post_paid: `By confirming this order, you acknowledge that this order is irrevocable.
  At the end of each month, 26lights will calculate the Time Logged by the consultants and issue an Invoice. This Invoice is payable ten (10) days from date of invoice.
  The client acknowledges that the Time logged can be different from the Time Ordered, consequence on one hand of the illness of the consultant’s holidays, emergency on another project and on the other hand of new clients requests orally or by email or because the work to be performed requires more time than expected.
  Any amount not paid within the contractually agreed time limits, which may be extended by mutual agreement between the Parties, will bear interest at three (3) times the statutory interest rate until payment of that sum unpaid. 26lights reserves also the right to suspend the work of its consultants.`,
  pre_paid: `By confirming this order, you acknowledge that this order is irrevocable. A Retainer Invoice will be sent by email with the amount detailed in this Order. This Retainer Invoice is payable ten (10) days from date of invoice.
  At the end of each month, 26lights will calculate the Time Delta between the Time Ordered and the Time Logged by the consultants. If the Time Logged is superior to the Time Ordered, a Delta Invoice will be issued. If the Time Logged is inferior a Credit Note will be issued. This Delta Invoice is payable ten (10) days from date of invoice.
  The client acknowledges that the Time Delta can be the consequence on one hand of the illness of the consultant’s holidays, emergency on another project and on the other hand of new clients requests orally or by email or because the work to be performed requires more time than expected.
  Any amount not paid within the contractually agreed time limits, which may be extended by mutual agreement between the Parties, will bear interest at three (3) times the statutory interest rate until payment of that sum unpaid. 26lights reserves also the right to suspend the work of its consultants.`,
} as { [key: string]: string };

export const sortedStatusLabels = [
  "draft",
  "to_validate",
  "late_approval",
  "approved",
  "retainer_sent",
  "retainer_reminder_1",
  "retainer_reminder_2",
  "retainer_paid",
  "performed",
  "delta_draft",
  "delta_invoiced",
  "delta_reminder_1",
  "delta_reminder_2",
  "delta_reminder_3",
  "closed",
  "rejected",
  "late_payment",
] as const;

export type StatusLabelsKey = (typeof sortedStatusLabels)[number];

export function transformUnitIfQuantityNotInteger(
  quantity: number,
  unit: number,
): { quantity: number; unit: number } {
  if (isInt(quantity)) return { quantity, unit };
  if (unit === 480)
    return {
      quantity: quantity * 8,
      unit: 60,
    };
  if (unit === 60)
    return {
      quantity: quantity * 60,
      unit: 1,
    };
  throw new Error("Unit should be either hours or days");
}

export function transformOrderLineToDisplay(orderLine: OrderLine): OrderLine {
  if (orderLine.unit === 1) {
    return {
      ...orderLine,
      unit: 60,
      quantity: orderLine.quantity / 60,
    };
  }
  if (orderLine.unit === 60 && orderLine.quantity >= 16) {
    return {
      ...orderLine,
      unit: 480,
      quantity: orderLine.quantity / 8,
    };
  }
  return orderLine;
}

export function isSingleMonth(
  order: Order | RecurringOrder | WorkingPeriod,
): boolean {
  return dayjs(order.startDate).isSame(
    dayjs(order.endDate).subtract(1, "day"),
    "month",
  );
}
