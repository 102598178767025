
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isLeft: {
      type: Boolean,
      required: false,
      default: true,
    },
    leftLabel: {
      type: String,
      required: true,
    },
    rightLabel: {
      type: String,
      required: true,
    },
  },
  emits: ["update:isLeft"],
  methods: {
    changeIsRecurringOrderValue(): void {
      this.$emit("update:isLeft", !this.isLeft);
    },
  },
});
