import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592a7af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = {
  key: 0,
  class: "empty"
}
const _hoisted_4 = {
  key: 1,
  class: "empty"
}
const _hoisted_5 = {
  key: 0,
  class: "empty"
}
const _hoisted_6 = {
  ref: "endOfList",
  class: "end-of-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TileTitle = _resolveComponent("TileTitle")!
  const _component_TileBase = _resolveComponent("TileBase")!

  return (_openBlock(), _createBlock(_component_TileBase, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          (_ctx.$props.title)
            ? (_openBlock(), _createBlock(_component_TileTitle, {
                key: 0,
                title: _ctx.$props.title,
                level: 2
              }, null, 8, ["title"]))
            : _createCommentVNode("", true)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.error), 1))
          : (_ctx.emptySpace)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.emptySpace), 1))
            : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.loading), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, null, 512)
    ]),
    _: 3
  }))
}