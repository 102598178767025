
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";

import enUK from "@/locales/invoicing/en-UK.json";

import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";

import { GET_OPENED_INVOICES } from "@/graphql/invoice/queries";

import { Invoice } from "@/helpers/invoice/typesDefinition";
import { formatPrice } from "@/helpers/utils";
import EditInvoice from "@/components/CRUXEntity/EditInvoice.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    // const toast = useToast();

    const clickedInvoice = ref();
    const openedInvoices = ref();

    const loading = ref(false);

    return {
      t,
      openedInvoices,
      loading,
      clickedInvoice,
    };
  },
  data() {
    return {
      enUK,
      GET_OPENED_INVOICES,
      isEditModalVisible: false,
    };
  },
  emits: [],
  provide: {
    columnsOrder: {
      invoiceNumber: null,
      client: null,
      company: null,
      total: null,
      paid: null,
      balance: null,
      dueDate: null,
      nbReminders: null,
      promisedDate: null,
      comment: null,
    },
  },
  methods: {
    showEditModal(invoice: Invoice): void {
      this.clickedInvoice = invoice;
      this.isEditModalVisible = true;
    },
    closeEditModal(): void {
      this.isEditModalVisible = false;
    },
    formatClient(
      _value: undefined,
      _name: string,
      invoice: Invoice,
    ): string | undefined {
      return invoice.company.client.name;
    },
    formatPaid(_value: undefined, _name: string, invoice: Invoice) {
      return formatPrice(invoice.total - invoice.balance);
    },
    formatDate(value: string | undefined) {
      if (!value) return value;
      return dayjs(value).format("DD/MM/YYYY");
    },
    reminderCount(_value: undefined, _name: string, invoice: Invoice) {
      const match = invoice.invoiceStatus.match(/^reminder_(\d+)$/);
      if (match && match.length > 0) {
        return match[1];
      }
      return "-";
    },
  },
  components: {
    EditInvoice,
    PaginatedEntitiesList,
  },
});
