import gql from "graphql-tag";
import { phaseFragment } from "./fragments";
import { milestoneFragment } from "@/graphql/orcha/milestones/fragments";
import { templateActivityFragment } from "@/graphql/orcha/activities/fragments";

export const CREATE_TEMPLATE_PHASE = gql`
  mutation createTemplatePhase($templateId: ID!, $name: String!, $rank: ID!) {
    createTemplatePhase(templateId: $templateId, name: $name, rank: $rank) {
      status
      error
      data {
        ...PhaseFragment
      }
    }
  }
  ${phaseFragment}
`;

export const UPDATE_TEMPLATE_PHASE = gql`
  mutation updateTemplatePhase($id: ID!, $name: String, $rank: ID) {
    updateTemplatePhase(id: $id, name: $name, rank: $rank) {
      status
      error
      data {
        ...PhaseFragment
        milestones {
          id
          ...MilestoneFragment
          activities {
            id
            ...TemplateActivityFragment
          }
        }
      }
    }
  }
  ${phaseFragment}
  ${milestoneFragment}
  ${templateActivityFragment}
`;

export const DELETE_TEMPLATE_PHASE = gql`
  mutation DeleteTemplatePhase($id: ID!) {
    deleteTemplatePhase(id: $id) {
      status
      error
      data {
        ...PhaseFragment
      }
    }
  }
  ${phaseFragment}
`;

export const CREATE_PHASE = gql`
  mutation createPhase($projectId: ID!, $name: String!, $rank: ID!) {
    createPhase(projectId: $projectId, name: $name, rank: $rank) {
      status
      error
      data {
        ...PhaseFragment
      }
    }
  }
  ${phaseFragment}
`;

export const UPDATE_PHASE = gql`
  mutation updatePhase($id: ID!, $name: String, $rank: ID, $deadline: Date) {
    updatePhase(id: $id, name: $name, rank: $rank, deadline: $deadline) {
      status
      error
      data {
        ...PhaseFragment
      }
    }
  }
  ${phaseFragment}
`;

export const ARCHIVE_PHASE = gql`
  mutation ArchivePhase($id: ID!) {
    archivePhase(id: $id) {
      status
      error
      data {
        ...PhaseFragment
      }
    }
  }
  ${phaseFragment}
`;
