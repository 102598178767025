
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { email, minLength, required } from "@vuelidate/validators";
import { ApolloCache } from "@apollo/client";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useMutation } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/client/en-UK.json";

import { UNASSIGN_USER, UPDATE_USER } from "@/graphql/user/mutations";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";
import BasicButton from "@/components/BasicButton.vue";

import ClientAccessRightsCacheInfoHolder from "@/helpers/client/ClientAccessRightsCacheInfoHolder";
import { User } from "@/helpers/user/typesDefinition";
import updateCacheWithUnassignedUser from "@/helpers/client/updateCacheWithUnassignedUser";
import updateUserInClientCache from "@/helpers/client/updateUserInClientCache";
import { ClientResponse } from "@/helpers/client/typesDefinition";
import { isProjectAccessRight } from "@/helpers/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const route = useRoute();

    function updateUserInClientCacheWithId(
      cache: ApolloCache<ClientResponse>,
      mutatedItem: User,
    ) {
      return updateUserInClientCache(
        cache,
        mutatedItem,
        route.params.id as string,
      );
    }

    const toast = useToast();
    const {
      mutate: unassignUser,
      onDone,
      onError,
      loading,
    } = useMutation(UNASSIGN_USER, {
      variables: {
        clientId: props.clientId,
        userId: props.user.id,
      },
      update: (cache, { data }) => {
        const mutate = data?.unassignUser;
        if (mutate?.status === "ok") {
          const mutatedItem = mutate.data;
          updateCacheWithUnassignedUser(
            cache,
            ClientAccessRightsCacheInfoHolder,
            mutatedItem,
            props.clientId,
          );
        }
      },
    });

    onDone(async (result) => {
      if (result.data?.unassignUser?.status === "ok") {
        toast.success(t("success.users.unassign"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.unassignUser?.error;
        console.log(errorResponse);
        toast.error(t("errors.users.unassign", { message: errorResponse }));
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.users.unassign", { message: t("errors.internal") }),
      );
    });

    return { t, unassignUser, loading, updateUserInClientCacheWithId };
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    projects: {
      type: Array as PropType<{ name: string; id: string }[]>,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
    transformedUser(user: User) {
      const { accessRights: userAccessRights, ...userProps } = user;
      const userProjectIds = [
        ...new Set(
          userAccessRights.reduce((acc, ar) => {
            if (!isProjectAccessRight(ar)) return acc;
            if (ar.project.client.id !== this.clientId) return acc;
            return [...acc, ar.project.id];
          }, [] as string[]),
        ),
      ];
      return {
        ...userProps,
        projects: userProjectIds,
        clientId: this.clientId,
      };
    },
  },
  data(props) {
    return {
      enUK,
      validationRules: computed(() => ({
        id: {},
        email: {
          required,
          email,
        },
        firstName: {},
        lastName: {},
        projects: {
          required,
          minLength: minLength(1),
        },
      })),
      formOptions: {
        projects: props.projects.reduce(
          (acc, { name, id }) => ({ ...acc, [id]: name }),
          {},
        ),
      },
      UPDATE_USER,
      ClientAccessRightsCacheInfoHolder,
      messages: {
        formButtonLabel: this.t("buttons.edit"),
        error: this.t("errors.edit"),
        success: this.t("success.edit"),
        title: this.t("titles.users.edit"),
      },
    };
  },
  components: {
    MutateEntity,
    BasicButton,
  },
});
