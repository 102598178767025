<template>
  <div class="header">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
}
</style>
