import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "open-invoices" }
const _hoisted_2 = { class: "open-invoices-body" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "open-invoices-header invoiceNumber" }
const _hoisted_5 = { class: "open-invoices-header client" }
const _hoisted_6 = { class: "open-invoices-header company" }
const _hoisted_7 = { class: "open-invoices-header total" }
const _hoisted_8 = { class: "open-invoices-header paid" }
const _hoisted_9 = { class: "open-invoices-header balance" }
const _hoisted_10 = { class: "open-invoices-header dueDate" }
const _hoisted_11 = { class: "open-invoices-header nbReminders" }
const _hoisted_12 = { class: "open-invoices-header promisedDate" }
const _hoisted_13 = { class: "open-invoices-header comment" }
const _hoisted_14 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedEntitiesList = _resolveComponent("PaginatedEntitiesList")!
  const _component_EditInvoice = _resolveComponent("EditInvoice")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("labels.invoiceNumber")), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("labels.client")), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("labels.company")), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t("labels.total")), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("labels.paid")), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("labels.balance")), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("labels.dueDate")), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t("labels.nbReminders")), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t("labels.promisedDate")), 1),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t("labels.comment")), 1)
        ]),
        _createVNode(_component_PaginatedEntitiesList, {
          query: _ctx.GET_OPENED_INVOICES,
          items: _ctx.openedInvoices,
          "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openedInvoices) = $event)),
          hasCheckBox: false,
          hasIcon: false,
          formatters: {
          client: _ctx.formatClient,
          paid: _ctx.formatPaid,
          nbReminders: _ctx.reminderCount,
          dueDate: _ctx.formatDate,
          promisedDate: _ctx.formatDate,
        },
          onActionOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEditModal($event)))
        }, null, 8, ["query", "items", "formatters"])
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.t("loading")), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isEditModalVisible)
      ? (_openBlock(), _createBlock(_component_EditInvoice, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeEditModal())),
          invoice: _ctx.clickedInvoice
        }, null, 8, ["invoice"]))
      : _createCommentVNode("", true)
  ], 64))
}