import { NonOrderedDeltasResponse, NonOrderedDelta } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { deltaComparator } from "./utils";
import { GET_NON_ORDERED_DELTAS } from "@/graphql/delta/queries";

const NonOrderedDeltasCacheInfoHolder: PaginatedCacheInfoHolder<
  NonOrderedDelta,
  NonOrderedDeltasResponse
> = {
  query: GET_NON_ORDERED_DELTAS,

  cachedPaginatedResult: (
    cachedResponse: NonOrderedDeltasResponse | null,
  ): PaginatedResult<NonOrderedDelta> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.nonOrderedDeltas;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<NonOrderedDelta>,
  ): NonOrderedDeltasResponse => ({
    nonOrderedDeltas: paginatedResult,
  }),

  comparator: deltaComparator,
};

export default NonOrderedDeltasCacheInfoHolder;
