import { Delta, NonOrderedDelta } from "./typesDefinition";
import dayjs from "dayjs";

export function isDelta(delta: unknown): delta is Delta {
  return (delta as Delta).__typename === "Delta";
}

export function deltaComparator<T extends NonOrderedDelta>(
  d1: T,
  d2: T,
): number {
  return dayjs(d1.startDate).diff(d2.startDate);
}
