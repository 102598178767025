import { ApolloCache } from "@apollo/client";
import { TemplatesResponse } from "../typesDefinition";
import { UpdateCachedEntities } from "@/helpers/cache/typesDefinition";
import { Phase } from "@26lights/orcha";
import { GET_TEMPLATES } from "@/graphql/orcha/templates/queries";
import { orderedComparator } from "@/helpers/orcha/utils";

export function updatePhaseCache(
  cache: ApolloCache<TemplatesResponse>,
  templateId: string,
  newItem: Phase,
  updateCachedEntities: UpdateCachedEntities<Phase>,
): void {
  const graphQLResponse: TemplatesResponse | null = cache.readQuery({
    query: GET_TEMPLATES,
  });

  if (graphQLResponse) {
    const templates = graphQLResponse.templates;

    const updatedTemplates = templates.map((t) => {
      if (t.id !== templateId) {
        return t;
      }
      return {
        ...t,
        phases: updateCachedEntities(newItem, t.phases, orderedComparator),
      };
    });

    console.log({ newItem, updatedTemplates });

    cache.writeQuery({
      query: GET_TEMPLATES,
      data: {
        templates: updatedTemplates,
      },
    });
  }
}
