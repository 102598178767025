import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3158d383"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clients" }
const _hoisted_2 = { class: "clients-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_PaginatedEntitiesList = _resolveComponent("PaginatedEntitiesList")!
  const _component_AddClient = _resolveComponent("AddClient")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBlock, { class: "clients-header" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("titles.myClients")), 1),
        _createVNode(_component_BasicButton, { onClick: _ctx.showModal }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("buttons.add")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_PaginatedEntitiesList, {
      query: _ctx.GET_CLIENTS,
      enUK: _ctx.enUK,
      onActionOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewEntity($event)))
    }, null, 8, ["query", "enUK"]),
    (_ctx.isModalVisible)
      ? (_openBlock(), _createBlock(_component_AddClient, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
        }))
      : _createCommentVNode("", true)
  ]))
}