import gql from "graphql-tag";

export const userFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    avatarUrl
  }
`;
