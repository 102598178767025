import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createBlock(_component_ButtonIcon, {
    onClick: _ctx.logout,
    label: _ctx.t('buttons.logout'),
    iconName: "bx:bxs-exit"
  }, null, 8, ["onClick", "label"]))
}