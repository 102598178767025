import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localeData from "dayjs/plugin/localeData";
import isBetween from "dayjs/plugin/isBetween";

export const initDayjs: () => void = () => {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(localeData);
  dayjs.extend(isBetween);
};
