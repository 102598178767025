import { Delta } from "../delta/typesDefinition";
import { isDelta } from "../delta/utils";
import { Order, RecurringOrder } from "./typesDefinition";
import { sortedStatusLabels, StatusLabelsKey } from "./utils";
import { Invoice } from "@/helpers/invoice/typesDefinition";
import { isInvoice } from "@/helpers/invoice/utils";

const statusLabelsSortingOrderArray = sortedStatusLabels.map(
  (label, index) => [label, index] as [StatusLabelsKey, number],
);

const statusLabelsSortingOrder = new Map<StatusLabelsKey, number>(
  statusLabelsSortingOrderArray,
) as ReadonlyMap<StatusLabelsKey, number>;

function getStatusSortingOrder(status: StatusLabelsKey): number {
  const statusSortingOrder = statusLabelsSortingOrder.get(status);
  return statusSortingOrder ? statusSortingOrder : 100;
}

export function orderComparator(
  e1: Order | RecurringOrder,
  e2: Order | RecurringOrder,
): number {
  const statusComparator =
    getStatusSortingOrder(e1.status) - getStatusSortingOrder(e2.status);
  if (statusComparator !== 0) return statusComparator;
  const dateComparator = e1.startDate.localeCompare(e2.startDate);
  if (dateComparator !== 0) return dateComparator;
  return e1.title.localeCompare(e2.title);
}

export function orderDeltaAndInvoiceComparator(
  e1: Order | Delta | Invoice,
  e2: Order | Delta | Invoice,
): number {
  if (isDelta(e1)) return 1;
  if (isDelta(e2)) return -1;
  if (isInvoice(e1)) return -1;
  if (isInvoice(e2)) return 1;
  return orderComparator(e1, e2);
}

export const recurringOrderComparator = orderComparator;
