import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MutateEntity = _resolveComponent("MutateEntity")!

  return (_openBlock(), _createBlock(_component_MutateEntity, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
    enUK: _ctx.enUK,
    entityProps: _ctx.entityProps,
    unmutableEntityProps: _ctx.unmutableEntityProps,
    entityId: _ctx.$route.params.id,
    validationRules: _ctx.validationRules,
    updatePaginatedQueryCache: _ctx.insertNewUserInClientCache,
    mutation: _ctx.ASSIGN_USER,
    modes: { projects: 'tags' },
    formOptions: _ctx.formOptions,
    mutationName: "assignUser",
    messages: _ctx.messages
  }, null, 8, ["enUK", "entityProps", "unmutableEntityProps", "entityId", "validationRules", "updatePaginatedQueryCache", "mutation", "formOptions", "messages"]))
}