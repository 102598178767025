import { Comparator } from "../typesDefinition";
import { User, UserResponse } from "../user/typesDefinition";
import { getIndexFirstHigherOrEqual } from "./binarySearch";

function removeOldUserResponseAndAddUpdated(
  updatedUser: User,
  clientAccessRights: UserResponse[],
  indexToBeRemoved: number,
  IndexToBeAdded: number,
): void {
  const [deletedAccessRights] = clientAccessRights.splice(indexToBeRemoved, 1);
  const updatedAccessRight = { ...deletedAccessRights, user: updatedUser };
  if (indexToBeRemoved >= IndexToBeAdded) {
    clientAccessRights.splice(IndexToBeAdded, 0, updatedAccessRight);
  } else {
    clientAccessRights.splice(IndexToBeAdded - 1, 0, updatedAccessRight);
  }
}

export function updateAccessRightsInClient(
  updatedEntity: User,
  updatedEntities: UserResponse[],
  comparator: Comparator<User, UserResponse>,
): void {
  const indexFirstHigherOrEqual = getIndexFirstHigherOrEqual(
    updatedEntities,
    updatedEntity,
    comparator,
  );
  for (let i = 0; i < updatedEntities.length; i++) {
    if (updatedEntity.id === updatedEntities[i].user.id) {
      removeOldUserResponseAndAddUpdated(
        updatedEntity,
        updatedEntities,
        i,
        indexFirstHigherOrEqual,
      );
      return;
    }
  }
}
