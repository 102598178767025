
import { defineComponent, PropType, ref } from "vue";
import VueDatePicker, { DatePickerMarker } from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import dayjs from "dayjs";

export default defineComponent({
  props: {
    input: {
      type: [String, Number, Date, Boolean],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    inputId: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String as PropType<
        "basic" | "textArea" | "date" | "inline-date" | "checkbox"
      >,
      default: "basic",
    },
    dateMarkers: {
      type: Array as PropType<DatePickerMarker[]>,
      required: false,
    },
    mask: {
      type: String,
      required: false,
    },
  },
  emits: ["update:input"],
  methods: {
    getType(): string {
      if (this.inputType === "checkbox") return "checkbox";
      if (typeof this.input === "number" && !this.readOnly) return "number";
      return "text";
    },
    dateFormat(value: Date): string {
      return dayjs(value).format("DD/MM/YYYY");
    },
  },
  data: (props) => ({
    value: ref(props.input),
  }),
  components: {
    VueDatePicker,
  },
});
