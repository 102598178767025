
import { computed, defineComponent, ref, reactive, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { OnClickOutside } from "@vueuse/components";

import enUK from "@/locales/home/en-UK.json";
import ProjectEnUK from "@/locales/project/en-UK.json";

import IconButton from "@/components/IconButton.vue";
import TileDashboard from "@/components/TileDashboard.vue";
import { GET_USER_INVOICES } from "@/graphql/invoice/queries";
import DropDownMenu from "@/components/dropDownMenu/DropDownMenu.vue";
import DropDownItem from "@/components/dropDownMenu/DropDownItem.vue";
import { Invoice } from "@/helpers/invoice/typesDefinition";
import { formatPrice } from "../helpers/utils";
import { formatPeriodDate } from "../helpers/order/utils";
import { formatDate } from "@vueuse/shared";
import dayjs from "dayjs";
import { sumBy } from "lodash";
import config from "@/config";
import PaymentModal from "@/components/PaymentModal.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import usePaginatedQuery from "@/composables/usePaginatedQuery";

type MessageSchema = typeof enUK | typeof ProjectEnUK;

export default defineComponent({
  methods: {
    dayjs,
    formatDate,
    formatOrderDate: formatPeriodDate,
    formatPrice,
  },
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": { ...enUK, ...ProjectEnUK },
      },
    });

    const invoices = ref();

    const { loading, paginatedResult, onResult, loadMore } = usePaginatedQuery(
      GET_USER_INVOICES,
      t("errors.getUserInvoices", {
        message: t("errors.internal"),
      }),
    );

    onResult(() => {
      if (paginatedResult.value.hasMore) {
        nextTick().then(loadMore);
      }
      invoices.value = paginatedResult.value.items;
    });

    const activeDropdown = ref("");
    const isDropdownActive = (id: string) => activeDropdown.value === id;

    const references = reactive<Record<string, HTMLElement | null>>({});

    const getRef = (invoiceId: string, element: HTMLElement) => {
      references[invoiceId] = element;
    };
    const toggleDropdown = (dropdownId: string) => {
      const newActiveDropdown =
        activeDropdown.value === dropdownId ? "" : dropdownId;
      activeDropdown.value = newActiveDropdown;
    };

    const handleInvoiceClick = (id: string) => {
      toggleDropdown(id);
    };

    const hasInvoices = computed(
      () => invoices.value && invoices.value.length > 0,
    );
    const closeDropdown = () => toggleDropdown("");

    const totalAmountDue = computed(() => sumBy(invoices.value, "balance"));

    const backEndUri = config.backendUri;

    const invoiceToPay = ref();
    const isPaymentModalVisible = computed(() => !!invoiceToPay.value);
    const setInvoiceToPay = (invoice: Invoice) => {
      invoiceToPay.value = invoice;
    };
    const closeInvoicePaymentModal = () => {
      invoiceToPay.value = undefined;
    };

    return {
      invoices,
      t,
      handleInvoiceClick,
      loading,
      isDropdownActive,
      closeDropdown,
      references,
      getRef,
      hasInvoices,
      backEndUri,
      totalAmountDue,
      invoiceToPay,
      isPaymentModalVisible,
      setInvoiceToPay,
      closeInvoicePaymentModal,
    };
  },
  components: {
    ButtonIcon,
    PaymentModal,
    IconButton,
    TileDashboard,
    DropDownMenu,
    DropDownItem,
    OnClickOutside,
  },
});
