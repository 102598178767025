
import { computed, defineComponent, PropType } from "vue";
import { required, email, minLength } from "@vuelidate/validators";
import { ApolloCache } from "@apollo/client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import enUK from "@/locales/client/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { ASSIGN_USER } from "@/graphql/user/mutations";

import ClientAccessRightsCacheInfoHolder from "@/helpers/client/ClientAccessRightsCacheInfoHolder";
import { updateEntityPaginatedPropQueryCache } from "@/helpers/cache/cache";
import { ClientResponse } from "@/helpers/client/typesDefinition";
import { UserResponse } from "@/helpers/user/typesDefinition";
import { insertNewEntityPaginated } from "@/helpers/cache/insertNewEntity";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    projects: {
      type: Array as PropType<{ name: string; id: string }[]>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const route = useRoute();

    function insertNewUserInClientCache(
      cache: ApolloCache<ClientResponse>,
      mutatedItem: UserResponse,
    ) {
      return updateEntityPaginatedPropQueryCache(
        cache,
        mutatedItem,
        ClientAccessRightsCacheInfoHolder,
        insertNewEntityPaginated,
        route.params.id as string,
      );
    }

    return { t, insertNewUserInClientCache };
  },
  data(props) {
    return {
      enUK,
      ASSIGN_USER,
      entityProps: { userEmail: "", projects: [] },
      unmutableEntityProps: { clientId: this.$route.params.id },
      validationRules: computed(() => ({
        userEmail: {
          required,
          email,
        },
        projects: {
          required,
          minLength: minLength(1),
        },
      })),
      formOptions: {
        projects: props.projects.reduce(
          (acc, { name, id }) => ({ ...acc, [id]: name }),
          {},
        ),
      },
      messages: {
        formButtonLabel: this.t("buttons.users.assign"),
        error: this.t("errors.users.assign"),
        success: this.t("success.users.assign"),
        title: this.t("titles.users.assign"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
