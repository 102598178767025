import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bee3d64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home-actions" }
const _hoisted_3 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_TileAdminProjects = _resolveComponent("TileAdminProjects")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBlock, {
      class: "header-home",
      title: _ctx.t('titles.myDashboard')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("welcome", { userName: _ctx.userName })) + " ", 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BasicButton, {
            class: "home-buttons",
            onClick: _ctx.syncData,
            disabled: _ctx.loadingSync
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("buttons.syncData")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          _createVNode(_component_BasicButton, {
            class: "home-buttons",
            onClick: _ctx.syncInvoices,
            disabled: _ctx.loadingSyncInvoices || _ctx.loadingSyncPerformedOrders
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("buttons.syncInvoices")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_TileAdminProjects, { class: "projects-home" }),
    (_ctx.loadingSync || _ctx.loadingSyncInvoices || _ctx.loadingSyncPerformedOrders)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true)
  ]))
}