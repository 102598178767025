
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";

export default defineComponent({
  props: {
    question: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });
    return { t };
  },
  emits: ["yes", "no", "close"],
  components: {
    Modal,
    BasicButton,
  },
});
