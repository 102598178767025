
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import HeaderBlock from "@/components/HeaderBlock.vue";

import enUK from "@/locales/invoicing/en-UK.json";
import Tabs from "@/components/Tabs.vue";
import { useMutation } from "@vue/apollo-composable";
import { SYNC_DELTAS } from "@/graphql/delta/mutations";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const {
      mutate: syncDeltas,
      onDone: onDoneSyncDeltas,
      onError: onErrorSyncDeltas,
      loading: loadingSyncDeltas,
    } = useMutation(SYNC_DELTAS);

    onDoneSyncDeltas(async (result) => {
      if (result.data?.syncDeltas?.status !== "ok") {
        const errorResponse = result.data?.syncDeltas?.error;
        console.log(errorResponse);
        toast.error(t("errors.syncDeltas", { message: errorResponse }));
      }
    });

    onErrorSyncDeltas((error) => {
      logErrorMessages(error);
      toast.error(t("errors.syncDeltas", { message: t("errors.internal") }));
    });

    return {
      t,
      syncDeltas,
      loadingSyncDeltas,
    };
  },
  data() {
    return {
      enUK,
    };
  },
  mounted() {
    this.syncDeltas();
  },
  components: {
    Tabs,
    HeaderBlock,
  },
});
