import gql from "graphql-tag";
import { timesheetFragment } from "@/graphql/timesheets/fragments";

export const CREATE_TIMER = gql`
  mutation createTimer($startTime: DateTime!, $themeId: ID, $roleId: ID) {
    createTimer(startTime: $startTime, themeId: $themeId, roleId: $roleId) {
      status
      error
      data {
        id
      }
    }
  }
`;

export const STOP_TIMER = gql`
  mutation stopTimer($id: ID!, $stopTime: DateTime!) {
    stopTimer(id: $id, stopTime: $stopTime) {
      status
      error
    }
  }
`;

export const RESTART_TIMER = gql`
  mutation restartTimer($id: ID!, $restartTime: DateTime!) {
    restartTimer(id: $id, restartTime: $restartTime) {
      status
      error
    }
  }
`;

export const DELETE_TIMER = gql`
  mutation deleteTimer($id: ID!) {
    deleteTimer(id: $id) {
      status
      error
    }
  }
`;

export const UPDATE_TIMER_PERIOD = gql`
  mutation updateTimerPeriod($id: ID!, $duration: Int!) {
    updateTimerPeriod(id: $id, duration: $duration) {
      status
      error
    }
  }
`;

export const SYNC_DAILY_TIMESHEETS = gql`
  mutation syncDailyTimesheets {
    syncDailyTimesheets {
      status
      error
      data {
        ...TimesheetFragment
      }
    }
  }
  ${timesheetFragment}
`;

export const CREATE_TIMESHEET = gql`
  mutation createTimesheet(
    $date: Date!
    $duration: Int!
    $themeId: ID!
    $roleId: ID!
    $notes: String
  ) {
    createTimesheet(
      date: $date
      duration: $duration
      themeId: $themeId
      roleId: $roleId
      notes: $notes
    ) {
      status
      error
      data {
        ...TimesheetFragment
      }
    }
  }
  ${timesheetFragment}
`;

export const UPDATE_TIMESHEET = gql`
  mutation updateTimesheet(
    $id: ID!
    $date: Date
    $duration: Int
    $themeId: ID
    $roleId: ID
    $notes: String
  ) {
    updateTimesheet(
      id: $id
      date: $date
      duration: $duration
      themeId: $themeId
      roleId: $roleId
      notes: $notes
    ) {
      status
      error
      data {
        ...TimesheetFragment
      }
    }
  }
  ${timesheetFragment}
`;

export const DELETE_TIMESHEET = gql`
  mutation deleteTimesheet($id: ID!) {
    deleteTimesheet(id: $id) {
      status
      error
    }
  }
`;
