import { CacheInfoHolder } from "@/helpers/typesDefinition";
import { Activity } from "@26lights/orcha";
import { GET_ACTIVITY } from "@/graphql/orcha/activities/queries";
import { Comment } from "@26lights/orcha/dist/src/types";

type ActivityResponse = {
  activity: Activity;
};

const ActivityCommentsCacheInfoHolder: CacheInfoHolder<
  Comment,
  ActivityResponse
> = {
  query: GET_ACTIVITY,

  cachedEntitiesToUpdate: (cachedResponse: ActivityResponse): Comment[] => {
    console.log({ cachedResponse });
    return cachedResponse.activity.comments ?? [];
  },

  cachedResponse: (
    cachedResponse: ActivityResponse,
    updatedComments: Comment[],
  ): ActivityResponse => ({
    activity: { ...cachedResponse.activity, comments: updatedComments },
  }),

  comparator: () => 1, // New comment will always be put at the end of the array
};

export default ActivityCommentsCacheInfoHolder;
