
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/client/en-UK.json";

import { GET_CLIENT } from "@/graphql/client/queries";
import { ARCHIVE_CLIENT } from "@/graphql/client/mutations";

import HeaderBlock from "@/components/HeaderBlock.vue";
import EditClient from "@/components/CRUXEntity/EditClient.vue";
import IconButton from "@/components/IconButton.vue";
import BasicButton from "@/components/BasicButton.vue";
import Tabs from "@/components/Tabs.vue";

import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import ClientsCacheInfoHolder from "@/helpers/client/ClientsCacheInfoHolder";
import { removePaginatedEntity } from "@/helpers/cache/removeEntity";
import checkHasAccess from "@/helpers/permissions/checkHasAccess";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const route = useRoute();
    const toast = useToast();
    const { result, loading, onError, onResult } = useQuery(GET_CLIENT, () => ({
      id: route.params.id,
    }));

    const client = useResult(result);

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.get"));
    });

    onResult(async (result) => {
      if (!hasAdminAccess.value)
        hasAdminAccess.value = await checkHasAccess(
          result.data?.client?.id,
          undefined,
          "admin",
        );
    });

    const hasAdminAccess = ref(false);

    onMounted(async () => {
      hasAdminAccess.value = await checkHasAccess(
        client.value?.id,
        undefined,
        "admin",
      );
    });

    const {
      mutate: archiveClient,
      onDone: onDoneArchive,
      onError: onErrorArchive,
      loading: loadingArchive,
    } = useMutation(ARCHIVE_CLIENT, () => ({
      variables: {
        id: route.params.id,
      },
      update: (cache, { data }) => {
        const archiveClientResponse = data?.archiveClient;
        if (archiveClientResponse?.status === "ok") {
          updatePaginatedQueryCache(
            cache,
            archiveClientResponse.data,
            ClientsCacheInfoHolder,
            removePaginatedEntity,
          );
        }
      },
    }));

    onDoneArchive(async (result) => {
      if (result.data?.archiveClient?.status === "ok") {
        toast.success(t("success.archive"));
      } else {
        const errorResponse = result.data?.archiveClient?.error;
        console.log(errorResponse);
        toast.error(t("errors.archive", { message: errorResponse }));
      }
    });

    onErrorArchive((error) => {
      logErrorMessages(error);
      toast.error(t("errors.archive", { message: t("errors.internal") }));
    });

    return {
      client,
      loading,
      hasAdminAccess,
      archiveClient,
      loadingArchive,
      t,
    };
  },
  data() {
    return { isEditClientModalVisible: false };
  },
  methods: {
    showEditClientModal(): void {
      this.isEditClientModalVisible = true;
    },
    closeEditClientModal(): void {
      this.isEditClientModalVisible = false;
    },
  },
  components: {
    HeaderBlock,
    EditClient,
    IconButton,
    BasicButton,
    Tabs,
  },
});
