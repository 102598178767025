
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useResult } from "@vue/apollo-composable";

import enUK from "@/locales/client/en-UK.json";

import EntitiesList from "@/components/list/EntitiesList.vue";
import AssignUser from "@/components/CRUXEntity/AssignUser.vue";
import EditUser from "@/components/CRUXEntity/EditUser.vue";
import BasicButton from "@/components/BasicButton.vue";

import usePaginatedQueryWithId from "@/composables/usePaginatedQueryWithId";

import { GET_CLIENT_USERS } from "@/graphql/client/queries";

import { AccessRight } from "@/helpers/typesDefinition";
import { User } from "@/helpers/user/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const route = useRoute();

    const endOfList = ref() as Ref<HTMLElement>;

    const {
      entity: client,
      loading,
      loadMore,
      onResult,
      result,
    } = usePaginatedQueryWithId(
      GET_CLIENT_USERS,
      t("errors.users.list"),
      route.params.id as string,
      "accessRights",
    );

    const paginatedUsers = useResult(result, null, (data) => {
      const accessRights = data.client.accessRights.items as AccessRight[];
      const users = accessRights.map((accessRights) => accessRights.user);
      const filtered = users.filter((userRights) =>
        userRights.accessRights.some(
          (accessRights) => accessRights.role === "client",
        ),
      );
      return filtered;
    });

    onMounted(() => {
      const handler = (entries: IntersectionObserverEntry[]) => {
        if (
          entries[0]?.isIntersecting &&
          client?.value?.accessRights?.hasMore
        ) {
          loadMore();
        }
      };
      const observer = new window.IntersectionObserver(handler);
      observer.observe(endOfList.value);
    });

    onResult(async () => {
      const rect = endOfList.value.getBoundingClientRect();
      if (
        rect.bottom <= document.documentElement.clientHeight &&
        client?.value?.accessRights?.hasMore &&
        !loading.value
      ) {
        loadMore();
      }
    });

    const userToEdit = ref() as Ref<User>;

    return {
      client,
      loading,
      endOfList,
      paginatedUsers,
      userToEdit,
      t,
    };
  },
  data() {
    return {
      isAssignUserModalVisible: false,
      isEditUserModalVisible: false,
    };
  },
  provide: {
    columnsOrder: {
      firstName: null,
      lastName: null,
      email: null,
    },
  },
  methods: {
    showAssignUserModal(): void {
      this.isAssignUserModalVisible = true;
    },
    closeAssignUserModal(): void {
      this.isAssignUserModalVisible = false;
    },
    openEditUserModal(user: User): void {
      this.userToEdit = user;
      this.isEditUserModalVisible = true;
    },
    closeEditUserModal(): void {
      this.isEditUserModalVisible = false;
    },
  },
  components: {
    EntitiesList,
    AssignUser,
    EditUser,
    BasicButton,
  },
});
