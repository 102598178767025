import gql from "graphql-tag";

export const invoiceFragment = gql`
  fragment InvoiceFragment on Invoice {
    id
    type
    invoiceNumber
    total
    netTotal
    balance
    date
    dueDate
    invoiceStatus: status
  }
`;
