
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";

import {
  GET_RECURRING_ORDER,
  ORDERS_TO_REJECT_IF_RECURRING_CANCELED,
} from "@/graphql/order/queries";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import OrderLine from "@/components/order/OrderLine.vue";
import BasicButton from "@/components/BasicButton.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import EntitiesList from "@/components/list/EntitiesList.vue";

import enUK from "@/locales/project/en-UK.json";
import { Order, RecurringOrder } from "@/helpers/order/typesDefinition";
import { getMonthOptions } from "@/helpers/dates";
import { formatPrice } from "@/helpers/utils";
import { transformOrderLineToDisplay } from "@/helpers/order/utils";
import {
  APPROVE_RECURRING_ORDER,
  CANCEL_RECURRING_ORDER,
  DELETE_RECURRING_ORDER,
  SEND_RECURRING_ORDER,
} from "@/graphql/order/mutations";
import { updateRecurringOrdersCache } from "@/helpers/order/updateCache";
import { removeEntity } from "@/helpers/cache/removeEntity";
import { apolloClient } from "@/apollo.provider";
import dayjs from "dayjs";

type MessageSchema = typeof enUK;

function fixEndDate(order: RecurringOrder): RecurringOrder {
  if (/01$/.test(order.endDate)) {
    const endDate = dayjs(order.endDate)
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");
    return {
      ...order,
      endDate,
    };
  }
  return order;
}

export default defineComponent({
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const months = getMonthOptions();

    const legalNoticeAgreement = ref(false);
    const showApproveValidation = ref(false);

    const { result, loading, onError, onResult, refetch } = useQuery(
      GET_RECURRING_ORDER,
      () => ({
        id: props.id,
      }),
    );

    const recurringOrder = useResult(result, null, (data) => {
      const recurringOrder = fixEndDate(data.recurringOrder as RecurringOrder);
      const orderLines = recurringOrder.orderLines;
      return {
        ...recurringOrder,
        orderLines: orderLines.map(transformOrderLineToDisplay),
      };
    });

    const subtotals = ref(
      Array(recurringOrder.value?.orderLines.length).fill(0),
    );

    onResult((result) => {
      subtotals.value = Array(
        result.data.recurringOrder.orderLines.length,
      ).fill(0);
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.recurringOrder.get"));
    });

    const {
      mutate: approveRecurringOrderMutation,
      onDone: onApprovalDone,
      onError: onApprovalError,
      loading: loadingApproval,
    } = useMutation(APPROVE_RECURRING_ORDER, () => ({
      variables: {
        id: props.id,
        legalNoticeAgreement: legalNoticeAgreement.value,
      },
    }));

    onApprovalDone((result) => {
      if (result.data?.approveRecurringOrder?.status === "ok") {
        toast.success(t("success.recurringOrder.approve"));
        context.emit("close");
        refetch();
      } else {
        const errorResponse = result.data?.approveRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.recurringOrder.approve", { message: errorResponse }),
        );
      }
    });

    onApprovalError((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.approve", { message: t("errors.internal") }),
      );
    });

    async function approveRecurringOrder() {
      if (recurringOrder.value?.isFirstOrder && !legalNoticeAgreement.value) {
        showApproveValidation.value = true;
        return;
      }
      return approveRecurringOrderMutation();
    }

    const {
      mutate: sendRecurringOrder,
      onDone: onDoneSendRecurringOrder,
      onError: onErrorSendRecurringOrder,
      loading: loadingSendOrder,
    } = useMutation(SEND_RECURRING_ORDER, () => ({
      variables: {
        id: props.id,
      },
    }));

    onDoneSendRecurringOrder(async (result) => {
      if (result.data?.sendRecurringOrder?.status === "ok") {
        toast.success(t("success.recurringOrder.send"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.sendRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.recurringOrder.send", { message: errorResponse }),
        );
      }
    });

    onErrorSendRecurringOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.send", {
          message: "Error with graphql query",
        }),
      );
    });

    const {
      mutate: deleteOrder,
      onDone: onDoneDeleteOrder,
      onError: onErrorDeleteOrder,
      loading: loadingDeleteOrder,
    } = useMutation(DELETE_RECURRING_ORDER, () => ({
      variables: {
        id: props.id,
      },
      update: (cache, { data }) => {
        const createRecurringOrderResponse = data?.deleteRecurringOrder;
        if (createRecurringOrderResponse?.status === "ok") {
          updateRecurringOrdersCache(
            cache,
            createRecurringOrderResponse.data?.project?.id,
            createRecurringOrderResponse.data,
            removeEntity,
          );
        }
      },
    }));

    onDoneDeleteOrder(async (result) => {
      if (result.data?.deleteRecurringOrder?.status === "ok") {
        toast.success(t("success.recurringOrder.delete"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.deleteRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.recurringOrder.delete", { message: errorResponse }),
        );
      }
    });

    onErrorDeleteOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.delete", {
          message: "Error with graphql query",
        }),
      );
    });

    const {
      mutate: cancelOrder,
      onDone: onDoneCancelOrder,
      onError: onErrorCancelOrder,
      loading: loadingCancelOrder,
    } = useMutation(CANCEL_RECURRING_ORDER, () => ({
      variables: {
        id: props.id,
      },
    }));

    onDoneCancelOrder(async (result) => {
      if (result.data?.cancelRecurringOrder?.status === "ok") {
        toast.success(t("success.recurringOrder.cancel"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.cancelRecurringOrder?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.recurringOrder.cancel", { message: errorResponse }),
        );
      }
    });

    onErrorCancelOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.recurringOrder.cancel", {
          message: "Error with graphql query",
        }),
      );
    });

    const loadingAction = computed(
      () =>
        loadingApproval.value ||
        loadingSendOrder.value ||
        loadingDeleteOrder.value ||
        loadingCancelOrder.value,
    );

    const isConfirmModalVisible = ref(false);
    const closeConfirmModal = () => {
      isConfirmModalVisible.value = false;
    };

    const ordersToReject = ref([] as Order[]);

    const openConfirmModalAndGetOrdersToReject = async () => {
      try {
        const ordersToRejectResult = await apolloClient.query({
          query: ORDERS_TO_REJECT_IF_RECURRING_CANCELED,
          variables: {
            recurringOrderId: props.id,
          },
        });
        ordersToReject.value =
          ordersToRejectResult.data.ordersToRejectIfRecurringCanceled;
      } catch (error) {
        console.log(error);
        toast.error(t("errors", { message: error }));
      }
      isConfirmModalVisible.value = true;
    };

    return {
      result,
      recurringOrder,
      subtotals,
      months,
      loading,
      formatPrice,
      t,
      loadingAction,
      approveRecurringOrder,
      sendRecurringOrder,
      deleteOrder,
      cancelOrder,
      isConfirmModalVisible,
      closeConfirmModal,
      openConfirmModalAndGetOrdersToReject,
      ordersToReject,
      legalNoticeAgreement,
      showApproveValidation,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    hasAdminAccess: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  computed: {
    total() {
      return this.subtotals.reduce(
        (partial_sum: number, subtotal: number) => partial_sum + subtotal,
        0,
      );
    },
  },
  provide: {
    columnsOrder: {
      title: null,
      date: null,
      status: null,
    },
  },
  components: {
    Modal,
    FormItem,
    OrderLine,
    BasicButton,
    ConfirmModal,
    EntitiesList,
  },
});
