import { getMonthOptions, getYearOptions } from "@/helpers/dates";
import { legalNotices, orderDefaultTitle } from "@/helpers/order/utils";
import { Project } from "@/helpers/project/typesDefinition";
import { reactive } from "vue";
import dayjs from "dayjs";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (
  project: Project,
  isRecurringOrder: boolean,
  orderInTitle: string,
  nextOrderNumber: number,
) {
  const currentDate = new Date();
  const months = getMonthOptions();
  const years = getYearOptions();

  const nextMonth = dayjs(currentDate).add(1, "month");
  const monthAfterNext = nextMonth.add(1, "month");

  const companies = project.client.companies;

  const order = reactive({
    title: orderDefaultTitle(project, orderInTitle, nextOrderNumber),
    startYear: nextMonth.year(),
    startMonth: nextMonth.month() + 1,
    endYear: isRecurringOrder ? nextMonth.year() + 1 : monthAfterNext.year(),
    endMonth: isRecurringOrder
      ? nextMonth.month() + 1
      : monthAfterNext.month() + 1,
    notes: "",
    legalNotice: legalNotices[project.defaultPaymentOption],
    paymentOption: project.defaultPaymentOption,
    companyId: companies.length > 0 ? companies[0].id : undefined,
  });

  return { order, months, years };
}
