import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a65bda4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.question), 1)
    ]),
    body: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        class: "confirm-button yes",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('yes')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("yes")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BasicButton, {
        class: "confirm-button no",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('no')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("no")), 1)
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}