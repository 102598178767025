import { User, UserResponse } from "../user/typesDefinition";
import { ApolloCache } from "@apollo/client";
import { EntityPaginatedPropCacheInfoHolder } from "../typesDefinition";
import { Client, ClientResponse } from "./typesDefinition";

function removeUserUserResponses(
  entityToRemove: User,
  entities: UserResponse[],
): void {
  const index = entities.findIndex(
    (entity) => entity.user.id === entityToRemove.id,
  );
  if (index < 0) return;
  entities.splice(index, 1);
}

export function removeUserFromClientAccessRights(
  existingEntities: UserResponse[],
  mutatedEntity: User,
): UserResponse[] {
  const updatedEntities = Array.from(existingEntities);
  removeUserUserResponses(mutatedEntity, updatedEntities);
  return updatedEntities;
}

export default function updateCacheWithUnassignedUser(
  cache: ApolloCache<ClientResponse>,
  updater: EntityPaginatedPropCacheInfoHolder<
    UserResponse,
    ClientResponse,
    Omit<Client, "projects" | "companies">
  >,
  newItem: User,
  id: string,
): void {
  const graphQLResponse: ClientResponse | null = cache.readQuery({
    query: updater.query,
    variables: { id },
  });

  const paginatedResult = updater.cachedPaginatedResult(graphQLResponse);
  const entity = updater.cachedEntity(graphQLResponse, id);

  const updatedItems = removeUserFromClientAccessRights(
    paginatedResult.items,
    newItem,
  );

  cache.writeQuery({
    query: updater.query,
    variables: { id },
    data: updater.cachedResponse(
      {
        ...paginatedResult,
        items: updatedItems,
      },
      entity,
    ),
  });
}
