import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1f1fd94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-header" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _mergeProps({ class: "modal-backdrop" }, _ctx.$attrs), [
      _createElementVNode("div", {
        class: _normalizeClass(['modal', `modal--${_ctx.size}`])
      }, [
        _createElementVNode("header", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]),
        _createElementVNode("section", _hoisted_2, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ]),
        _createElementVNode("footer", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true),
          _createVNode(_component_BasicButton, {
            onClick: _ctx.close,
            color: "canvas"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("buttons.close")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ], 2)
    ], 16)
  ]))
}