import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57d4d75a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TileTitle = _resolveComponent("TileTitle")!
  const _component_TileBase = _resolveComponent("TileBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TileBase, { class: "card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TileTitle, {
            title: _ctx.companyData.name,
            level: 2
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("labels.invoicedAmount")), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("labels.gross")), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatPrice(_ctx.companyData.totalInvoiced.gross)), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("labels.net")), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formatPrice(_ctx.companyData.totalInvoiced.net)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", null, _toDisplayString(_ctx.t("labels.totalPaid")), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatPrice(_ctx.companyData.totalPaid)), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t("labels.totalToPay")), 1),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatPrice(_ctx.companyData.totalToPay)), 1)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}