import gql from "graphql-tag";
import { userFragment } from "../user/fragments";
import { clientFragment, companyFragment } from "./fragments";
import { budgetFragment } from "@/graphql/project/fragments";

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      ...ClientFragment
      projects(withInactive: true) {
        id
        name
        active
        budget {
          ...BudgetFragment
        }
      }
      companies {
        ...CompanyFragment
        vatExempted
      }
    }
  }
  ${budgetFragment}
  ${clientFragment}
  ${companyFragment}
`;

export const GET_CLIENT_USERS = gql`
  query getClientUsers($id: ID!, $offset: Int!, $limit: Int) {
    client(id: $id) {
      id
      projects(withInactive: true) {
        id
        name
        active
      }
      accessRights(offset: $offset, limit: $limit) {
        hasMore
        items {
          user {
            ...UserFragment
            accessRights {
              ... on ProjectAccessRight {
                role
                project {
                  id
                  client {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${userFragment}
`;

export const GET_CLIENTS = gql`
  query getClients($offset: Int!, $limit: Int) {
    clients(offset: $offset, limit: $limit) {
      hasMore
      items {
        ...ClientFragment
      }
    }
  }
  ${clientFragment}
`;

export const GET_ALL_CLIENT_WAITING_ACTIONS = gql`
  query getAllClientActions {
    clientActions {
      ... on BudgetApprobationAction {
        budget {
          id
          amount
          project {
            id
            name
            previouslyApprovedBudget {
              id
              amount
            }
          }
        }
      }
      ... on InvoicePaymentAction {
        invoice {
          id
          invoiceNumber
          balance
          iban
          bic
          communication
        }
      }
      ... on OrderApprobationAction {
        order {
          ... on Order {
            id
            number
            price
            project {
              id
              name
            }
          }
          ... on RecurringOrder {
            id
            number
            price
            project {
              id
              name
            }
          }
        }
      }
    }
  }
`;
