import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "companies-list" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "vatNumber" }
const _hoisted_5 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntitiesList = _resolveComponent("EntitiesList")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_AddCompany = _resolveComponent("AddCompany")!
  const _component_EditCompany = _resolveComponent("EditCompany")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("labels.company.name")), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("labels.company.vatNumber")), 1)
        ]),
        (_ctx.client)
          ? (_openBlock(), _createBlock(_component_EntitiesList, {
              key: 0,
              elements: _ctx.client.companies,
              onGoEntityDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditCompanyModal($event)))
            }, null, 8, ["elements"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_BasicButton, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddCompanyModal())),
      class: "create-company-button"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("buttons.company.add")), 1)
      ]),
      _: 1
    }),
    (_ctx.isAddCompanyModalVisible)
      ? (_openBlock(), _createBlock(_component_AddCompany, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeAddCompanyModal()))
        }))
      : _createCommentVNode("", true),
    (_ctx.client && _ctx.isEditCompanyModalVisible)
      ? (_openBlock(), _createBlock(_component_EditCompany, {
          key: 2,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeEditCompanyModal())),
          clientId: _ctx.client.id,
          company: _ctx.companyToEdit
        }, null, 8, ["clientId", "company"]))
      : _createCommentVNode("", true)
  ], 64))
}