import { CacheInfoHolder } from "@/helpers/typesDefinition";
import { Activity } from "@26lights/orcha";
import { GET_ACTIVITY } from "@/graphql/orcha/activities/queries";
import { Subtask } from "@26lights/orcha/dist/src/types";

type ActivityResponse = {
  activity: Activity;
};

const ActivityTasksCacheInfoHolder: CacheInfoHolder<Subtask, ActivityResponse> =
  {
    query: GET_ACTIVITY,

    cachedEntitiesToUpdate: (cachedResponse: ActivityResponse): Subtask[] => {
      console.log({ cachedResponse });
      return cachedResponse.activity.tasks ?? [];
    },

    cachedResponse: (
      cachedResponse: ActivityResponse,
      updatedTasks: Subtask[],
    ): ActivityResponse => ({
      activity: { ...cachedResponse.activity, tasks: updatedTasks },
    }),

    comparator: () => 1, // New task will always be put at the end of the array
  };

export default ActivityTasksCacheInfoHolder;
