
import { DocumentNode } from "graphql";
import { defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import usePaginatedQuery from "@/composables/usePaginatedQuery";

interface MultiSelectAPI {
  refreshOptions: () => void;
}

export default defineComponent({
  inject: ["loadingMessage"],
  props: {
    query: {
      type: Object as PropType<DocumentNode>,
      required: true,
    },
    input: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:input"],
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });

    const endOfList = ref(null) as unknown as Ref<HTMLElement>;
    const multiselect = ref(null) as unknown as Ref<MultiSelectAPI>;
    const observer = ref();

    const { paginatedResult, loading, loadMore } = usePaginatedQuery(
      props.query,
      t("errors.selectInput"),
    );

    onMounted(() => {
      const handler = (entries: IntersectionObserverEntry[]) => {
        if (entries[0]?.isIntersecting && paginatedResult?.value?.hasMore) {
          loadMore();
        }
      };
      observer.value = new window.IntersectionObserver(handler);
    });

    watch(paginatedResult, () => {
      if (multiselect.value) multiselect.value.refreshOptions();
    });

    return {
      paginatedResult,
      loading,
      observer,
      endOfList,
      multiselect,
    };
  },
  methods: {
    async onOpen() {
      await this.$nextTick();
      this.observer.observe(this.endOfList);
    },
    onClose() {
      this.observer.disconnect();
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.input;
      },
      set(val: string) {
        this.$emit("update:input", val);
      },
    },
  },
});
