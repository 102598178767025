import { ApolloCache } from "@apollo/client";
import { TemplatesResponse } from "../typesDefinition";
import { UpdateCachedEntities } from "@/helpers/cache/typesDefinition";
import { Activity } from "@26lights/orcha";
import { GET_TEMPLATES } from "@/graphql/orcha/templates/queries";
import { orderedComparator } from "@/helpers/orcha/utils";

export function updateActivityCache(
  cache: ApolloCache<TemplatesResponse>,
  templateId: string,
  phaseId: string,
  milestoneId: string,
  newItem: Activity,
  updateCachedEntities: UpdateCachedEntities<Activity>,
): void {
  const graphQLResponse: TemplatesResponse | null = cache.readQuery({
    query: GET_TEMPLATES,
  });

  if (graphQLResponse) {
    const templates = graphQLResponse.templates;

    const updatedTemplates = templates.map((t) => {
      if (t.id !== templateId) {
        return t;
      }
      return {
        ...t,
        phases: t.phases.map((ph) => {
          if (ph.id !== phaseId) {
            return ph;
          }
          return {
            ...ph,
            milestones: ph.milestones.map((ml) => {
              if (ml.id !== milestoneId) {
                return ml;
              }
              return {
                ...ml,
                activities: updateCachedEntities(
                  newItem,
                  ml.activities,
                  orderedComparator,
                ),
              };
            }),
          };
        }),
      };
    });

    console.log({ newItem, updatedTemplates });

    cache.writeQuery({
      query: GET_TEMPLATES,
      data: {
        templates: updatedTemplates,
      },
    });
  }
}
