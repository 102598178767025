import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b755ee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_MutateEntity = _resolveComponent("MutateEntity")!

  return (_openBlock(), _createBlock(_component_MutateEntity, {
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal())),
    enUK: _ctx.enUK,
    entityProps: _ctx.transformedUser(_ctx.user),
    entityId: _ctx.clientId,
    validationRules: _ctx.validationRules,
    hiddenProperties: ['clientId', 'avatarUrl'],
    updatePaginatedQueryCache: _ctx.updateUserInClientCacheWithId,
    mutation: _ctx.UPDATE_USER,
    modes: { projects: 'tags' },
    formOptions: _ctx.formOptions,
    mutationName: "updateUser",
    messages: _ctx.messages
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.unassignUser()), ["prevent"])),
        disabled: _ctx.loading,
        class: "mutate-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("buttons.users.unassign")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.t("loading")), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["enUK", "entityProps", "entityId", "validationRules", "updatePaginatedQueryCache", "mutation", "formOptions", "messages"]))
}