import gql from "graphql-tag";
import { invoiceFragment } from "./fragments";

export const GET_INDEPENDENT_INVOICES = gql`
  query independentInvoices($offset: Int, $limit: Int) {
    independentInvoices(offset: $offset, limit: $limit) {
      hasMore
      items {
        ...InvoiceFragment
        date
        project {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
  ${invoiceFragment}
`;

export const GET_PROJECT_INVOICES = gql`
  query getProjectInvoices($projectId: ID!, $offset: Int, $limit: Int) {
    projectInvoices(projectId: $projectId, offset: $offset, limit: $limit) {
      hasMore
      items {
        ...InvoiceFragment
        company {
          id
          name
          client {
            id
          }
        }
        linkedRessource {
          ... on Delta {
            id
            startDate
            endDate
            project {
              name
            }
          }
          ... on Order {
            id
            startDate
            endDate
            paymentOption
            project {
              name
            }
          }
        }
      }
    }
  }
  ${invoiceFragment}
`;

export const GET_USER_INVOICES = gql`
  query getClientInvoices($offset: Int, $limit: Int) {
    userInvoices(offset: $offset, limit: $limit) {
      hasMore
      items {
        id
        type
        status
        invoiceNumber
        date
        dueDate
        company {
          id
          name
        }
        project {
          id
          name
        }
        total
        netTotal
        balance
        linkedRessource {
          ... on Delta {
            id
            startDate
            endDate
            project {
              name
            }
          }
          ... on Order {
            id
            startDate
            endDate
            project {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_OPENED_CREDIT_NOTES = gql`
  query getOpenedCreditNotes($offset: Int!, $limit: Int) {
    invoices(
      offset: $offset
      limit: $limit
      filter: { creditNotes: true, status: [sent] }
    ) {
      hasMore
      items {
        ...InvoiceFragment
        date
        company {
          id
          name
          client {
            id
            name
          }
        }
      }
    }
  }
  ${invoiceFragment}
`;

export const GET_OPENED_INVOICES = gql`
  query getOpenedInvoices($offset: Int!, $limit: Int) {
    invoices(
      offset: $offset
      limit: $limit
      orderBy: due_date
      filter: {
        creditNotes: false
        onlyLate: true
        status: [sent, reminder_1, reminder_2, reminder_3]
      }
    ) {
      hasMore
      items {
        ...InvoiceFragment
        date
        dueDate
        promisedDate
        comment
        company {
          id
          name
          client {
            id
            name
          }
        }
      }
    }
  }
  ${invoiceFragment}
`;
