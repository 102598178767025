import gql from "graphql-tag";

export const milestoneFragment = gql`
  fragment MilestoneFragment on BaseMilestone {
    id
    name
    rank
    important
  }
`;
