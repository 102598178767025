
import { defineComponent, PropType } from "vue";
import { createPopper, Instance } from "@popperjs/core";

export default defineComponent({
  props: {
    reference: {
      type: HTMLElement as PropType<HTMLElement | null>,
      required: true,
    },
    popover: {
      type: HTMLElement as PropType<HTMLElement | null>,
      required: true,
    },
  },
  data() {
    return {
      popper: null as Instance | null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const reference = this.$props.reference;
      const popover = this.$props.popover;

      if (reference && popover) {
        this.$data.popper = createPopper(reference, popover, {
          placement: "bottom-start",
        });
      }
    });
  },
  beforeUnmount() {
    this.$data.popper?.destroy();
  },
});
