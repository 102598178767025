
import { defineComponent, PropType } from "vue";
import GraphDonut from "./GraphDonut.vue";
import { type DonutSegment } from "@/helpers/typesDefinition";

export default defineComponent({
  props: {
    segments: Object as PropType<DonutSegment[]>,
    labelName: String,
    labelValue: String,
  },
  components: {
    GraphDonut,
  },
});
