import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "open-credit-notes" }
const _hoisted_2 = { class: "open-credit-notes-body" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "open-credit-notes-header checkbox" }
const _hoisted_5 = { class: "open-credit-notes-header client" }
const _hoisted_6 = { class: "open-credit-notes-header company" }
const _hoisted_7 = { class: "open-credit-notes-header invoiceNumber" }
const _hoisted_8 = { class: "open-credit-notes-header date" }
const _hoisted_9 = { class: "open-credit-notes-header total" }
const _hoisted_10 = { class: "open-credit-notes-header invoiceStatus" }
const _hoisted_11 = {
  key: 0,
  class: "loading apollo"
}
const _hoisted_12 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginatedEntitiesList = _resolveComponent("PaginatedEntitiesList")!
  const _component_BasicButton = _resolveComponent("BasicButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.selectAll]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("labels.client")), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("labels.company")), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t("labels.invoiceNumber")), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("labels.date")), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("labels.total")), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("labels.status")), 1)
      ]),
      _createVNode(_component_PaginatedEntitiesList, {
        query: _ctx.GET_OPENED_CREDIT_NOTES,
        checkBoxes: _ctx.checkBoxes,
        "onUpdate:checkBoxes": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkBoxes) = $event)),
        items: _ctx.openedCreditNotes,
        "onUpdate:items": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openedCreditNotes) = $event)),
        hasCheckBox: true,
        selectAll: _ctx.selectAll,
        "onUpdate:selectAll": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectAll) = $event)),
        hasIcon: false,
        formatters: { client: _ctx.formatClient }
      }, null, 8, ["query", "checkBoxes", "items", "selectAll", "formatters"])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_BasicButton, {
        class: "confirm-button",
        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.closeSelectedCreditNotes()), ["prevent"])),
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("buttons.confirm")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}