import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

dayjs.extend(localizedFormat);

export function formatPrice(price: number): string {
  if (typeof price !== "number" || isNaN(price)) return "-";
  const priceInEuros = price / 100;
  return priceInEuros.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
  });
}

export function formatFloat(value: number): string {
  if (typeof value !== "number" || isNaN(value)) return "-";
  return value.toLocaleString("fr-FR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export function formatFullDate(date: Date): string {
  return dayjs(date).format("LLLL");
}

export const formatPercentage = (decimalPercentage: number): string => {
  if (typeof decimalPercentage !== "number" || isNaN(decimalPercentage)) {
    return "-";
  }
  return Number(decimalPercentage).toLocaleString("fr", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function stringCompareWithNullsLast(
  str1: string | undefined,
  str2: string | undefined,
): number {
  if (!str1 && !str2) return 0;
  if (!str2) return -1;
  if (!str1) return 1;
  return str1.localeCompare(str2);
}

export function isInt(n: number): boolean {
  return n % 1 === 0;
}

export function quarterStepsValidator(value: number): boolean {
  return isInt(value * 4);
}

export const computeOrderLineSubtotal = (
  quantity: number,
  hourlyRate: number,
  unit: number,
): number => {
  return quantity * hourlyRate * (unit / 60);
};

export function shadeColor(color: string, percent: number) {
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  r = (r * (100 + percent)) / 100;
  g = (g * (100 + percent)) / 100;
  b = (b * (100 + percent)) / 100;

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);

  const rr = r.toString(16).length == 1 ? "0" + r.toString(16) : r.toString(16);
  const gg = g.toString(16).length == 1 ? "0" + g.toString(16) : g.toString(16);
  const bb = b.toString(16).length == 1 ? "0" + b.toString(16) : b.toString(16);

  return `#${rr}${gg}${bb}`;
}
