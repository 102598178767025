
import { defineComponent, computed } from "vue";
import { Icon } from "@iconify/vue";
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
      validator: (value: string) =>
        ["_self", "_blank", "_parent", "top"].includes(value),
    },
  },
  setup(props) {
    const isLink = computed(() => {
      return !!props.href;
    });
    const tag = computed(() => {
      return isLink.value ? "a" : "button";
    });
    return { tag, isLink };
  },
  components: {
    Icon,
  },
});
