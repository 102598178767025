import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "project-recurring-orders-list" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "project-recurring-orders-list-header title" }
const _hoisted_4 = { class: "project-recurring-orders-list-header startDate" }
const _hoisted_5 = { class: "project-recurring-orders-list-header endDate" }
const _hoisted_6 = { class: "project-recurring-orders-list-header status" }
const _hoisted_7 = { class: "project-recurring-orders-list-header price" }
const _hoisted_8 = { class: "project-recurring-orders-list-header company" }
const _hoisted_9 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntitiesList = _resolveComponent("EntitiesList")!
  const _component_RecurringOrderDetails = _resolveComponent("RecurringOrderDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("labels.recurringOrder.title")), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("labels.recurringOrder.firstMonth")), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("labels.recurringOrder.lastMonth")), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("labels.recurringOrder.status")), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t("labels.recurringOrder.price")), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("labels.recurringOrder.company")), 1)
      ]),
      _createVNode(_component_EntitiesList, {
        elements: _ctx.recurringOrders,
        onGoEntityDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openOrderDetailsModal($event.id))),
        hasIcon: false,
        emptySpace: _ctx.t('emptySpace.recurringOrder')
      }, null, 8, ["elements", "emptySpace"])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true),
    (_ctx.isModalDetailsVisible)
      ? (_openBlock(), _createBlock(_component_RecurringOrderDetails, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeOrderDetailsModal())),
          id: _ctx.modalDetailsId,
          projectId: _ctx.id,
          hasAdminAccess: _ctx.hasAdminAccess
        }, null, 8, ["id", "projectId", "hasAdminAccess"]))
      : _createCommentVNode("", true)
  ], 64))
}