import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "client-users" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "firstName" }
const _hoisted_5 = { class: "lastName" }
const _hoisted_6 = { class: "email" }
const _hoisted_7 = {
  ref: "endOfList",
  id: "endOfList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntitiesList = _resolveComponent("EntitiesList")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_AssignUser = _resolveComponent("AssignUser")!
  const _component_EditUser = _resolveComponent("EditUser")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.client)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            _createElementVNode("li", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("labels.users.firstName")), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("labels.users.lastName")), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("labels.users.email")), 1)
            ]),
            (_ctx.paginatedUsers)
              ? (_openBlock(), _createBlock(_component_EntitiesList, {
                  key: 0,
                  elements: _ctx.paginatedUsers,
                  onGoEntityDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditUserModal($event)))
                }, null, 8, ["elements"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("div", { class: "loading apollo" }, _toDisplayString(_ctx.t("loading")), 513), [
          [_vShow, _ctx.loading]
        ])
      ], 512),
      _createVNode(_component_BasicButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAssignUserModal())),
        class: "assign-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("buttons.users.assign")), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.isAssignUserModalVisible)
      ? (_openBlock(), _createBlock(_component_AssignUser, {
          key: 0,
          projects: _ctx.client.projects,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeAssignUserModal()))
        }, null, 8, ["projects"]))
      : _createCommentVNode("", true),
    (_ctx.client && _ctx.isEditUserModalVisible)
      ? (_openBlock(), _createBlock(_component_EditUser, {
          key: 1,
          projects: _ctx.client.projects,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeEditUserModal())),
          clientId: _ctx.client.id,
          user: _ctx.userToEdit
        }, null, 8, ["projects", "clientId", "user"]))
      : _createCommentVNode("", true)
  ], 64))
}