import gql from "graphql-tag";
import { budgetFragment, projectFragment } from "./fragments";

export const CREATE_PROJECT = gql`
  mutation createProject(
    $name: String!
    $description: String
    $clientId: ID!
    $defaultPaymentOption: PaymentOption!
    $budget: Int
  ) {
    createProject(
      name: $name
      description: $description
      clientId: $clientId
      defaultPaymentOption: $defaultPaymentOption
      budget: $budget
    ) {
      status
      error
      data {
        ...ProjectFragment
      }
    }
  }
  ${projectFragment}
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: ID!
    $name: String
    $description: String
    $clientId: ID
    $defaultPaymentOption: PaymentOption!
    $budget: Int
    $needsBudgetApproval: Boolean
  ) {
    updateProject(
      id: $id
      name: $name
      description: $description
      clientId: $clientId
      defaultPaymentOption: $defaultPaymentOption
      budget: $budget
      needsBudgetApproval: $needsBudgetApproval
    ) {
      status
      error
      data {
        ...ProjectFragment
      }
    }
  }
  ${projectFragment}
`;

export const ARCHIVE_PROJECT = gql`
  mutation archiveProject($id: ID!) {
    archiveProject(id: $id) {
      status
      error
      data {
        ...ProjectFragment
      }
    }
  }
  ${projectFragment}
`;

export const APPROVE_BUDGET = gql`
  mutation approveBudget($id: ID!) {
    approveBudget(id: $id) {
      status
      error
      data {
        ...BudgetFragment
      }
    }
  }
  ${budgetFragment}
`;

export const CREATE_THEME = gql`
  mutation createTheme($projectId: ID!, $name: String!) {
    createTheme(projectId: $projectId, name: $name) {
      status
      error
      data {
        id
        name
      }
    }
  }
`;

export const UPDATE_THEME = gql`
  mutation updateTheme($id: ID!, $name: String!) {
    updateTheme(id: $id, name: $name) {
      status
      error
      data {
        id
        name
      }
    }
  }
`;

export const DELETE_THEME = gql`
  mutation deleteTheme($id: ID!) {
    deleteTheme(id: $id) {
      status
      error
      data {
        id
        name
      }
    }
  }
`;

export const SUBMIT_BUDGET = gql`
  mutation submitBudget($id: ID!) {
    submitBudget(id: $id) {
      status
      error
      data {
        id
        amount
        status
      }
    }
  }
`;

export const NEW_BUDGET = gql`
  mutation newBudget(
    $projectId: ID!
    $amount: Int!
    $themes: [BudgetThemeInput!]
  ) {
    newBudget(projectId: $projectId, amount: $amount, themes: $themes) {
      status
      error
      data {
        id
        amount
        status
        themes {
          theme {
            id
            name
          }
          amount
        }
      }
    }
  }
`;

export const UPDATE_BUDGET = gql`
  mutation updateBudget($id: ID!, $amount: Int!, $themes: [BudgetThemeInput!]) {
    updateBudget(id: $id, amount: $amount, themes: $themes) {
      status
      error
      data {
        id
        amount
        status
        themes {
          theme {
            id
            name
          }
          amount
        }
      }
    }
  }
`;
