import { useToast } from "vue-toastification";
import { DocumentNode } from "graphql";
import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { apolloClient } from "@/apollo.provider";

import { UpdatePaginatedQueryCache } from "@/helpers/cache/typesDefinition";
import { Entity, PaginatedResponse } from "@/helpers/typesDefinition";
import { transformEurosVariables } from "@/composables/utils";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function <T extends Entity>(
  mutation: DocumentNode,
  mutationName: string,
  variables: Record<string, unknown>,
  unmutableEntityProps: Record<string, unknown> | undefined,
  updatePaginatedQueryCache: UpdatePaginatedQueryCache<T, PaginatedResponse<T>>,
  errorMessage: string,
  successMessage: string,
  closeModal: () => void,
) {
  const toast = useToast();

  provideApolloClient(apolloClient);
  const { mutate, onDone, onError, loading } = useMutation(mutation, () => ({
    variables: {
      ...transformEurosVariables(variables),
      ...unmutableEntityProps,
    },
    update: (cache, { data }) => {
      const mutate = data?.[mutationName];
      if (mutate?.status === "ok") {
        const mutatedItem = mutate.data;
        updatePaginatedQueryCache(cache, mutatedItem);
      }
    },
  }));

  onDone((result) => {
    if (result.data?.[mutationName]?.status === "ok") {
      toast.success(successMessage);
      closeModal();
    } else {
      const errorResponse = result.data?.[mutationName]?.error;
      console.log(errorResponse);
      toast.error(`${errorMessage} Error: ${errorResponse}`);
    }
  });

  onError((error) => {
    logErrorMessages(error);
    toast.error(`${errorMessage} Error with graphql query`);
  });

  return {
    mutate,
    loading,
  };
}
