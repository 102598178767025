import { getIndexFirstHigherOrEqual } from "./binarySearch";
import { Comparator, Entity, PaginatedResult } from "../typesDefinition";

function removeOldEntityAndAddUpdated<T>(
  updatedEntity: T,
  entities: T[],
  indexToBeRemoved: number,
  IndexToBeAdded: number,
): void {
  entities.splice(indexToBeRemoved, 1);
  if (indexToBeRemoved >= IndexToBeAdded) {
    entities.splice(IndexToBeAdded, 0, updatedEntity);
  } else {
    entities.splice(IndexToBeAdded - 1, 0, updatedEntity);
  }
}

export function updateNonPaginatedCachedEntity<T extends Entity>(
  updatedEntity: T,
  cacheResult: T[],
  comparator: Comparator<T, T>,
): T[] {
  console.log({ cacheResult });
  const updatedEntities = [...cacheResult];
  const indexFirstHigherOrEqual = getIndexFirstHigherOrEqual(
    updatedEntities,
    updatedEntity,
    comparator,
  );
  if (updatedEntity.id === updatedEntities[indexFirstHigherOrEqual]?.id) {
    updatedEntities.splice(indexFirstHigherOrEqual, 1, updatedEntity);
    return updatedEntities;
  }
  for (let i = 0; i < updatedEntities.length; i++) {
    if (updatedEntity.id === updatedEntities[i].id) {
      removeOldEntityAndAddUpdated(
        updatedEntity,
        updatedEntities,
        i,
        indexFirstHigherOrEqual,
      );
      return updatedEntities;
    }
  }
  return updatedEntities;
}

export function updateCachedEntity<T extends Entity>(
  updatedEntity: T,
  cacheResult: PaginatedResult<T>,
  comparator: Comparator<T, T>,
): T[] {
  return updateNonPaginatedCachedEntity(
    updatedEntity,
    Array.from(cacheResult.items),
    comparator,
  );
}
