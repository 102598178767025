import gql from "graphql-tag";
import { orderFragment, recurringOrderFragment } from "./fragments";

export const CREATE_ORDER = gql`
  mutation createOrder(
    $startDate: Date!
    $endDate: Date!
    $title: String!
    $projectId: ID!
    $price: Int!
    $companyId: ID!
    $notes: String
    $legalNotice: String
    $paymentOption: PaymentOption!
    $showDetailsOnInvoice: Boolean
  ) {
    createOrder(
      startDate: $startDate
      endDate: $endDate
      title: $title
      projectId: $projectId
      price: $price
      companyId: $companyId
      notes: $notes
      legalNotice: $legalNotice
      paymentOption: $paymentOption
      showDetailsOnInvoice: $showDetailsOnInvoice
    ) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const CREATE_RECURRING_ORDER = gql`
  mutation createRecurringOrder(
    $startDate: Date!
    $endDate: Date!
    $title: String!
    $projectId: ID!
    $price: Int!
    $companyId: ID!
    $notes: String
    $legalNotice: String
    $paymentOption: PaymentOption!
    $showDetailsOnInvoice: Boolean
  ) {
    createRecurringOrder(
      startDate: $startDate
      endDate: $endDate
      title: $title
      projectId: $projectId
      price: $price
      companyId: $companyId
      notes: $notes
      legalNotice: $legalNotice
      paymentOption: $paymentOption
      showDetailsOnInvoice: $showDetailsOnInvoice
    ) {
      status
      error
      data {
        ...RecurringOrderFragment
      }
    }
  }
  ${recurringOrderFragment}
`;

export const CREATE_ORDER_LINE = gql`
  mutation createOrderLine(
    $orderId: ID
    $recurringOrderId: ID
    $projectRoleName: String!
    $hourlyRate: Int!
    $quantity: Float!
    $unit: Int!
  ) {
    createOrderLine(
      orderId: $orderId
      recurringOrderId: $recurringOrderId
      projectRoleName: $projectRoleName
      hourlyRate: $hourlyRate
      quantity: $quantity
      unit: $unit
    ) {
      status
      error
      data {
        id
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const DELETE_RECURRING_ORDER = gql`
  mutation deleteRecurringOrder($id: ID!) {
    deleteRecurringOrder(id: $id) {
      status
      error
      data {
        ...RecurringOrderFragment
        project {
          id
        }
      }
    }
  }
  ${recurringOrderFragment}
`;

export const VALIDATE_ORDERS = gql`
  mutation validateOrders {
    validateOrders {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const SEND_ORDER = gql`
  mutation sendOrder($id: ID!) {
    sendOrder(id: $id) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const SEND_RECURRING_ORDER = gql`
  mutation sendRecurringOrder($id: ID!) {
    sendRecurringOrder(id: $id) {
      status
      error
      data {
        ...RecurringOrderFragment
      }
    }
  }
  ${recurringOrderFragment}
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($id: ID!, $legalNoticeAgreement: Boolean) {
    approveOrder(id: $id, legalNoticeAgreement: $legalNoticeAgreement) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const APPROVE_RECURRING_ORDER = gql`
  mutation approveRecurringOrder($id: ID!, $legalNoticeAgreement: Boolean) {
    approveRecurringOrder(
      id: $id
      legalNoticeAgreement: $legalNoticeAgreement
    ) {
      status
      error
      data {
        recurringOrder {
          ...RecurringOrderFragment
        }
        orders {
          ...OrderFragment
        }
      }
    }
  }
  ${recurringOrderFragment}
  ${orderFragment}
`;

export const CANCEL_RECURRING_ORDER = gql`
  mutation cancelRecurringOrder($id: ID!) {
    cancelRecurringOrder(id: $id) {
      status
      error
      data {
        recurringOrder {
          ...RecurringOrderFragment
        }
        orders {
          ...OrderFragment
        }
      }
    }
  }
  ${recurringOrderFragment}
  ${orderFragment}
`;

export const SYNC_PERFORMED_ORDERS = gql`
  mutation syncPerformedOrders {
    syncPerformedOrders {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: ID!
    $title: String
    $startDate: Date
    $endDate: Date
    $price: Int
    $companyId: ID
    $notes: String
    $legalNotice: String
    $orderLines: [OrderLineUpdate!]
    $showDetailsOnInvoice: Boolean
    $paymentOption: PaymentOption
    $dueDate: Date
  ) {
    updateOrder(
      id: $id
      title: $title
      startDate: $startDate
      endDate: $endDate
      price: $price
      companyId: $companyId
      notes: $notes
      legalNotice: $legalNotice
      orderLines: $orderLines
      showDetailsOnInvoice: $showDetailsOnInvoice
      paymentOption: $paymentOption
      dueDate: $dueDate
    ) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;

export const CLOSE_ORDER = gql`
  mutation closeOrder($id: ID!) {
    closeOrder(id: $id) {
      status
      error
      data {
        ...OrderFragment
      }
    }
  }
  ${orderFragment}
`;
