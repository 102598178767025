
import { computed, defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/home/en-UK.json";

import { Timer } from "@/helpers/client/typesDefinition";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";
import BasicInput from "@/components/form/BasicInput.vue";
import useVuelidate from "@vuelidate/core";
import dayjs from "dayjs";
import { helpers, required } from "@vuelidate/validators";
import { formatDuration } from "@/helpers/dates";
import { useMutation } from "@vue/apollo-composable";
import { UPDATE_TIMER_PERIOD } from "@/graphql/timesheets/mutations";
import { useToast } from "vue-toastification";
import { GET_TIMERS } from "@/graphql/timesheets/queries";
import { logErrorMessages } from "@vue/apollo-util";

type MessageSchema = typeof enUK;

function unformatDuration(formated: string): number {
  const [h, m, s] = formated.split(":");
  return Number(h) * 60 * 60 * 1000 + Number(m) * 60 * 1000 + Number(s) * 1000;
}

export default defineComponent({
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();

    const variables = ref({
      periods: props.timer.periods.map((t) => ({
        duration: formatDuration(t.duration!),
      })),
    });

    const v$ = useVuelidate(
      {
        periods: {
          $each: helpers.forEach({
            duration: {
              required,
              isDuration: helpers.withMessage(
                t("errors.validation.durationFormat"),
                helpers.regex(/^\d{2}:\d{2}:\d{2}$/),
              ),
            },
          }),
        },
      },
      variables,
      {
        $autoDirty: true,
      },
    );

    const total = computed(() => {
      return formatDuration(
        variables.value.periods.reduce(
          (acc, p) => acc + unformatDuration(p.duration),
          0,
        ),
      );
    });

    const { mutate: updateMutation, loading: updateLoading } = useMutation(
      UPDATE_TIMER_PERIOD,
      {
        refetchQueries: [{ query: GET_TIMERS }],
      },
    );

    return {
      t,
      v$,
      variables,
      total,
      toast,
      updateMutation,
      updateLoading,
    };
  },
  props: {
    timer: {
      type: Object as PropType<Timer>,
      required: true,
    },
  },
  data() {
    return {
      enUK,
    };
  },
  emits: ["close"],
  methods: {
    dayjs,
    closeModal(): void {
      this.$emit("close");
    },
    formatTimestamp(timestamp: Date | undefined): string {
      if (!timestamp) return "-";
      return dayjs(timestamp).format("HH:mm:ss");
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        try {
          for (const [idx, input] of this.variables.periods.entries()) {
            const newDuration = unformatDuration(input.duration);
            const period = this.timer.periods[idx];
            if (newDuration !== period.duration) {
              console.log(
                `Changed ${JSON.stringify(period)} to ${newDuration}`,
              );
              await this.updateMutation({
                id: period.id,
                duration: newDuration,
              });
            }
          }
          this.toast.success(this.t("success.updateTimer"));
          this.$emit("close");
        } catch (error) {
          logErrorMessages(error);
          this.toast.error(
            this.t("errors.updateTimer", {
              message: this.t("errors.internal"),
            }),
          );
        }
      }
    },
  },
  components: {
    BasicInput,
    BasicButton,
    Modal,
  },
});
