
import { defineComponent } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { logErrorMessages } from "@vue/apollo-util";
import { useI18n } from "vue-i18n";

import { VERIFY } from "@/graphql/user/mutations";
import { GET_USER } from "@/graphql/user/queries";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });
    const toast = useToast();

    const router = useRouter();
    const route = useRoute();
    const redirect = route.query.redirect as string;

    const {
      mutate: verify,
      onDone,
      onError,
      loading,
    } = useMutation(VERIFY, () => ({
      update: (cache, data) => {
        cache.writeQuery({
          query: GET_USER,
          data: { user: data.data?.verify?.data },
        });
      },
    }));

    onDone(async (result) => {
      if (result.data?.verify?.status === "ok") {
        if (redirect) router.replace({ path: redirect });
        else router.replace({ name: "Home" });
      } else {
        const responseError: string = result.data?.verify?.error;
        console.log(responseError);
        toast.error(t("errors.verify"));
        router.replace({ name: "Login" });
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.verify"));
    });

    return {
      verify,
      loading,
      t,
    };
  },

  props: {
    email: {
      type: String,
      default: null,
    },
    token: {
      type: String,
      default: null,
    },
  },

  mounted: function (): void {
    this.verify({ email: this.email, token: this.token });
  },
});
