import gql from "graphql-tag";
import { templateFragment } from "./fragments";
import { phaseFragment } from "@/graphql/orcha/phases/fragments";
import { milestoneFragment } from "@/graphql/orcha/milestones/fragments";
import { templateActivityFragment } from "@/graphql/orcha/activities/fragments";
import { templateTaskFragment } from "../tasks/fragments";

export const GET_TEMPLATES = gql`
  query getTemplates {
    templates {
      id
      ...TemplateFragment
      phases {
        id
        ...PhaseFragment
        milestones {
          id
          ...MilestoneFragment
          activities {
            id
            ...TemplateActivityFragment
            tasks {
              ...TemplateTaskFragment
            }
          }
        }
      }
    }
  }
  ${templateFragment}
  ${phaseFragment}
  ${milestoneFragment}
  ${templateActivityFragment}
  ${templateTaskFragment}
`;

export const GET_TEMPLATES_NAMES = gql`
  query getTemplates {
    templates {
      id
      name
    }
  }
`;
