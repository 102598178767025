
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/project/en-UK.json";

import { Project } from "@/helpers/project/typesDefinition";
import { useQuery } from "@vue/apollo-composable";
import { GET_FULL_PROJECT } from "@/graphql/project/queries";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";
import { formatPrice } from "@/helpers/utils";
import Badge from "@/components/Badge.vue";
import ProgressBar from "@/components/ProgressBar.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  components: { Badge, ProgressBar },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();
    const budget = ref();

    const { result, loading, onError, onResult } = useQuery(
      GET_FULL_PROJECT,
      () => ({
        id: props.id,
      }),
    );

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.get"));
    });

    onResult((result) => {
      const p: Project | undefined = result.data.project;
      budget.value = p?.budget?.amount;
    });

    const project = computed(() => result.value?.project);

    return {
      t,
      loading,
      budget,
      formatPrice,
      project,
    };
  },
});
