import { ApolloCache } from "@apollo/client";
import {
  Order,
  OrdersDeltasAndInvoicesResponse,
  RecurringOrder,
} from "./typesDefinition";
import { UpdateCachedEntities } from "../cache/typesDefinition";
import { GET_ORDERS_DELTAS_AND_INVOICES } from "@/graphql/order/queries";
import { Delta } from "../delta/typesDefinition";
import {
  orderDeltaAndInvoiceComparator,
  recurringOrderComparator,
} from "./orderComparator";
import {
  ProjectRecurringOrdersResponse,
  ProjectResponse,
} from "../project/typesDefinition";
import {
  GET_FULL_PROJECT,
  GET_PROJECT_INVOICES_MENU,
  GET_PROJECT_RECURRING_ORDERS,
} from "@/graphql/project/queries";
import { getIndexFirstHigherOrEqual } from "../cache/binarySearch";
import { Invoice, InvoicesMenuItem } from "../invoice/typesDefinition";

function deltaAndDateComparator(
  date: string,
  invoicesMenuItem: InvoicesMenuItem,
): number {
  return -date.localeCompare(invoicesMenuItem.menuDate);
}

function updateInvoiceMenu(
  existingInvoicesMenu: InvoicesMenuItem[],
  date: string | undefined,
): InvoicesMenuItem[] {
  if (!date) return existingInvoicesMenu;
  const existingInvoicesMenuItemIndex = existingInvoicesMenu.findIndex(
    (invoiceMenuItem) => invoiceMenuItem.menuDate === date,
  );
  if (existingInvoicesMenuItemIndex >= 0) {
    return existingInvoicesMenu;
  }
  const indexFirstHigherOrEqual = getIndexFirstHigherOrEqual(
    existingInvoicesMenu,
    date,
    deltaAndDateComparator,
  );
  if (indexFirstHigherOrEqual < 0)
    throw new Error("Can't insert new entity in Cache");
  existingInvoicesMenu.splice(indexFirstHigherOrEqual, 0, {
    menuDate: date,
    menuBalance: 0,
    menuTotal: 0,
    menuOrdered: 0,
  });
  return existingInvoicesMenu;
}

export function updateOrdersCache(
  cache: ApolloCache<OrdersDeltasAndInvoicesResponse>,
  projectId: string,
  date: string | undefined,
  newItem: Order,
  updateCachedEntities: UpdateCachedEntities<Order | Delta | Invoice>,
  orderCountDelta: number,
): void {
  const graphQLResponse: OrdersDeltasAndInvoicesResponse | null =
    cache.readQuery({
      query: GET_ORDERS_DELTAS_AND_INVOICES,
      variables: { projectId, date },
    });

  if (graphQLResponse) {
    const ordersDeltasAndInvoices = graphQLResponse.ordersDeltasAndInvoices;

    const updatedItems = updateCachedEntities(
      newItem,
      ordersDeltasAndInvoices,
      orderDeltaAndInvoiceComparator,
    );

    cache.writeQuery({
      query: GET_ORDERS_DELTAS_AND_INVOICES,
      variables: { projectId, date },
      data: {
        ordersDeltasAndInvoices: updatedItems,
      },
    });
  }

  const invoicesMenuGraphQLResponse: ProjectResponse | null = cache.readQuery({
    query: GET_PROJECT_INVOICES_MENU,
    variables: { id: projectId },
  });

  if (invoicesMenuGraphQLResponse) {
    const updatedInvoiceMenu = updateInvoiceMenu(
      Array.from(invoicesMenuGraphQLResponse.project.invoicesMenu),
      date,
    );

    cache.writeQuery({
      query: GET_PROJECT_INVOICES_MENU,
      variables: { id: projectId },
      data: {
        project: {
          ...invoicesMenuGraphQLResponse.project,
          invoicesMenu: updatedInvoiceMenu,
        },
      },
    });
  }

  const projectGraphQLResponse: ProjectResponse | null = cache.readQuery({
    query: GET_FULL_PROJECT,
    variables: { id: projectId },
  });

  if (projectGraphQLResponse) {
    cache.writeQuery({
      query: GET_FULL_PROJECT,
      variables: { id: projectId },
      data: {
        project: {
          ...projectGraphQLResponse.project,
          nextOrderNumber:
            projectGraphQLResponse.project.nextOrderNumber +
            Math.max(orderCountDelta, 0),
        },
      },
    });
  }
}

export function updateRecurringOrdersCache(
  cache: ApolloCache<ProjectRecurringOrdersResponse>,
  projectId: string,
  newItem: RecurringOrder,
  updateCachedEntities: UpdateCachedEntities<RecurringOrder>,
): void {
  const graphQLResponse: ProjectRecurringOrdersResponse | null =
    cache.readQuery({
      query: GET_PROJECT_RECURRING_ORDERS,
      variables: { id: projectId },
    });

  if (graphQLResponse) {
    const recurringOrders = graphQLResponse.project.recurringOrders;

    const updatedItems = updateCachedEntities(
      newItem,
      recurringOrders,
      recurringOrderComparator,
    );

    cache.writeQuery({
      query: GET_PROJECT_RECURRING_ORDERS,
      variables: { id: projectId },
      data: {
        project: {
          ...graphQLResponse.project,
          recurringOrders: updatedItems,
        },
      },
    });
  }
}
