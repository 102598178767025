import { Client } from "../client/typesDefinition";
import { Invoice, InvoicesMenuItem } from "../invoice/typesDefinition";
import { Order, RecurringOrder } from "../order/typesDefinition";
import { PaginatedResponse, Entity, PaginatedResult } from "../typesDefinition";
import { User } from "@/helpers/user/typesDefinition";

export interface ProjectRole extends Entity {
  name: string;
  hourlyRate: number;
}

export interface Budget extends Entity {
  amount: number;
  approvedAt: Date;
}

export interface File extends Entity {
  url: string;
  name: string;
}

export interface Project extends Entity {
  name: string;
  client: Client;
  defaultPaymentOption: string;
  projectRoles: ProjectRole[];
  orders: PaginatedResult<Order>;
  nextOrderNumber: number;
  nextRecurringOrderNumber: number;
  invoicesMenu: InvoicesMenuItem[];
  budget?: Budget;
  previouslyApprovedBudget?: Budget;
  totalBilled: number;
  totalBilledNet: number;
  totalNotBilledOrdered: number;
  totalPaid: number;
  totalPaidNet: number;
  totalApprovedOrderedAmount: number;
  totalOrderedAmount: number;
  performedAmount: number;
  description?: string;
  lateApprovalOrdersCount: number;
  latePaymentInvoicesCount: number;
  latePaymentBalance: number;
  latePaymentDays: number;
  budgetWarning?: boolean;
  active: boolean;
  color?: string;
  internal: boolean;
  architectureImage?: File;
}

export interface ProjectResponse {
  project: Project;
}

export interface ProjectRecurringOrdersResponse {
  project: {
    id: string;
    client: { id: string };
    recurringOrders: RecurringOrder[];
  };
}

export interface ProjectPaginatedResponse extends PaginatedResponse<Project> {
  projects: PaginatedResult<Project>;
}

export interface Theme extends Entity {
  name: string;
  project: Partial<Project> & { id: string; name: string };
}

export interface LoggedHours {
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  project: {
    id: string;
  };
  projectRole?: ProjectRole;
  startDate: string;
  endDate: string;
  loggedHours: number;
  plannedHours: number;
}

export interface CurrentOrder {
  id: string;
  title: string;
  price: number;
  performedAmount: number;
  startDate: string;
  endDate: string;
  invoiced: boolean;
}

export enum PlannedTimeFrequency {
  NO_REPEAT = "no_repeat",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  EVERY_2_WEEKS = "every_2_weeks",
  EVERY_3_WEEKS = "every_3_weeks",
  EVERY_6_WEEKS = "every_6_weeks",
  EVERY_3_MONTHS = "every_3_months",
  EVERY_6_MONTHS = "every_6_months",
  YEARLY = "yearly",
}
export interface PlannedTime {
  id: string;
  user: User;
  startDate: Date;
  endDate: Date;
  durationPerDay: number;
  frequency: PlannedTimeFrequency;
  repeatEndDate?: Date;
  active: boolean;
  projectRole?: ProjectRole;
  project: Project;
}

export interface Holiday {
  id: string;
  date: Date;
  name?: string;
}

export interface TimeOff {
  id: string;
  user: User;
  durationPerDay: number;
  frequency: PlannedTimeFrequency;
  startDate: Date;
  endDate: Date;
  repeatEndDate?: Date;
  active: boolean;
}

export interface InvoicedAmount {
  gross: number;
  net: number;
  paid: number;
}

export interface WorkingPeriod {
  startDate: string;
  endDate: string;
  performedAmount: number;
  orderedAmount: number;
  invoicedAmount: InvoicedAmount;
  plannedTimes?: PlannedTime[];
  holidays?: Holiday[];
  timeOffs?: TimeOff[];
  invoices: Invoice[];
  orders: Order[];
}

export interface MonthlyProjectResult {
  id: string;
  name: string;
  color?: string | null;
  workingPeriod: WorkingPeriod;
}

export interface LoggedHoursResult {
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  loggedHours: number;
  plannedHours: number;
}

export interface ThemeResponse {
  theme: Omit<Theme, "project">;
}

export interface BudgetWithThemes extends Budget {
  themes: {
    __typename: "BudgetTheme";
    theme: Entity & { performedAmount: number };
    amount: number;
    invoicedAmount: number;
  }[];
}

export interface ThemeProjectWithBudget extends Entity {
  name: string;
  externalId: string;
}

export interface ProjectWithBudgetAndThemes extends Project {
  budget: BudgetWithThemes;
  themes: ThemeProjectWithBudget[];
}
