import { IndependentInvoicesResponse, Invoice } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { invoicesComparator } from "./utils";
import { GET_INDEPENDENT_INVOICES } from "@/graphql/invoice/queries";

const IndependentInvoicesCacheInfoHolder: PaginatedCacheInfoHolder<
  Invoice,
  IndependentInvoicesResponse
> = {
  query: GET_INDEPENDENT_INVOICES,

  cachedPaginatedResult: (
    cachedResponse: IndependentInvoicesResponse | null,
  ): PaginatedResult<Invoice> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.independentInvoices;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<Invoice>,
  ): IndependentInvoicesResponse => ({
    independentInvoices: paginatedResult,
  }),

  comparator: invoicesComparator,
};

export default IndependentInvoicesCacheInfoHolder;
