
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { DocumentNode } from "graphql";
import _ from "lodash";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";

import usePaginatedCacheMutation from "@/composables/usePaginatedCacheMutation";

import { PaginatedResponse, Entity } from "@/helpers/typesDefinition";

import messageSchema from "@/locales/client/en-UK.json";
import { UpdatePaginatedQueryCache } from "@/helpers/cache/typesDefinition";
import { useMutation, UseMutationOptions } from "@vue/apollo-composable";
import { transformEurosVariables } from "@/composables/utils";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";

type MessageSchema = typeof messageSchema;

export default defineComponent({
  props: {
    enUK: {
      type: Object as PropType<MessageSchema>,
      required: true,
    },
    entityProps: {
      type: Object,
      required: true,
    },
    unmutableEntityProps: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: Object,
      required: false,
    },
    validationRules: {
      type: Object,
      required: true,
    },
    mutation: {
      type: Object as PropType<DocumentNode>,
      required: true,
    },
    hiddenProperties: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    readOnlyProperties: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    mutationName: {
      type: String,
      required: true,
    },
    updatePaginatedQueryCache: {
      type: Function as PropType<
        UpdatePaginatedQueryCache<Entity, PaginatedResponse<Entity>>
      >,
      required: false,
    },
    refetchQueries: {
      type: Object as PropType<UseMutationOptions["refetchQueries"]>,
      required: false,
    },
    onSave: {
      type: Function,
    },
    formQueries: {
      type: Object,
      required: false,
    },
    formOptions: {
      type: Object,
      required: false,
    },
    modes: {
      type: Object as PropType<Record<string, "single" | "multiple" | "tags">>,
      default: () => ({}),
    },
    messages: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": props.enUK,
      },
    });

    const variables = reactive(props.entityProps);

    const v$ = useVuelidate(props.validationRules, variables, {
      $autoDirty: true,
    });

    function closeModal(): void {
      context.emit("close");
    }

    const toast = useToast();
    const mutation = ref();

    watch(variables, (newVariables) => {
      if (props.updatePaginatedQueryCache) {
        mutation.value = usePaginatedCacheMutation(
          props.mutation,
          props.mutationName,
          newVariables,
          props.unmutableEntityProps,
          props.updatePaginatedQueryCache,
          props.messages.error,
          props.messages.success,
          closeModal,
        );
      } else {
        const mutationObj = useMutation(props.mutation, {
          variables: {
            ...transformEurosVariables(newVariables),
            ...props.unmutableEntityProps,
          },
          ...(props.refetchQueries
            ? { refetchQueries: props.refetchQueries }
            : {}),
        });
        mutationObj.onDone((result) => {
          if (result.data?.[props.mutationName]?.status === "ok") {
            toast.success(props.messages.success);
            closeModal();
          } else {
            const errorResponse = result.data?.[props.mutationName]?.error;
            console.log(errorResponse);
            toast.error(`${props.messages.error} Error: ${errorResponse}`);
          }
        });

        mutationObj.onError((error) => {
          logErrorMessages(error);
          toast.error(`${props.messages.error} Error with graphql query`);
        });
        mutation.value = mutationObj;
      }
    });

    return {
      mutate: mutation,
      v$,
      t,
      variables,
      closeModal,
    };
  },
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        if (this.onSave) this.onSave(this.variables);
        else await this.mutate.mutate();
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterHiddenProperties(object: { [x: string]: any }): { [x: string]: any } {
      return _.omit(object, ["id", "__typename", ...this.hiddenProperties]);
    },
    inputType(
      name: string,
    ): "basic" | "textArea" | "date" | "inline-date" | "checkbox" {
      if (["notes", "comment"].includes(name)) return "textArea";
      if (["dueDate", "promisedDate"].includes(name)) return "inline-date";
      if (["vatExempted", "needsBudgetApproval"].includes(name))
        return "checkbox";
      return "basic";
    },
  },
  provide() {
    return {
      loadingMessage: this.t("loading"),
    };
  },
  components: {
    FormItem,
    Modal,
    BasicButton,
  },
});
