import { InvoicePaginatedResponse, Invoice } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { GET_OPENED_INVOICES } from "@/graphql/invoice/queries";
import dayjs from "dayjs";

const OpenedInvoicesCacheInfoHolder: PaginatedCacheInfoHolder<
  Invoice,
  InvoicePaginatedResponse
> = {
  query: GET_OPENED_INVOICES,

  cachedPaginatedResult: (
    cachedResponse: InvoicePaginatedResponse | null,
  ): PaginatedResult<Invoice> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.invoices;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<Invoice>,
  ): InvoicePaginatedResponse => ({
    invoices: paginatedResult,
  }),

  comparator: (i1: Invoice, i2: Invoice): number => {
    const date1 = dayjs(i1.promisedDate ?? i1.dueDate);
    const date2 = dayjs(i2.promisedDate ?? i2.dueDate);
    const fn = () => {
      if (date1.isSame(date2)) return 0;
      return date1.isBefore(date2) ? -1 : 1;
    };
    const result = fn();
    console.log({ date1, date2, result });
    return result;
  },
};

export default OpenedInvoicesCacheInfoHolder;
