
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/delta/en-UK.json";

import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";
import BasicButton from "@/components/BasicButton.vue";

import { GET_OPENED_CREDIT_NOTES } from "@/graphql/invoice/queries";
import { CLOSE_CREDIT_NOTES } from "@/graphql/invoice/mutations";

import { apolloClient } from "@/apollo.provider";
import { useToast } from "vue-toastification";
import { Invoice } from "@/helpers/invoice/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const selectAll = ref(false);
    const checkBoxes = ref();
    const openedCreditNotes = ref();

    const loading = ref(false);

    async function closeCreditNotes(creditNotesIds: string[]): Promise<void> {
      try {
        loading.value = true;
        const result = await apolloClient.mutate({
          mutation: CLOSE_CREDIT_NOTES,
          variables: {
            ids: creditNotesIds,
          },
        });
        loading.value = false;
        if (result.data?.closeCreditNotes?.status === "ok") {
          toast.success(t("success.closeCreditNotes"));
        } else {
          const errorResponse = result.data?.closeCreditNotes?.error;
          console.log(errorResponse);
          toast.error(
            t("errors.closeCreditNotes", {
              message: errorResponse,
            }),
          );
        }
      } catch (error) {
        console.log(error);
        toast.error(
          t("errors.closeCreditNotes", { message: t("errors.internal") }),
        );
      }
    }

    async function closeSelectedCreditNotes(): Promise<void> {
      const creditNotesIds = openedCreditNotes.value.map(
        (creditNote: Invoice) => creditNote.id,
      );
      const selectedCreditNotesIds = creditNotesIds.filter(
        (_id: string, index: number) => checkBoxes.value[index],
      );
      return closeCreditNotes(selectedCreditNotesIds);
    }

    return {
      t,
      checkBoxes,
      selectAll,
      openedCreditNotes,
      loading,
      closeSelectedCreditNotes,
    };
  },
  data() {
    return {
      enUK,
      GET_OPENED_CREDIT_NOTES,
    };
  },
  emits: [],
  provide: {
    columnsOrder: {
      client: null,
      company: null,
      invoiceNumber: null,
      date: null,
      total: null,
      invoiceStatus: null,
    },
  },
  methods: {
    formatClient(
      _value: undefined,
      _name: string,
      invoice: Invoice,
    ): string | undefined {
      return invoice.company.client.name;
    },
  },
  components: {
    PaginatedEntitiesList,
    BasicButton,
  },
});
