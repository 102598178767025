
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@vuelidate/validators";

import enUK from "@/locales/project/en-UK.json";

import { UPDATE_PROJECT } from "@/graphql/project/mutations";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import ProjectsCacheInfoHolder from "@/helpers/project/ProjectsCacheInfoHolder";
import { updateCachedEntity } from "@/helpers/cache/updateCachedEntity";
import { paymentOptions } from "@/helpers/order/utils";
import { ApolloCache } from "@apollo/client";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import {
  ProjectPaginatedResponse,
  Project,
} from "@/helpers/project/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    function updateProject(
      cache: ApolloCache<ProjectPaginatedResponse>,
      mutatedItem: Project,
    ) {
      return updatePaginatedQueryCache(
        cache,
        mutatedItem,
        ProjectsCacheInfoHolder,
        updateCachedEntity,
      );
    }

    return { t, updateProject };
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  data() {
    return {
      enUK,
      validationRules: computed(() => ({
        id: {},
        name: {
          required,
        },
        defaultPaymentOption: {
          required,
        },
        needsBudgetApproval: {},
      })),
      formOptions: {
        defaultPaymentOption: paymentOptions,
      },
      UPDATE_PROJECT,
      messages: {
        formButtonLabel: this.t("buttons.edit"),
        error: this.t("errors.edit"),
        success: this.t("success.edit"),
      },
    };
  },
  components: {
    MutateEntity,
  },
});
