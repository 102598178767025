import gql from "graphql-tag";
import { invoiceFragment } from "../invoice/fragments";
import { orderFragment } from "../order/fragments";
import { deltaFragment } from "./fragments";

export const SYNC_DELTAS = gql`
  mutation syncDeltas {
    syncDeltas {
      status
      error
      data {
        ...DeltaFragment
        invoice {
          ...InvoiceFragment
        }
      }
    }
  }
  ${deltaFragment}
  ${invoiceFragment}
`;

export const CREATE_INVOICE_FOR_DELTA = gql`
  mutation createInvoiceForDelta(
    $id: ID!
    $companyId: ID!
    $dueDate: Date!
    $showDetailsOnInvoice: Boolean!
  ) {
    createInvoiceForDelta(
      id: $id
      companyId: $companyId
      dueDate: $dueDate
      showDetailsOnInvoice: $showDetailsOnInvoice
    ) {
      status
      error
      data {
        ... on Delta {
          ...DeltaFragment
          invoice {
            ...InvoiceFragment
          }
        }
        ... on Order {
          ...OrderFragment
        }
      }
    }
  }
  ${deltaFragment}
  ${orderFragment}
  ${invoiceFragment}
`;

export const UPDATE_DELTA = gql`
  mutation updateDelta($id: ID!, $status: BaseDeltaStatus, $companyId: ID) {
    updateDelta(id: $id, status: $status, companyId: $companyId) {
      status
      error
      data {
        ...DeltaFragment
      }
    }
  }
  ${deltaFragment}
`;

export const IGNORE_DELTA = gql`
  mutation ignoreDelta($id: ID!) {
    ignoreDelta(id: $id) {
      status
      error
      data {
        ...DeltaFragment
      }
    }
  }
  ${deltaFragment}
`;

export const CREATE_NEW_DELTAS_FOR_PROJECTS = gql`
  mutation createNewDeltasForProjects($ids: [ID!]) {
    createNewDeltasForProjects(projectIds: $ids) {
      status
      error
      data {
        ...DeltaFragment
        project {
          id
        }
      }
    }
  }
  ${deltaFragment}
`;
