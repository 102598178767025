import { apolloClient } from "@/apollo.provider";

import { GET_USER } from "@/graphql/user/queries";
import { AccessRight } from "../typesDefinition";
import { User } from "../user/typesDefinition";

export async function getUser(): Promise<User> {
  const result = await apolloClient.query({
    query: GET_USER,
  });
  return result.data.user;
}

export function isAdmin(accessRights: AccessRight[] | undefined): boolean {
  if (!accessRights) return false;
  return accessRights.some(
    (accessRight) =>
      accessRight.role === "admin" || accessRight.role === "super_admin",
  );
}

export function isEmployee(accessRights: AccessRight[] | undefined): boolean {
  if (!accessRights) return false;
  return accessRights.some((accessRight) => accessRight.role === "employee");
}

export function isClient(accessRights: AccessRight[] | undefined): boolean {
  console.log({ accessRights });
  if (!accessRights) return false;
  return accessRights.some((accessRight) => accessRight.role === "client");
}

export function isProjectManager(
  accessRights: AccessRight[] | undefined,
): boolean {
  if (!accessRights) return false;
  return accessRights.some(
    (accessRight) => accessRight.role === "project_manager",
  );
}
