import api from "./api";

export async function uploadFile(files: File[]): Promise<string[]> {
  if (files.length === 0) return [];
  const formData = new FormData();
  files.forEach((file) => formData.append("file", file));
  const result = await api.post("files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result.data;
}
