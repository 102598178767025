import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55e10e36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "entities-list"
}
const _hoisted_2 = {
  key: 0,
  class: "flex-item-list checkbox"
}
const _hoisted_3 = ["onUpdate:modelValue", "onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "option-icon"
}
const _hoisted_6 = {
  key: 1,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!
  const _component_DropDownItem = _resolveComponent("DropDownItem")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_ctx.elements?.length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (object, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: object.id
          }, [
            (_ctx.hasCheckBox)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelCheckBoxes[index]) = $event),
                    onClick: ($event: any) => (_ctx.$emit('updateCheckBoxes', index))
                  }, null, 8, _hoisted_3), [
                    [_vModelCheckbox, _ctx.modelCheckBoxes[index]]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortColumns(
          _ctx.filterOutIdAndTypename(object),
        ), (value, name, valueIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([name, "flex-item-list"]),
                key: valueIndex,
                onClick: ($event: any) => (_ctx.$emit('goEntityDetails', object))
              }, _toDisplayString(_ctx.formatValue(value, name, object)), 11, _hoisted_4))
            }), 128)),
            _createElementVNode("div", {
              class: "dropdown-wrapper",
              ref_for: true,
              ref: 
          (el) => {
            _ctx.getRef(el, object.id);
          }
        
            }, [
              (_ctx.hasIcon)
                ? (_openBlock(), _createBlock(_component_OnClickOutside, {
                    key: 0,
                    onTrigger: _ctx.closeDropdown
                  }, {
                    default: _withCtx(() => [
                      (
              object.__typename === 'Order' ||
              (object.deltaStatus && object.deltaStatus !== 'new') ||
              (object.invoiceStatus && object.invoiceStatus !== 'new') ||
              (object.status && object.status !== 'new')
            )
                        ? (_openBlock(), _createBlock(_component_IconButton, {
                            key: 0,
                            iconName: "bi:cloud-download",
                            onClick: () => _ctx.toggleDropdown(object.id)
                          }, null, 8, ["onClick"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5))
                    ]),
                    _: 2
                  }, 1032, ["onTrigger"]))
                : _createCommentVNode("", true),
              (_ctx.isDropdownActive(object.id))
                ? (_openBlock(), _createBlock(_component_DropdownMenu, {
                    reference: _ctx.targetElements[object.id],
                    key: object.id
                  }, {
                    default: _withCtx(() => [
                      (object.__typename === 'Delta')
                        ? (_openBlock(), _createBlock(_component_DropDownItem, {
                            key: 0,
                            label: _ctx.t('dropDownMenu.download.timesheets'),
                            iconName: "akar-icons:download",
                            href: `${_ctx.backendUri}/documents/reports/delta/${object.id}`,
                            target: "_blank"
                          }, null, 8, ["label", "href"]))
                        : _createCommentVNode("", true),
                      (object.invoiceId)
                        ? (_openBlock(), _createBlock(_component_DropDownItem, {
                            key: 1,
                            label: _ctx.t('dropDownMenu.download.invoice'),
                            iconName: "akar-icons:download",
                            href: `${_ctx.backendUri}/documents/invoice/${object.invoiceId}`,
                            target: "_blank"
                          }, null, 8, ["label", "href"]))
                        : _createCommentVNode("", true),
                      (object.__typename === 'Order')
                        ? (_openBlock(), _createBlock(_component_DropDownItem, {
                            key: 2,
                            label: _ctx.t('dropDownMenu.seeOrder'),
                            iconName: "eva:eye-fill",
                            onClick: ($event: any) => (_ctx.$emit('goEntityDetails', object))
                          }, null, 8, ["label", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["reference"]))
                : _createCommentVNode("", true)
            ], 512)
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.emptySpace), 1))
}