import { ref, inject, type App, type InjectionKey, type Ref } from "vue";

export const isWorkFullScreenKey = Symbol.for(
  "isWorkFullScreen",
) as InjectionKey<Ref<boolean>>;

const isFullScreen = ref(false);

export const isWorkFullScreenPlugin = {
  install: (app: App) => {
    app.provide(isWorkFullScreenKey, isFullScreen);
  },
};

export const useIsWorkFullScreen = () => {
  const isWorkFullScreen = inject(isWorkFullScreenKey) as Ref<boolean>;
  return {
    isWorkFullScreen,
  };
};
