
import { defineComponent, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";

import SideBar from "@/components/SideBar.vue";

import { apolloClient } from "./apollo.provider";

export default defineComponent({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  methods: {
    hasSideBar() {
      if (
        this.$route.name?.toString() === "Login" ||
        this.$route.name?.toString() === "Verify"
      )
        return false;
      return true;
    },
    getClass() {
      if (this.hasSideBar()) return "router-view";
      return "login-router-view";
    },
  },
  components: {
    SideBar,
  },
});
