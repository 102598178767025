import { GET_CLIENT } from "@/graphql/client/queries";
import { ApolloCache } from "@apollo/client";
import { updateAccessRightsInClient } from "../cache/updateAccessRightsInClient";
import { User } from "../user/typesDefinition";
import { userAndUserResponseComparator } from "../user/utils";
import ClientAccessRightsCacheInfoHolder from "./ClientAccessRightsCacheInfoHolder";
import { ClientResponse } from "./typesDefinition";

export default function (
  cache: ApolloCache<ClientResponse>,
  newItem: User,
  id: string,
): void {
  const graphQLResponse: ClientResponse | null = cache.readQuery({
    query: GET_CLIENT,
    variables: { id },
  });

  const paginatedResult =
    ClientAccessRightsCacheInfoHolder.cachedPaginatedResult(graphQLResponse);
  const entity = ClientAccessRightsCacheInfoHolder.cachedEntity(
    graphQLResponse,
    id,
  );

  const updatedItems = Array.from(paginatedResult.items);
  updateAccessRightsInClient(
    newItem,
    updatedItems,
    userAndUserResponseComparator,
  );

  cache.writeQuery({
    query: ClientAccessRightsCacheInfoHolder.query,
    variables: { id },
    data: ClientAccessRightsCacheInfoHolder.cachedResponse(
      {
        ...paginatedResult,
        items: updatedItems,
      },
      entity,
    ),
  });
}
