import { RouteRecordRaw } from "vue-router";

import Clients from "@/views/client/Clients.vue";
import ClientDetails from "@/views/client/ClientDetails.vue";
import ClientProjects from "@/views/client/ClientProjects.vue";
import ClientUsers from "@/views/client/ClientUsers.vue";
import ClientCompanies from "@/views/client/ClientCompanies.vue";

export const clientRoutes: Array<RouteRecordRaw> = [
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/client/:id",
    name: "ClientDetails",
    component: ClientDetails,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "ClientProjects",
        component: ClientProjects,
        meta: { requiresAuth: true },
      },
      {
        path: "users",
        name: "ClientUsers",
        component: ClientUsers,
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: "companies",
        name: "ClientCompanies",
        component: ClientCompanies,
        meta: { requiresAuth: true },
      },
    ],
  },
];
