
import { defineComponent, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/project/en-UK.json";

import { GET_PROJECT_INVOICES } from "@/graphql/invoice/queries";
import usePaginatedQuery from "@/composables/usePaginatedQuery";
import { CompanyInvoices, Invoice } from "@/helpers/invoice/typesDefinition";
import ProjectCompanyFinancialData from "@/components/dashboard/ProjectCompanyFinancialData.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  components: { ProjectCompanyFinancialData },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const financialData = ref();

    const { loading, paginatedResult, onResult, loadMore } = usePaginatedQuery(
      GET_PROJECT_INVOICES,
      t("errors.getProjectFinancialData", {
        message: t("errors.internal"),
      }),
      {
        projectId: props.id,
      },
    );

    onResult(() => {
      if (paginatedResult.value.hasMore) {
        nextTick().then(loadMore);
      }
      const invoices: Invoice[] = paginatedResult.value.items;
      const companyInvoices = invoices.reduce(
        (acc: CompanyInvoices[], invoice) => {
          const ciIdx = acc.findIndex((ci) => ci.id === invoice.company.id);
          if (ciIdx !== -1) {
            const ci = acc[ciIdx];
            acc[ciIdx] = {
              ...ci,
              invoices: [...ci.invoices, invoice],
            };
            return acc;
          } else {
            return [
              ...acc,
              {
                id: invoice.company.id,
                name: invoice.company.name,
                invoices: [invoice],
              },
            ];
          }
        },
        [],
      );
      financialData.value = companyInvoices.map((ci: CompanyInvoices) => {
        const { totalInvoiced, totalPaid, totalToPay } = ci.invoices.reduce(
          (acc, invoice) => {
            return {
              totalInvoiced: {
                net: acc.totalInvoiced.net + invoice.netTotal,
                gross: acc.totalInvoiced.gross + invoice.total,
              },
              totalPaid: acc.totalPaid + (invoice.total - invoice.balance),
              totalToPay: acc.totalToPay + invoice.balance,
            };
          },
          { totalInvoiced: { net: 0, gross: 0 }, totalPaid: 0, totalToPay: 0 },
        );
        return {
          id: ci.id,
          name: ci.name,
          totalInvoiced,
          totalPaid,
          totalToPay,
        };
      });
    });

    return {
      t,
      loading,
      financialData,
    };
  },
});
