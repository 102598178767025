
import dayjs from "dayjs";
import _ from "lodash";
import { defineComponent, inject, PropType, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";

import DropdownMenu from "../dropDownMenu/DropDownMenu.vue";
import DropDownItem from "@/components/dropDownMenu/DropDownItem.vue";
import IconButton from "@/components/IconButton.vue";
import { OnClickOutside } from "@vueuse/components";

import { Entity } from "@/helpers/typesDefinition";
import { formatPrice } from "@/helpers/utils";
import { Project } from "@/helpers/project/typesDefinition";
import config from "@/config";

export default defineComponent({
  props: {
    elements: {
      type: Object as PropType<readonly Entity[]>,
      required: false,
    },
    checkBoxes: {
      type: Array,
      required: false,
    },
    hasCheckBox: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    emptySpace: {
      type: String,
    },
    formatters: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["goEntityDetails", "updateCheckBoxes"],
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });
    const columnsOrder = inject("columnsOrder");
    const modelCheckBoxes = ref(props.checkBoxes);
    const activeDropdown = ref("");
    const isDropdownActive = (dropdownId: string) => {
      return activeDropdown.value === dropdownId;
    };
    const backendUri = config.backendUri;
    const toggleDropdown = (dropdownId: string) => {
      const newActiveDropdown =
        activeDropdown.value === dropdownId ? "" : dropdownId;
      activeDropdown.value = newActiveDropdown;
    };

    const closeDropdown = () => {
      toggleDropdown("");
    };

    const targetElements = reactive<Record<string, HTMLElement | null>>({});
    const getRef = (el: HTMLElement | null, id: string) => {
      targetElements[id] = el;
    };

    const formatDate = (date: string) => {
      return dayjs(date).format("MMMM YY");
    };

    return {
      t,
      columnsOrder,
      modelCheckBoxes,
      backendUri,
      isDropdownActive,
      toggleDropdown,
      getRef,
      targetElements,
      closeDropdown,
      formatDate,
    };
  },
  watch: {
    selectAll(newValue) {
      this.modelCheckBoxes = this.modelCheckBoxes?.map(() => newValue);
    },
    checkBoxes(newValue) {
      this.modelCheckBoxes = newValue;
    },
  },
  methods: {
    filterOutIdAndTypename(object: Entity & { invoiceId?: string }): unknown {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, invoiceId, __typename, ...filteredObject } = object;
      return filteredObject;
    },
    sortColumns(object: Entity): unknown {
      if (this.columnsOrder) {
        const newOrder = { ...(this.columnsOrder as Record<string, boolean>) };
        return _.assign(newOrder, _.pick(object, _.keys(newOrder)));
      }
      return object;
    },
    formatValue(
      value: number | string | Project | boolean,
      name: string,
      object: Entity,
    ): string | number {
      if (this.formatters[name]) {
        return this.formatters[name](value, name, object);
      }
      if (
        name === "price" ||
        name === "taxedPrice" ||
        name === "ordersTotal" ||
        name === "timesheetTotal" ||
        name === "deltaTotal" ||
        name === "total" ||
        name === "paid" ||
        name === "toBePaid" ||
        name === "balance" ||
        name === "budget"
      )
        return formatPrice(value as number);
      if (value instanceof Object) return value.name;
      if (
        (name === "paymentOption" || name === "defaultPaymentOption") &&
        value
      ) {
        return this.t(`paymentOptionLabels.${value}`);
      }
      if (name === "status") return this.t(`statusLabels.${value}`);
      if (name === "startDate" || name === "endDate" || name === "date")
        return this.formatDate(value as string);
      if (_.isBoolean(value)) return value.toString();
      if (!value) return "-";
      return value;
    },
  },
  components: {
    DropdownMenu,
    DropDownItem,
    IconButton,
    OnClickOutside,
  },
});
