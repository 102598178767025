import { ApolloError, FetchResult } from "@apollo/client";
import { logErrorMessages } from "@vue/apollo-util";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

export default function () {
  const toast = useToast();
  const { t } = useI18n({
    useScope: "global",
    inheritLocale: true,
  });
  const createErrorHandler = (mutation: string) => (error: ApolloError) => {
    logErrorMessages(error);
    toast.error(t(`errors.${mutation}`));
  };
  const createResultHandler =
    (mutation: string, onSuccess: () => void = () => {}) =>
    (result: FetchResult) => {
      if (result.data?.[mutation]?.status !== "ok") {
        const errorResponse = result.data?.[mutation]?.error;
        console.log(errorResponse);
        toast.error(t(`errors.${mutation}`, { message: errorResponse }));
      } else {
        onSuccess();
      }
    };
  return {
    createErrorHandler,
    createResultHandler,
  };
}
