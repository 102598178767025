
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/client/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { UPDATE_CLIENT } from "@/graphql/client/mutations";

import ClientsCacheInfoHolder from "@/helpers/client/ClientsCacheInfoHolder";
import { updateCachedEntity } from "@/helpers/cache/updateCachedEntity";
import {
  Client,
  ClientPaginatedResponse,
} from "@/helpers/client/typesDefinition";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import { ApolloCache } from "@apollo/client";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    function updateClient(
      cache: ApolloCache<ClientPaginatedResponse>,
      mutatedItem: Client,
    ) {
      return updatePaginatedQueryCache(
        cache,
        mutatedItem,
        ClientsCacheInfoHolder,
        updateCachedEntity,
      );
    }

    return { t, updateClient };
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  data() {
    return {
      enUK,
      validationRules: computed(() => ({
        id: {},
        name: {},
      })),
      UPDATE_CLIENT,
      messages: {
        formButtonLabel: this.t("buttons.edit"),
        error: this.t("errors.edit"),
        success: this.t("success.edit"),
      },
    };
  },
  components: {
    MutateEntity,
  },
});
