import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-454eb9af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "projects" }
const _hoisted_2 = { class: "projects-title" }
const _hoisted_3 = {
  key: 0,
  class: "block"
}
const _hoisted_4 = { class: "tabs-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_TileAllDashboardTasks = _resolveComponent("TileAllDashboardTasks")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AddProject = _resolveComponent("AddProject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBlock, { class: "projects-header" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("welcome", { userName: _ctx.userName })), 1),
        (_ctx.isUserAdmin)
          ? (_openBlock(), _createBlock(_component_BasicButton, {
              key: 0,
              onClick: _ctx.showModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("buttons.add")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isUserAdmin && _ctx.isUserClient)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_TileAllDashboardTasks)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Tabs, {
        color: "primary",
        definitions: _ctx.tabDefinitions
      }, null, 8, ["definitions"])
    ]),
    _createVNode(_component_router_view),
    (_ctx.isUserAdmin && _ctx.isModalVisible)
      ? (_openBlock(), _createBlock(_component_AddProject, {
          key: 1,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
        }))
      : _createCommentVNode("", true)
  ]))
}