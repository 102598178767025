import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40dff2fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = { class: "header header--sticky" }
const _hoisted_3 = { class: "cell header-cell cell--client" }
const _hoisted_4 = { class: "cell header-cell cell--project" }
const _hoisted_5 = { class: "cell header-cell cell--price cell--align-center" }
const _hoisted_6 = { class: "cell header-cell cell--billed cell--align-center" }
const _hoisted_7 = { class: "cell header-cell cell--ordered cell--align-center" }
const _hoisted_8 = { class: "cell header-cell cell--remaining cell--align-center" }
const _hoisted_9 = { class: "cell header-cell cell--late-approval cell--align-center" }
const _hoisted_10 = { class: "cell header-cell cell--late-payment cell--align-center" }
const _hoisted_11 = { class: "cell header-cell cell--late-payment-balance cell--align-center" }
const _hoisted_12 = { class: "section-header" }
const _hoisted_13 = ["href", "onClick"]
const _hoisted_14 = { class: "cell cell--client" }
const _hoisted_15 = { class: "cell cell--project" }
const _hoisted_16 = { class: "cell cell--price cell--align-end" }
const _hoisted_17 = { class: "cell cell--billed cell--align-end" }
const _hoisted_18 = { class: "cell cell--ordered cell--align-end" }
const _hoisted_19 = { class: "cell cell--remaining cell--align-end" }
const _hoisted_20 = { class: "cell cell--late-approval cell--align-center" }
const _hoisted_21 = { class: "cell cell--late-payment cell--align-center" }
const _hoisted_22 = { class: "cell cell--late-payment-balance cell--align-end" }
const _hoisted_23 = {
  key: 1,
  class: "row row__inner row--empty-space"
}
const _hoisted_24 = { class: "cell--empty-space" }
const _hoisted_25 = { class: "section-header" }
const _hoisted_26 = ["href", "onClick"]
const _hoisted_27 = { class: "cell cell--client" }
const _hoisted_28 = { class: "cell cell--project" }
const _hoisted_29 = { class: "cell cell--price cell--align-end" }
const _hoisted_30 = { class: "cell cell--billed cell--align-end" }
const _hoisted_31 = { class: "cell cell--ordered cell--align-end" }
const _hoisted_32 = { class: "cell cell--remaining cell--align-end" }
const _hoisted_33 = { class: "cell cell--late-approval cell--align-center" }
const _hoisted_34 = { class: "cell cell--late-payment cell--align-center" }
const _hoisted_35 = { class: "cell cell--late-payment-balance cell--align-end" }
const _hoisted_36 = {
  key: 3,
  class: "row row__inner row--empty-space"
}
const _hoisted_37 = { class: "cell--empty-space" }
const _hoisted_38 = { class: "section-header" }
const _hoisted_39 = ["href", "onClick"]
const _hoisted_40 = { class: "cell cell--client" }
const _hoisted_41 = { class: "cell cell--project" }
const _hoisted_42 = { class: "cell cell--price cell--align-end" }
const _hoisted_43 = { class: "cell cell--billed cell--align-end" }
const _hoisted_44 = { class: "cell cell--ordered cell--align-end" }
const _hoisted_45 = { class: "cell cell--remaining cell--align-end" }
const _hoisted_46 = { class: "cell cell--late-approval cell--align-center" }
const _hoisted_47 = { class: "cell cell--late-payment cell--align-center" }
const _hoisted_48 = { class: "cell cell--late-payment-balance cell--align-end" }
const _hoisted_49 = {
  key: 5,
  class: "row row__inner row--empty-space"
}
const _hoisted_50 = { class: "cell--empty-space" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_TilePaginatedDashboard = _resolveComponent("TilePaginatedDashboard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TilePaginatedDashboard, {
      title: _ctx.t('myProjects'),
      loading: _ctx.loading ? _ctx.t('project.loading') : undefined,
      error: _ctx.error ? _ctx.t('project.error') : undefined,
      "empty-space": !_ctx.hasProjects ? _ctx.t('project.emptySpace.default') : undefined,
      loadMore: _ctx.loadMore,
      onResult: _ctx.onResult,
      hasMore: _ctx.paginatedProjects?.hasMore
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("labels.client")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("labels.name")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("labels.budgeted")), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("labels.used")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t("labels.ordered")), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("labels.remaining")), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("labels.lateApprovals")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("labels.latePayments")), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t("labels.latePaymentsAmount")), 1)
          ]),
          _createElementVNode("li", _hoisted_12, _toDisplayString(_ctx.t("labels.budgetWarning")), 1),
          (
            _ctx.projectsWithBudgetWarning && _ctx.projectsWithBudgetWarning.length > 0
          )
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.projectsWithBudgetWarning, (project) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: project.id,
                  class: "row row--hoverable"
                }, [
                  _createVNode(_component_RouterLink, {
                    custom: "",
                    to: {
                name: _ctx.ProjectRoutes.PROJECT,
                params: { id: project.id },
              }
                  }, {
                    default: _withCtx(({ navigate, href }) => [
                      _createElementVNode("a", {
                        class: "row__inner row__inner--link",
                        href: href,
                        onClick: navigate
                      }, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(project.client.name), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(project.name), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(project.budget ? _ctx.formatPrice(project.budget.amount) : "-"), 1),
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.formatPrice(project.totalBilledNet)), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.formatPrice(project.totalNotBilledOrdered)), 1),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getAndFormatProjectRemaining(project)), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(project.lateApprovalOrdersCount), 1),
                        _createElementVNode("div", _hoisted_21, _toDisplayString(project.latePaymentInvoicesCount), 1),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.formatPrice(project.latePaymentBalance)), 1)
                      ], 8, _hoisted_13)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("li", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.t("project.emptySpace.warning")), 1)
              ])),
          _createElementVNode("li", _hoisted_25, _toDisplayString(_ctx.t("labels.lateWarnings")), 1),
          (_ctx.projectsWithLateWarnings && _ctx.projectsWithLateWarnings.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.projectsWithLateWarnings, (project) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: project.id,
                  class: "row row--hoverable"
                }, [
                  _createVNode(_component_RouterLink, {
                    custom: "",
                    to: {
                name: _ctx.ProjectRoutes.PROJECT,
                params: { id: project.id },
              }
                  }, {
                    default: _withCtx(({ navigate, href }) => [
                      _createElementVNode("a", {
                        class: "row__inner row__inner--link",
                        href: href,
                        onClick: navigate
                      }, [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(project.client.name), 1),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(project.name), 1),
                        _createElementVNode("div", _hoisted_29, _toDisplayString(project.budget ? _ctx.formatPrice(project.budget.amount) : "-"), 1),
                        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.formatPrice(project.totalBilledNet)), 1),
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.formatPrice(project.totalNotBilledOrdered)), 1),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.getAndFormatProjectRemaining(project)), 1),
                        _createElementVNode("div", _hoisted_33, _toDisplayString(project.lateApprovalOrdersCount), 1),
                        _createElementVNode("div", _hoisted_34, _toDisplayString(project.latePaymentInvoicesCount), 1),
                        _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.formatPrice(project.latePaymentBalance)), 1)
                      ], 8, _hoisted_26)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("li", _hoisted_36, [
                _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.t("project.emptySpace.warning")), 1)
              ])),
          _createElementVNode("li", _hoisted_38, _toDisplayString(_ctx.t("labels.other")), 1),
          (_ctx.otherProjects && _ctx.otherProjects.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.otherProjects, (project) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: project.id,
                  class: "row row--hoverable"
                }, [
                  _createVNode(_component_RouterLink, {
                    custom: "",
                    to: {
                name: _ctx.ProjectRoutes.PROJECT,
                params: { id: project.id },
              }
                  }, {
                    default: _withCtx(({ navigate, href }) => [
                      _createElementVNode("a", {
                        class: "row__inner row__inner--link",
                        href: href,
                        onClick: navigate
                      }, [
                        _createElementVNode("div", _hoisted_40, _toDisplayString(project.client.name), 1),
                        _createElementVNode("div", _hoisted_41, _toDisplayString(project.name), 1),
                        _createElementVNode("div", _hoisted_42, _toDisplayString(project.budget ? _ctx.formatPrice(project.budget.amount) : "-"), 1),
                        _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.formatPrice(project.totalBilledNet)), 1),
                        _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.formatPrice(project.totalNotBilledOrdered)), 1),
                        _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.getAndFormatProjectRemaining(project)), 1),
                        _createElementVNode("div", _hoisted_46, _toDisplayString(project.lateApprovalOrdersCount), 1),
                        _createElementVNode("div", _hoisted_47, _toDisplayString(project.latePaymentInvoicesCount), 1),
                        _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.formatPrice(project.latePaymentBalance)), 1)
                      ], 8, _hoisted_39)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("li", _hoisted_49, [
                _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.t("project.emptySpace.default")), 1)
              ]))
        ])
      ]),
      _: 1
    }, 8, ["title", "loading", "error", "empty-space", "loadMore", "onResult", "hasMore"])
  ]))
}