
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { logErrorMessages } from "@vue/apollo-util";

import { LOGOUT } from "@/graphql/user/mutations";
import { GET_USER } from "@/graphql/user/queries";
import ButtonIcon from "@/components/ButtonIcon.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });
    const toast = useToast();
    const router = useRouter();

    const {
      mutate: logout,
      onDone,
      onError,
    } = useMutation(LOGOUT, () => ({
      update: (cache) => {
        cache.writeQuery({
          query: GET_USER,
          data: { user: null },
        });
      },
    }));
    onDone((result) => {
      if (result.data?.logout) {
        router.push({ name: "Login" });
      } else {
        console.log(
          "Log out returned false while it should always return true",
        );
        toast.error(t("errors.logout"));
      }
    });
    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.logout"));
    });

    return {
      logout,
      t,
    };
  },
  components: {
    ButtonIcon,
  },
});
