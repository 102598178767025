
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { apolloClient } from "@/apollo.provider";
import { useToast } from "vue-toastification";

import enUK from "@/locales/delta/en-UK.json";

import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";
import BasicButton from "@/components/BasicButton.vue";

import { GET_NON_ORDERED_DELTAS } from "@/graphql/delta/queries";
import { CREATE_NEW_DELTAS_FOR_PROJECTS } from "@/graphql/delta/mutations";

import { Delta, NonOrderedDelta } from "@/helpers/delta/typesDefinition";
import dayjs from "dayjs";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import { removeNonIdenticalPaginatedEntity } from "@/helpers/cache/removeEntity";
import NonOrderedDeltasCacheInfoHolder from "@/helpers/delta/NonOrderedDeltasCacheInfoHolder";
import { insertNewEntityPaginated } from "@/helpers/cache/insertNewEntity";
import OpenedDeltasCacheInfoHolder from "@/helpers/delta/OpenedDeltasCacheInfoHolder";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const selectAll = ref(false);
    const checkBoxes = ref();
    const deltas = ref();

    const loading = ref(false);

    async function createDeltas(selectedProjectIds: string[]): Promise<void> {
      try {
        loading.value = true;
        const result = await apolloClient.mutate({
          mutation: CREATE_NEW_DELTAS_FOR_PROJECTS,
          variables: {
            ids: selectedProjectIds,
          },
          update: (cache, { data }) => {
            const createNewDeltasResponse = data?.createNewDeltasForProjects;
            if (createNewDeltasResponse?.status === "ok") {
              for (const delta of createNewDeltasResponse.data) {
                updatePaginatedQueryCache(
                  cache,
                  delta,
                  NonOrderedDeltasCacheInfoHolder,
                  removeNonIdenticalPaginatedEntity<NonOrderedDelta>(
                    (d1, d2) => d1.project.id === d2.project.id,
                  ),
                );
                updatePaginatedQueryCache(
                  cache,
                  delta,
                  OpenedDeltasCacheInfoHolder,
                  insertNewEntityPaginated,
                );
              }
            }
          },
        });
        loading.value = false;
        if (result.data?.createNewDeltasForProjects?.status === "ok") {
          toast.success(t("success.createInvoice"));
        } else {
          const errorResponse = result.data?.createNewDeltasForProjects?.error;
          console.log(errorResponse);
          toast.error(
            t("errors.createInvoice", {
              message: errorResponse,
            }),
          );
        }
      } catch (error) {
        console.log(error);
        toast.error(
          t("errors.createInvoice", { message: t("errors.internal") }),
        );
      }
    }

    async function confirmSelectedDeltas(): Promise<void> {
      if (!checkBoxes.value) {
        if (!selectAll.value) return;
        const projectIds = deltas.value.map((delta: Delta) => delta.project.id);
        return createDeltas(projectIds);
      }
      const projectIds = deltas.value.map((delta: Delta) => delta.project.id);
      const selectedProjectIds = projectIds.filter(
        (_id: string, index: number) => checkBoxes.value[index],
      );
      return createDeltas(selectedProjectIds);
    }

    return {
      t,
      checkBoxes,
      deltas,
      confirmSelectedDeltas,
      loading,
      selectAll,
    };
  },
  data() {
    return {
      enUK,
      GET_NON_ORDERED_DELTAS,
    };
  },
  emits: [],
  provide: {
    columnsOrder: {
      project: null,
      timesheetTotal: null,
      startDate: null,
    },
  },
  methods: {
    formatDate(date: string, _name: string, delta: Delta): string {
      const baseFormat = dayjs(date).format("MMMM YY");
      if (delta.monthsSpan > 1)
        return `${baseFormat} (+${delta.monthsSpan - 1})`;
      return baseFormat;
    },
  },
  components: {
    PaginatedEntitiesList,
    BasicButton,
  },
});
