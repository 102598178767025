import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c9d2854"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invoicing" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "deltas" }
const _hoisted_4 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBlock, { class: "header" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("titles.invoicing")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tabs, {
        color: "primary",
        definitions: [
          {
            title: _ctx.t('labels.openedInvoices'),
            route: { name: 'OpenedInvoices' },
          },
          { title: _ctx.t('labels.deltas'), route: { name: 'Invoicing' } },
          {
            title: _ctx.t('labels.nonOrdered'),
            route: { name: 'InvoicingNonOrdered' },
          },
          {
            title: _ctx.t('labels.independent'),
            route: { name: 'IndependentInvoices' },
          },
          {
            title: _ctx.t('labels.openedCreditNotes'),
            route: { name: 'OpenedCreditNotes' },
          },
        ]
      }, null, 8, ["definitions"]),
      (_ctx.loadingSyncDeltas)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t("loading")), 1))
        : _createCommentVNode("", true),
      (!_ctx.loadingSyncDeltas)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}