import gql from "graphql-tag";
import { milestoneFragment } from "./fragments";
import { templateActivityFragment } from "@/graphql/orcha/activities/fragments";

export const CREATE_TEMPLATE_MILESTONE = gql`
  mutation createTemplateMilestone($phaseId: ID!, $name: String!, $rank: ID!) {
    createTemplateMilestone(phaseId: $phaseId, name: $name, rank: $rank) {
      status
      error
      data {
        ...MilestoneFragment
      }
    }
  }
  ${milestoneFragment}
`;

export const UPDATE_TEMPLATE_MILESTONE = gql`
  mutation updateTemplateMilestone($id: ID!, $name: String, $rank: ID) {
    updateTemplateMilestone(id: $id, name: $name, rank: $rank) {
      status
      error
      data {
        ...MilestoneFragment
        activities {
          id
          ...TemplateActivityFragment
        }
      }
    }
  }
  ${milestoneFragment}
  ${templateActivityFragment}
`;

export const DELETE_TEMPLATE_MILESTONE = gql`
  mutation DeleteTemplateMilestone($id: ID!) {
    deleteTemplateMilestone(id: $id) {
      status
      error
      data {
        ...MilestoneFragment
      }
    }
  }
  ${milestoneFragment}
`;

export const CREATE_MILESTONE = gql`
  mutation CreateMilestone($phaseId: ID!, $name: String!, $rank: ID!) {
    createMilestone(phaseId: $phaseId, name: $name, rank: $rank) {
      status
      error
      data {
        ...MilestoneFragment
      }
    }
  }
  ${milestoneFragment}
`;

export const UPDATE_MILESTONE = gql`
  mutation updateMilestone(
    $id: ID!
    $name: String
    $rank: ID
    $deadline: Date
    $important: Boolean
    $assigneeId: ID
    $phaseId: ID
  ) {
    updateMilestone(
      id: $id
      name: $name
      rank: $rank
      deadline: $deadline
      important: $important
      assigneeId: $assigneeId
      phaseId: $phaseId
    ) {
      status
      error
      data {
        ...MilestoneFragment
      }
    }
  }
  ${milestoneFragment}
`;

export const ARCHIVE_MILESTONE = gql`
  mutation ArchiveMilestone($id: ID!) {
    archiveMilestone(id: $id) {
      status
      error
      data {
        ...MilestoneFragment
      }
    }
  }
  ${milestoneFragment}
`;
