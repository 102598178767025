import gql from "graphql-tag";

export const clientFragment = gql`
  fragment ClientFragment on Client {
    id
    name
  }
`;

export const companyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    vatNumber
  }
`;
