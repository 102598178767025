
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/client/en-UK.json";

import { GET_CLIENT } from "@/graphql/client/queries";

import EntitiesList from "@/components/list/EntitiesList.vue";
import { Budget, Project } from "@/helpers/project/typesDefinition";
import { formatPrice } from "@/helpers/utils";
import { ProjectRoutes } from "@/router/projectRoutes";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const route = useRoute();
    const toast = useToast();
    const { result, loading, onError } = useQuery(GET_CLIENT, () => ({
      id: route.params.id,
    }));

    const client = useResult(result);

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.get"));
    });

    return {
      client,
      loading,
      t,
    };
  },
  provide: {
    columnsOrder: {
      name: null,
      active: null,
      budget: null,
    },
  },
  methods: {
    viewEntity(project: Project): void {
      this.$router.push({
        name: ProjectRoutes.PROJECT,
        params: { id: project.id },
      });
    },
    formatBudget(budget?: Budget): string {
      return budget?.amount ? formatPrice(budget.amount) : "-";
    },
  },
  components: {
    EntitiesList,
  },
});
