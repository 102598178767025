import gql from "graphql-tag";
import { userFragment } from "./fragments";

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const VERIFY = gql`
  mutation verify($email: String!, $token: String!) {
    verify(email: $email, token: $token) {
      status
      error
      data {
        id
        email
        firstName
        accessRights {
          ... on GlobalAccessRight {
            role
          }
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation logIn($email: String!, $redirect: String) {
    login(email: $email, redirect: $redirect) {
      status
      error
    }
  }
`;

export const ASSIGN_USER = gql`
  mutation assignUser($clientId: ID!, $userEmail: String!, $projects: [ID!]!) {
    assignUser(
      clientId: $clientId
      userEmail: $userEmail
      projects: $projects
    ) {
      status
      error
      data {
        user {
          ...UserFragment
        }
      }
    }
  }
  ${userFragment}
`;

export const UNASSIGN_USER = gql`
  mutation unassignUser($clientId: ID!, $userId: ID!) {
    unassignUser(clientId: $clientId, userId: $userId) {
      status
      error
      data {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $projects: [ID!]
    $clientId: ID
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      projects: $projects
      clientId: $clientId
    ) {
      status
      error
      data {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;
