import { reactive } from "vue";

type ToggleableTable = {
  toggled: Record<string, boolean>;
  toggle: (key: string) => void;
  isToggled: (key: string) => boolean;
};
const toggleableTables: Record<string, ToggleableTable> = {};

export default function useToggleableTable(id: string, toggles?: string[]) {
  if (toggles) {
    const togglesMap = toggles.reduce<Record<string, boolean>>(
      (acc, toggleKey) => {
        return {
          ...acc,
          [toggleKey]: false,
        };
      },
      {},
    );
    const toggled = reactive(togglesMap);

    const toggle = (key: string) => {
      toggled[key] = !toggled[key];
    };

    const isToggled = (key: string) => {
      return toggled[key];
    };

    toggleableTables[id] = {
      toggled,
      toggle,
      isToggled,
    };
  }

  return toggleableTables[id];
}
