
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useQuery, useResult } from "@vue/apollo-composable";

import HeaderBlock from "@/components/HeaderBlock.vue";
import AddProject from "@/components/CRUXEntity/AddProject.vue";
import BasicButton from "@/components/BasicButton.vue";

import { GET_USER } from "@/graphql/user/queries";

import enUK from "@/locales/project/en-UK.json";
import { isAdmin, isClient } from "@/helpers/router/queries";
import Tabs from "@/components/Tabs.vue";
import { ProjectRoutes } from "@/router/projectRoutes";
import TileAllDashboardTasks from "@/components/TileAllDashboardTasks.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const isUserAdmin = ref(false);
    const isUserClient = ref(false);
    const { result, onResult } = useQuery(GET_USER);

    const user = useResult(result);
    isUserAdmin.value = isAdmin(user.value?.accessRights);
    isUserClient.value = isClient(user.value?.accessRights);

    onResult((r) => {
      isUserAdmin.value = isAdmin(r.data.user?.accessRights);
      isUserClient.value = isClient(r.data.user?.accessRights);
    });

    const userName = computed(() => {
      return user.value?.firstName || "";
    });

    const tabDefinitions = [
      {
        title: t("labels.tab.active"),
        route: {
          name: ProjectRoutes.PROJECTS_ACTIVE,
        },
      },
      {
        title: t("labels.tab.inactive"),
        route: {
          name: ProjectRoutes.PROJECTS_INACTIVE,
        },
      },
    ];

    return { t, isUserAdmin, isUserClient, tabDefinitions, userName };
  },
  data() {
    return {
      enUK,
      isModalVisible: false,
    };
  },
  methods: {
    showModal(): void {
      this.isModalVisible = true;
    },
    closeModal(): void {
      this.isModalVisible = false;
    },
  },
  components: {
    TileAllDashboardTasks,
    HeaderBlock,
    AddProject,
    BasicButton,
    Tabs,
  },
});
