import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e1db406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mutate-entity-modal-body" }
const _hoisted_2 = {
  key: 0,
  id: `mutate-form-edit-invoice`
}
const _hoisted_3 = {
  key: 1,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { onClose: _ctx.closeModal }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("titles.edit")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.projects)
          ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
              _createVNode(_component_FormItem, {
                input: _ctx.projectId,
                "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.projectId) = $event)),
                label: _ctx.t(`labels.projectId`),
                options: _ctx.projects,
                name: "projectId"
              }, null, 8, ["input", "label", "options"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.loading || _ctx.loadingProjects)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("loading")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        class: "mutate-button",
        type: "submit",
        form: `mutate-form-edit-invoice`,
        disabled: _ctx.loading,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editInvoice()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("buttons.edit")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["onClose"]))
}