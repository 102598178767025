import gql from "graphql-tag";

export const budgetFragment = gql`
  fragment BudgetFragment on Budget {
    id
    amount
    approvedAt
  }
`;

export const projectFragment = gql`
  fragment ProjectFragment on Project {
    id
    name
    description
    defaultPaymentOption
    budget {
      ...BudgetFragment
    }
    needsBudgetApproval
    active
  }
  ${budgetFragment}
`;
