import { ref, watch, toRef, type MaybeRefOrGetter } from "vue";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import checkHasAccess from "@/helpers/permissions/checkHasAccess";

export default function useHasAccess(id: MaybeRefOrGetter<string>) {
  const projectId = toRef(id);

  const { t } = useI18n({
    useScope: "global",
    inheritLocale: true,
  });
  const toast = useToast();

  const accessIsChecked = ref();
  const hasAdminAccess = ref(false);
  const hasClientAccess = ref(false);
  const hasPMAccess = ref(false);
  const hasAccessToFinancialData = ref(false);

  const checkAccess = async (id: string) => {
    const adminAccess = await checkHasAccess(
      undefined,
      id,
      "admin",
      toast,
      t("errors.getUserDetails"),
    );

    const pmAccess = await checkHasAccess(
      undefined,
      id,
      "project_manager",
      toast,
      t("errors.getUserDetails"),
    );

    const clientAccess = await checkHasAccess(
      undefined,
      id,
      "client",
      toast,
      t("errors.getUserDetails"),
    );

    const anyAccess = adminAccess || pmAccess || clientAccess;

    return {
      adminAccess,
      pmAccess,
      clientAccess,
      anyAccess,
    };
  };

  watch(
    projectId,
    async (id) => {
      if (id) {
        const { adminAccess, pmAccess, clientAccess, anyAccess } =
          await checkAccess(id);
        hasAdminAccess.value = adminAccess;
        hasPMAccess.value = pmAccess;
        hasClientAccess.value = clientAccess;
        hasAccessToFinancialData.value = anyAccess;
        accessIsChecked.value = true;
      } else {
        hasAdminAccess.value = false;
        hasPMAccess.value = false;
        hasClientAccess.value = false;
        hasAccessToFinancialData.value = false;
        accessIsChecked.value = false;
      }
    },
    { immediate: true },
  );

  return {
    hasAdminAccess,
    hasPMAccess,
    hasClientAccess,
    hasAccessToFinancialData,
    accessIsChecked,
  };
}
