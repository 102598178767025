import gql from "graphql-tag";
import { themeFragment } from "@/graphql/timesheets/fragments";

export const GET_THEMES = gql`
  query getThemes {
    themes {
      hasMore
      items {
        ...ThemeFragment
      }
    }
  }
  ${themeFragment}
`;

export const GET_TIMERS = gql`
  query getTimers {
    timers {
      id
      date
      periods {
        id
        startTime
        stopTime
        duration
      }
      theme {
        ...ThemeFragment
      }
      role {
        id
        name
      }
    }
  }
  ${themeFragment}
`;
