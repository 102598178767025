import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43685b90"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "readonly"]
const _hoisted_2 = ["placeholder", "id", "value", "data-maska"]
const _hoisted_3 = ["placeholder", "type", "id", "value", "checked", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _directive_maska = _resolveDirective("maska")!

  return (_ctx.inputType === 'textArea')
    ? (_openBlock(), _createElementBlock("textarea", {
        key: 0,
        class: _normalizeClass(["textarea-input", { 'read-only': _ctx.readOnly }]),
        id: _ctx.inputId,
        value: _ctx.input,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:input', $event.target?.value))),
        readonly: _ctx.readOnly
      }, null, 42, _hoisted_1))
    : (['date', 'inline-date'].includes(_ctx.inputType))
      ? (_openBlock(), _createBlock(_component_VueDatePicker, {
          key: 1,
          class: _normalizeClass(["date-input", { 'read-only': _ctx.readOnly }]),
          id: _ctx.inputId,
          modelValue: _ctx.value,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
            _cache[2] || (_cache[2] = (v) => _ctx.$emit('update:input', v))
          ],
          readonly: _ctx.readOnly,
          "auto-apply": "",
          format: _ctx.dateFormat,
          markers: _ctx.dateMarkers || [],
          inline: _ctx.inputType === 'inline-date',
          "enable-time-picker": false
        }, null, 8, ["class", "id", "modelValue", "readonly", "format", "markers", "inline"]))
      : (!!_ctx.mask && !_ctx.readOnly && _ctx.getType() == 'text')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            placeholder: _ctx.placeholder,
            class: "text-input",
            type: "text",
            id: _ctx.inputId,
            value: _ctx.input,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:input', $event.target?.value))),
            "data-maska": _ctx.mask
          }, null, 40, _hoisted_2)), [
            [_directive_maska]
          ])
        : (_openBlock(), _createElementBlock("input", {
            key: 3,
            placeholder: _ctx.placeholder,
            class: _normalizeClass({ 'read-only': _ctx.readOnly, 'text-input': _ctx.inputType !== 'checkbox' }),
            type: _ctx.getType(),
            step: "0.25",
            id: _ctx.inputId,
            value: _ctx.input,
            checked: !!_ctx.input,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (
      _ctx.$emit(
        'update:input',
        _ctx.inputType === 'checkbox'
          ? $event.target?.checked
          : $event.target?.value,
      )
    )),
            readonly: _ctx.readOnly
          }, null, 42, _hoisted_3))
}