import { ClientResponse, Company } from "./typesDefinition";
import { CacheInfoHolder } from "@/helpers/typesDefinition";
import { GET_CLIENT } from "@/graphql/client/queries";
import { companyComparator } from "./utils";

const ClientCompaniesCacheInfoHolder: CacheInfoHolder<Company, ClientResponse> =
  {
    query: GET_CLIENT,

    cachedEntitiesToUpdate: (cachedResponse: ClientResponse): Company[] => {
      return cachedResponse.client.companies;
    },

    cachedResponse: (
      cachedResponse: ClientResponse,
      updatedCompanies: Company[],
    ): ClientResponse => ({
      client: { ...cachedResponse.client, companies: updatedCompanies },
    }),

    comparator: companyComparator,
  };

export default ClientCompaniesCacheInfoHolder;
