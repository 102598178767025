import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5d9e4ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "label__name" }
const _hoisted_4 = { class: "label__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GraphDonut = _resolveComponent("GraphDonut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.labelName), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.labelValue), 1)
    ]),
    (_ctx.segments)
      ? (_openBlock(), _createBlock(_component_GraphDonut, _mergeProps({
          key: 0,
          segments: _ctx.segments,
          class: "donut"
        }, _ctx.$attrs), null, 16, ["segments"]))
      : _createCommentVNode("", true)
  ]))
}