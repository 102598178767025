import gql from "graphql-tag";

export const themeFragment = gql`
  fragment ThemeFragment on Theme {
    id
    name
    active
    project {
      id
      name
      color
    }
  }
`;

export const timesheetFragment = gql`
  fragment TimesheetFragment on TimesheetEntry {
    id
    duration
    theme {
      ...ThemeFragment
    }
    role: projectRole {
      id
      name
    }
    notes
  }
  ${themeFragment}
`;
