
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import BasicInput from "@/components/form/BasicInput.vue";

import enUK from "@/locales/project/en-UK.json";

import { helpers, minValue, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  formatPrice,
  quarterStepsValidator,
  computeOrderLineSubtotal,
} from "@/helpers/utils";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const v$ = useVuelidate({
      $autoDirty: true,
    });

    const unitOptions = {
      60: "hours",
      480: "days",
    };

    return {
      unitOptions,
      v$,
      t,
      formatPrice,
    };
  },
  validations() {
    return {
      quantity: {
        required,
        minValue: minValue(0),
        quarterSteps: helpers.withMessage(
          this.t("validators.quarterSteps"),
          quarterStepsValidator,
        ),
      },
      unit: {
        required,
      },
    };
  },
  emits: [
    "update:subtotal",
    "update:quantity",
    "update:unit",
    "update:validator",
  ],
  props: {
    projectRoleName: {
      type: String,
      required: true,
    },
    hourlyRate: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    unit: {
      type: Number,
      required: true,
    },
    subtotal: {
      type: Number,
      required: true,
    },
    validator: {
      type: Object,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedSubtotal(): number {
      const subtotal = computeOrderLineSubtotal(
        this.quantity,
        this.hourlyRate,
        this.unit,
      );
      this.$emit("update:subtotal", subtotal);
      return subtotal;
    },
    modelUnit: {
      get() {
        return this.unit;
      },
      set(value: string) {
        this.$emit("update:unit", parseInt(value, 10));
      },
    },
  },
  watch: {
    v$: function (value) {
      this.$emit("update:validator", value);
    },
  },
  components: {
    BasicInput,
  },
});
