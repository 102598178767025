
import { defineComponent, PropType } from "vue";
import { Theme } from "@/helpers/project/typesDefinition";

export default defineComponent({
  props: {
    duration: {
      type: Number,
      required: true,
    },
    dayDuration: {
      type: Number,
      default: 60 * 8,
    },
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
  },
  computed: {
    cssVars() {
      const heightPercentage = Math.round(
        (this.duration / this.dayDuration) * 100,
      );
      const color = this.theme.project?.color || "#5A61F1";
      return {
        "--bg-color": color,
        "--height": `${heightPercentage}%`,
      };
    },
  },
});
