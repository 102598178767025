import gql from "graphql-tag";

export const commentFragment = gql`
  fragment CommentFragment on Comment {
    id
    message
    author {
      id
    }
    createdAt
    updatedAt
  }
`;
