import { useToast } from "vue-toastification";
import { useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { DocumentNode } from "graphql";
import { computed, Ref, toRef } from "vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (
  query: DocumentNode | Ref<DocumentNode>,
  errorMessage: string,
  inputVariables: Record<string, unknown> | Ref<Record<string, unknown>> = {},
) {
  const toast = useToast();

  const variables = toRef(inputVariables);

  const fetchVariables = computed(() => ({
    ...variables.value,
    offset: 0,
  }));

  const { result, loading, onError, onResult, fetchMore } = useQuery(
    query,
    fetchVariables,
    { notifyOnNetworkStatusChange: true },
  );

  const paginatedResult = useResult(result);

  function loadMore() {
    fetchMore({
      variables: {
        ...variables.value,
        offset: paginatedResult.value?.items?.length,
      },
    });
  }

  onError((error) => {
    logErrorMessages(error);
    toast.error(errorMessage);
  });

  return {
    paginatedResult,
    loading,
    loadMore,
    onResult,
  };
}
