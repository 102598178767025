
import { computed, defineComponent } from "vue";
import { ApolloCache } from "@apollo/client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import enUK from "@/locales/client/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { CREATE_COMPANY } from "@/graphql/client/mutations";

import ClientCompaniesCacheInfoHolder from "@/helpers/client/ClientCompaniesCacheInfoHolder";

import { ClientResponse, Company } from "@/helpers/client/typesDefinition";
import { updateQueryCache } from "@/helpers/cache/cache";
import { insertNewEntity } from "@/helpers/cache/insertNewEntity";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const route = useRoute();

    function insertNewCompanyInClientCache(
      cache: ApolloCache<ClientResponse>,
      mutatedItem: Company,
    ) {
      return updateQueryCache(
        cache,
        mutatedItem,
        ClientCompaniesCacheInfoHolder,
        insertNewEntity,
        route.params.id as string,
      );
    }

    return { t, insertNewCompanyInClientCache };
  },
  data() {
    return {
      enUK,
      CREATE_COMPANY,
      entityProps: { name: "", vatNumber: "", vatExempted: false },
      unmutableEntityProps: { clientId: this.$route.params.id },
      validationRules: computed(() => ({
        name: {},
        vatNumber: {},
        vatExempted: {},
      })),
      messages: {
        formButtonLabel: this.t("buttons.company.add"),
        error: this.t("errors.company.add"),
        success: this.t("success.company.add"),
        title: this.t("titles.company.add"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
