
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useQuery, useResult } from "@vue/apollo-composable";
import { useToast } from "vue-toastification";

import enUK from "@/locales/project/en-UK.json";

import EntitiesList from "@/components/list/EntitiesList.vue";

import { GET_ORDERS_DELTAS_AND_INVOICES } from "@/graphql/order/queries";

import { Order } from "@/helpers/order/typesDefinition";
import { Delta } from "@/helpers/delta/typesDefinition";
import { isDelta } from "@/helpers/delta/utils";
import { isInvoice } from "@/helpers/invoice/utils";
import { isOrder } from "@/helpers/order/utils";
import { Entity } from "@/helpers/typesDefinition";
import { Invoice } from "@/helpers/invoice/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    projectId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  emits: ["openModal"],
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const { result, loading, onError } = useQuery(
      GET_ORDERS_DELTAS_AND_INVOICES,
      () => ({
        projectId: props.projectId,
        date: props.date,
      }),
    );

    const ordersDeltasAndInvoices = useResult(result, [], (data) => {
      const deltasAndOrdersResponse = data.ordersDeltasAndInvoices as (
        | Order
        | Delta
        | Invoice
      )[];
      return deltasAndOrdersResponse.map((orderOrDelta) => {
        if (isDelta(orderOrDelta)) {
          const invoice = orderOrDelta.invoice;
          return {
            id: orderOrDelta.id,
            __typename: orderOrDelta.__typename,
            type: "FINAL INVOICE",
            title: undefined,
            paymentOption: undefined,
            status: orderOrDelta.deltaStatus,
            company: orderOrDelta.deltaCompany?.name,
            price: invoice?.netTotal || orderOrDelta.deltaTotal,
            invoiceNumber: invoice?.invoiceNumber,
            toBePaid:
              invoice?.invoiceStatus === "canceled" ? "-" : invoice?.balance,
            paid:
              invoice?.invoiceStatus === "canceled"
                ? "-"
                : invoice?.total - invoice?.balance,
            taxedPrice: invoice?.total,
            invoiceId: invoice?.id,
          };
        }
        const [invoice, status] = isInvoice(orderOrDelta)
          ? [orderOrDelta, orderOrDelta.invoiceStatus]
          : [orderOrDelta.invoice, orderOrDelta.status];
        const order = isOrder(orderOrDelta) ? orderOrDelta : undefined;
        const type = invoice
          ? invoice.type === "order"
            ? "ORDER INVOICE"
            : "EXTERNAL INVOICE"
          : "ORDER";
        return {
          id: orderOrDelta.id,
          __typename: orderOrDelta.__typename,
          type,
          status,
          title: order?.title,
          paymentOption: order?.paymentOption,
          price: order?.price,
          company: orderOrDelta.company.name,
          invoiceNumber: invoice?.invoiceNumber,
          toBePaid: status === "rejected" ? "-" : invoice?.balance,
          paid: status === "rejected" ? "-" : invoice?.total - invoice?.balance,
          taxedPrice: invoice?.total,
          invoiceId: invoice?.id,
        };
      });
    });

    onError((error) => {
      console.log(error);
      toast.error(
        t("errors.getOrdersDeltasAndInvoices", {
          message: t("errors.internal"),
        }),
      );
    });

    return { t, loading, ordersDeltasAndInvoices };
  },
  methods: {
    viewEntity(entity: Entity): void {
      if (entity?.__typename === "Order") this.$emit("openModal", entity.id);
    },
  },
  provide: {
    columnsOrder: {
      type: null,
      title: null,
      paymentOption: null,
      status: null,
      price: null,
      taxedPrice: null,
      paid: null,
      toBePaid: null,
      invoiceNumber: null,
      company: null,
    },
  },
  components: { EntitiesList },
});
