import { ApolloCache } from "@apollo/client";
import {
  PaginatedCacheInfoHolder,
  EntityPaginatedPropCacheInfoHolder,
  Entity,
  CacheInfoHolder,
} from "../typesDefinition";
import {
  UpdateCachedEntities,
  UpdateCachedPaginatedEntities,
} from "./typesDefinition";

export const updatePaginatedQueryCache = <T extends Entity, U>(
  cache: ApolloCache<U>,
  newItem: T,
  updater: PaginatedCacheInfoHolder<T, U>,
  updateCachedEntities: UpdateCachedPaginatedEntities<T>,
): void => {
  const cachedEntity: U | null = cache.readQuery({
    query: updater.query,
    ...(updater.variables ? { variables: updater.variables } : {}),
  });

  const paginatedResult = updater.cachedPaginatedResult(cachedEntity);

  const updatedItems = updateCachedEntities(
    newItem,
    paginatedResult,
    updater.comparator,
  );

  cache.writeQuery({
    query: updater.query,
    ...(updater.variables ? { variables: updater.variables } : {}),
    data: updater.cachedResponse({
      ...paginatedResult,
      items: updatedItems,
    }),
  });
};

export const updateEntityPaginatedPropQueryCache = <T, U>(
  cache: ApolloCache<U>,
  newItem: T,
  updater: EntityPaginatedPropCacheInfoHolder<T, U, Entity>,
  updateCachedEntities: UpdateCachedPaginatedEntities<T>,
  id: string,
): void => {
  const cachedEntity: U | null = cache.readQuery({
    query: updater.query,
    variables: { id },
  });

  const paginatedResult = updater.cachedPaginatedResult(cachedEntity);
  const entity = updater.cachedEntity(cachedEntity, id);

  const updatedItems = updateCachedEntities(
    newItem,
    paginatedResult,
    updater.comparator,
  );

  cache.writeQuery({
    query: updater.query,
    variables: { id },
    data: updater.cachedResponse(
      {
        ...paginatedResult,
        items: updatedItems,
      },
      entity,
    ),
  });
};

export const updateQueryCache = <T extends Entity, U>(
  cache: ApolloCache<U>,
  newItem: T,
  updater: CacheInfoHolder<T, U>,
  updateCachedEntities: UpdateCachedEntities<T>,
  id: string,
): void => {
  const cachedEntityResponse: U | null = cache.readQuery({
    query: updater.query,
    variables: { id },
  });

  if (!cachedEntityResponse) return;
  const cachedEntitiesToUpdate =
    updater.cachedEntitiesToUpdate(cachedEntityResponse);

  const updatedCachedResult = updateCachedEntities(
    newItem,
    cachedEntitiesToUpdate,
    updater.comparator,
  );

  cache.writeQuery({
    query: updater.query,
    variables: { id },
    data: updater.cachedResponse(cachedEntityResponse, updatedCachedResult),
  });
};
