import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-348c4e0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass({
        button: true,
        'button--left': true,
        'button--inactive': !_ctx.isLeft,
      }),
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeIsRecurringOrderValue && _ctx.changeIsRecurringOrderValue(...args)))
    }, _toDisplayString(_ctx.leftLabel), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        button: true,
        'button--right': true,
        'button--inactive': _ctx.isLeft,
      }),
      type: "button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeIsRecurringOrderValue && _ctx.changeIsRecurringOrderValue(...args)))
    }, _toDisplayString(_ctx.rightLabel), 3)
  ]))
}