
import {
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRefs,
} from "vue";
import { useI18n } from "vue-i18n";
import { DocumentNode } from "graphql";

import usePaginatedQuery from "@/composables/usePaginatedQuery";

import EntitiesList from "@/components/list/EntitiesList.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    detailsRoute: {
      type: String,
      required: false,
    },
    query: {
      type: Object as PropType<DocumentNode>,
      required: true,
    },
    hasCheckBox: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    checkBoxes: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    formatters: {
      type: Object,
      default: () => ({}),
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });

    const propsRef = toRefs(props);
    const queryRef = propsRef.query;
    const queryParamsRef = propsRef.queryParams;
    const modelCheckBoxes = ref([] as boolean[]);

    const endOfList = ref() as Ref<HTMLElement>;

    const { paginatedResult, loading, loadMore, onResult } = usePaginatedQuery(
      queryRef,
      t("errors.list"),
      queryParamsRef,
    );

    if (paginatedResult.value) {
      if (propsRef.hasCheckBox.value) {
        modelCheckBoxes.value = Array(paginatedResult.value.items.length).fill(
          false,
        );
      }
      context.emit("update:items", paginatedResult.value.items);
    }

    onMounted(() => {
      const handler = (entries: IntersectionObserverEntry[]) => {
        if (entries[0]?.isIntersecting && paginatedResult?.value?.hasMore) {
          loadMore();
        }
      };
      const observer = new window.IntersectionObserver(handler);
      observer.observe(endOfList.value);
    });

    onResult(async () => {
      context.emit("update:items", paginatedResult.value.items);
      if (propsRef.hasCheckBox.value) {
        const numberNewResults =
          paginatedResult.value.items.length - modelCheckBoxes.value.length;
        modelCheckBoxes.value.push(...Array(numberNewResults).fill(false));
      }
      await nextTick();
      const rect = endOfList.value.getBoundingClientRect();
      if (
        rect.bottom <= document.documentElement.clientHeight &&
        paginatedResult?.value?.hasMore &&
        !loading.value
      ) {
        loadMore();
      }
    });

    return {
      paginatedResult,
      loading,
      endOfList,
      modelCheckBoxes,
      t,
    };
  },
  emits: ["update:checkBoxes", "update:items", "actionOnClick"],
  components: {
    EntitiesList,
  },
  watch: {
    selectAll(newValue) {
      this.modelCheckBoxes = this.modelCheckBoxes.map(() => newValue);
    },
  },
  methods: {
    updateCheckBoxes(): void {
      this.$emit("update:checkBoxes", this.modelCheckBoxes);
    },
  },
});
