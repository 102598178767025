import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6abbe7e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-item" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
      class: "wrapper",
      href: _ctx.isLink ? _ctx.href : null,
      target: _ctx.isLink ? _ctx.target : null
    }, {
      default: _withCtx(() => [
        (_ctx.iconName)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              icon: _ctx.iconName,
              class: "icon"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }, 8, ["href", "target"]))
  ]))
}