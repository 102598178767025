import { apolloClient } from "@/apollo.provider";
import { GET_ACCESS_RIGHTS } from "@/graphql/user/queries";
import { getUser, isAdmin } from "../router/queries";
import { ClientAccessRight, ProjectAccessRight } from "../typesDefinition";

export default async function (
  clientId: string | undefined,
  projectId: string | undefined,
  role: string,
  toast?: {
    error: (message: string) => void;
  },
  errorMessage?: string,
): Promise<boolean> {
  try {
    if (role === "admin") {
      const user = await getUser();
      if (isAdmin(user.accessRights)) return true;
    }

    const userResult = await apolloClient.query({
      query: GET_ACCESS_RIGHTS,
    });
    const allAccessRights = userResult.data.user.accessRights;
    if (clientId) {
      const clientAccessRights = allAccessRights.some(
        (accessRight: ClientAccessRight) =>
          accessRight?.client?.id === clientId && accessRight.role === role,
      );
      if (clientAccessRights) return true;
    }
    if (projectId) {
      const projectAccessRights = allAccessRights.some(
        (accessRight: ProjectAccessRight) =>
          accessRight?.project?.id === projectId && accessRight.role === role,
      );
      if (projectAccessRights) return true;
    }
  } catch (error) {
    console.log(error);
    if (toast && errorMessage) {
      toast.error(errorMessage);
    }
  }
  return false;
}
