import gql from "graphql-tag";
import { templateFragment } from "./fragments";
import { phaseFragment } from "@/graphql/orcha/phases/fragments";
import { milestoneFragment } from "@/graphql/orcha/milestones/fragments";
import { projectFragment } from "@/graphql/project/fragments";

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($name: String!, $rank: ID!) {
    createTemplate(name: $name, rank: $rank) {
      status
      error
      data {
        ...TemplateFragment
      }
    }
  }
  ${templateFragment}
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($id: ID!, $name: String, $rank: ID) {
    updateTemplate(id: $id, name: $name, rank: $rank) {
      status
      error
      data {
        ...TemplateFragment
        phases {
          id
          ...PhaseFragment
          milestones {
            id
            ...MilestoneFragment
          }
        }
      }
    }
  }
  ${templateFragment}
  ${phaseFragment}
  ${milestoneFragment}
`;

export const APPLY_TEMPLATE = gql`
  mutation applyTemplate($templateId: ID!, $projectId: ID!) {
    applyTemplate(templateId: $templateId, projectId: $projectId) {
      status
      error
      data {
        ...ProjectFragment
      }
    }
  }
  ${projectFragment}
`;
