import gql from "graphql-tag";

export const templateActivityFragment = gql`
  fragment TemplateActivityFragment on TemplateActivity {
    id
    name
    rank
    description
    estimation
    important
    type {
      id
      name
    }
    dependingOn {
      id
      name
    }
    blocking {
      id
      name
    }
  }
`;

export const activityFragment = gql`
  fragment ActivityFragment on Activity {
    id
    name
    rank
    description
    estimation
    important
    active
    type {
      id
      name
    }
    assignee {
      id
      firstName
      lastName
      avatarUrl
    }
    milestone {
      id
      name
      phase {
        id
        name
      }
    }
    project {
      id
      name
      color
      phases {
        id
        milestones {
          id
          name
        }
      }
    }
    status {
      id
      name
    }
    blocking {
      id
    }
    dependingOn {
      id
    }
    deadline
    private
    createdBy {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const attachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    fileId
    name
    size
    mimeType
    url
  }
`;
