import gql from "graphql-tag";
import { invoiceFragment } from "../invoice/fragments";

export const orderFragment = gql`
  fragment OrderFragment on Order {
    id
    title
    startDate
    endDate
    dueDate
    status
    price
    paymentOption
    invoice {
      ...InvoiceFragment
    }
    company {
      id
      name
    }
  }
  ${invoiceFragment}
`;

export const recurringOrderFragment = gql`
  fragment RecurringOrderFragment on RecurringOrder {
    id
    title
    startDate
    endDate
    status
    price
    company {
      id
      name
    }
  }
`;
