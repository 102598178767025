
import { defineComponent, nextTick, onMounted, Ref, ref } from "vue";
import TileBase from "@/components/TileBase.vue";
import TileTitle from "@/components/TileTitle.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    loading: {
      type: String,
    },
    error: {
      type: String,
    },
    emptySpace: {
      type: String,
    },
    hasMore: {
      type: Boolean,
    },
    onResult: {
      type: Function,
      required: true,
    },
    loadMore: {
      type: Function,
      required: true,
    },
  },
  components: {
    TileBase,
    TileTitle,
  },
  setup(props) {
    const endOfList = ref() as Ref<HTMLElement>;

    onMounted(() => {
      const handler = (entries: IntersectionObserverEntry[]) => {
        if (entries[0]?.isIntersecting && props.hasMore) {
          props.loadMore();
        }
      };
      const observer = new window.IntersectionObserver(handler);
      observer.observe(endOfList.value);
    });

    props.onResult(async () => {
      await nextTick();
      const rect = endOfList.value.getBoundingClientRect();
      if (
        rect.bottom <= document.documentElement.clientHeight &&
        props.hasMore &&
        !props.loading
      ) {
        props.loadMore();
      }
    });

    return { endOfList };
  },
});
