
import { defineComponent, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/client/en-UK.json";

import { GET_CLIENT } from "@/graphql/client/queries";

import EntitiesList from "@/components/list/EntitiesList.vue";
import AddCompany from "@/components/CRUXEntity/AddCompany.vue";
import BasicButton from "@/components/BasicButton.vue";
import { Company } from "@/helpers/client/typesDefinition";
import EditCompany from "@/components/CRUXEntity/EditCompany.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const route = useRoute();
    const toast = useToast();
    const { result, loading, onError } = useQuery(GET_CLIENT, () => ({
      id: route.params.id,
    }));

    const client = useResult(result);

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.get"));
    });

    const companyToEdit = ref() as Ref<Company>;

    return {
      client,
      companyToEdit,
      loading,
      t,
    };
  },
  data() {
    return {
      isAddCompanyModalVisible: false,
      isEditCompanyModalVisible: false,
    };
  },
  provide: {
    columnsOrder: {
      name: null,
      vatNumber: null,
    },
  },
  methods: {
    showAddCompanyModal(): void {
      this.isAddCompanyModalVisible = true;
    },
    closeAddCompanyModal(): void {
      this.isAddCompanyModalVisible = false;
    },
    openEditCompanyModal(company: Company): void {
      this.companyToEdit = company;
      this.isEditCompanyModalVisible = true;
    },
    closeEditCompanyModal(): void {
      this.isEditCompanyModalVisible = false;
    },
  },
  components: {
    EntitiesList,
    AddCompany,
    EditCompany,
    BasicButton,
  },
});
