import gql from "graphql-tag";

export const templateTaskFragment = gql`
  fragment TemplateTaskFragment on TemplateTask {
    id
    name
    isDone
  }
`;

export const taskFragment = gql`
  fragment TaskFragment on Task {
    id
    name
    assignee {
      id
      firstName
      lastName
      avatarUrl
    }
    isDone
  }
`;
