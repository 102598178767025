import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40532a28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mutate-entity-modal-body" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { onClose: _ctx.closeModal }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.messages.title || _ctx.t("titles.add")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          id: `mutate-form-${_ctx.mutationName}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterHiddenProperties(_ctx.variables), (value, name, index) => {
            return (_openBlock(), _createBlock(_component_FormItem, {
              key: index,
              input: _ctx.variables[name],
              "onUpdate:input": ($event: any) => ((_ctx.variables[name]) = $event),
              placeholder: _ctx.placeholder ? _ctx.placeholder[name] : undefined,
              errors: _ctx.v$[name].$errors,
              label: _ctx.t(`labels.${name}`),
              query: _ctx.formQueries ? _ctx.formQueries[name] : undefined,
              options: _ctx.formOptions ? _ctx.formOptions[name] : undefined,
              mode: _ctx.modes[name],
              name: name,
              "read-only": _ctx.readOnlyProperties && _ctx.readOnlyProperties.includes(name),
              "input-type": _ctx.inputType(name)
            }, null, 8, ["input", "onUpdate:input", "placeholder", "errors", "label", "query", "options", "mode", "name", "read-only", "input-type"]))
          }), 128))
        ], 40, _hoisted_2),
        (_ctx.mutate?.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("loading")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        class: "mutate-button",
        type: "submit",
        form: `mutate-form-${_ctx.mutationName}`,
        disabled: _ctx.mutate?.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.messages.formButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["form", "disabled"]),
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["onClose"]))
}