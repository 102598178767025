
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/home/en-UK.json";

import HeaderBlock from "@/components/HeaderBlock.vue";
import TileAdminProjects from "@/components/TileAdminProjects.vue";
import BasicButton from "@/components/BasicButton.vue";

import { SYNC_DATA } from "@/graphql/syncData/mutations";
import { SYNC_INVOICES } from "@/graphql/invoice/mutations";

import { apolloClient } from "@/apollo.provider";
import { SYNC_PERFORMED_ORDERS } from "@/graphql/order/mutations";
import { GET_USER } from "@/graphql/user/queries";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();

    const { result } = useQuery(GET_USER);
    const userName = useResult(result, "", (data) => {
      return data.user.firstName;
    });

    const {
      mutate: syncData,
      onDone: onDoneSync,
      onError: onErrorSync,
      loading: loadingSync,
    } = useMutation(SYNC_DATA);

    onDoneSync(async (result) => {
      if (result.data?.syncData?.status === "ok") {
        toast.success(t("success.syncData"));
        apolloClient.cache.reset();
      } else {
        const errorResponse = result.data?.syncData?.error;
        console.log(errorResponse);
        toast.error(t("errors.syncData", { message: errorResponse }));
      }
    });

    onErrorSync((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.syncData", { message: "issue with request to backend" }),
      );
    });

    const {
      mutate: syncInvoices,
      onDone: onDoneSyncInvoices,
      onError: onErrorSyncInvoices,
      loading: loadingSyncInvoices,
    } = useMutation(SYNC_INVOICES);

    onDoneSyncInvoices(async (result) => {
      if (result.data?.syncInvoices?.status === "ok") {
        toast.success(t("success.syncInvoices"));
        await syncPerformedOrders();
      } else {
        const errorResponse = result.data?.syncInvoices?.error;
        console.log(errorResponse);
        toast.error(t("errors.syncInvoices", { message: errorResponse }));
      }
    });

    onErrorSyncInvoices((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.syncInvoices", {
          message: "issue with request to backend",
        }),
      );
    });

    const {
      mutate: syncPerformedOrders,
      onDone: onDoneSyncPerformedOrders,
      onError: onErrorSyncPerformedOrders,
      loading: loadingSyncPerformedOrders,
    } = useMutation(SYNC_PERFORMED_ORDERS);

    onDoneSyncPerformedOrders(async (result) => {
      if (result.data?.syncPerformedOrders?.status === "ok") {
        toast.success(t("success.syncPerformedOrders"));
      } else {
        const errorResponse = result.data?.syncPerformedOrders?.error;
        console.log(errorResponse);
        toast.error(
          t("errors.syncPerformedOrders", { message: errorResponse }),
        );
      }
    });

    onErrorSyncPerformedOrders((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.syncPerformedOrders", {
          message: "issue with request to backend",
        }),
      );
    });

    return {
      t,
      syncData,
      loadingSync,
      syncInvoices,
      loadingSyncInvoices,
      loadingSyncPerformedOrders,
      userName,
    };
  },

  data() {
    return {
      enUK,
      isModalVisible: false,
    };
  },
  methods: {
    closeModal(): void {
      this.isModalVisible = false;
    },
  },
  components: {
    HeaderBlock,
    TileAdminProjects,
    BasicButton,
  },
});
