import { reactive } from "vue";
import dayjs from "dayjs";
import { Order } from "@/helpers/order/typesDefinition";
import { computeOrderLineSubtotal } from "@/helpers/utils";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (originalOrder: Order) {
  const lastDay = dayjs(originalOrder.endDate).subtract(1, "day");
  const order = reactive({
    title: originalOrder.title,
    startYear: dayjs(originalOrder.startDate).year(),
    startMonth: dayjs(originalOrder.startDate).month() + 1,
    endYear: lastDay.year(),
    endMonth: lastDay.month() + 1,
    notes: originalOrder.notes,
    legalNotice: originalOrder.legalNotice,
    paymentOption: originalOrder.paymentOption,
    companyId: originalOrder.company.id,
    dueDate: originalOrder.dueDate,
  });

  const orderLines = originalOrder.orderLines.map((ol) => ({
    subtotal: computeOrderLineSubtotal(ol.quantity, ol.hourlyRate, ol.unit),
    quantity: ol.quantity,
    unit: ol.unit,
  }));

  return { order, orderLines };
}
