import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73d7a02c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "loading apollo"
}
const _hoisted_3 = {
  key: 1,
  class: "grid"
}
const _hoisted_4 = { class: "column-container" }
const _hoisted_5 = {
  key: 0,
  class: ""
}
const _hoisted_6 = { class: "entities-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex-item-list button" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "column-container" }
const _hoisted_12 = { class: "day" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_EntitiesList = _resolveComponent("EntitiesList")!
  const _component_TimeBlock = _resolveComponent("TimeBlock")!
  const _component_EditTimer = _resolveComponent("EditTimer")!
  const _component_EditTimesheet = _resolveComponent("EditTimesheet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.themesLoading || _ctx.timersLoading || _ctx.timesheetsLoading)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.t("loading")), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.timers.length > 0)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.t("timesheets.timers.title")), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timers, (timer, idx) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: timer.theme.id,
                      class: _normalizeClass(timer.running ? 'active' : '')
                    }, [
                      _createElementVNode("div", {
                        class: "flex-item-list title",
                        onClick: ($event: any) => (_ctx.restartTimer(idx))
                      }, _toDisplayString(timer.theme.project.name) + " " + _toDisplayString(timer.theme.name), 9, _hoisted_7),
                      _createElementVNode("div", {
                        class: "flex-item-list timer",
                        onClick: ($event: any) => (_ctx.restartTimer(idx))
                      }, _toDisplayString(timer.displayedValue), 9, _hoisted_8),
                      _createElementVNode("div", _hoisted_9, [
                        (timer.running)
                          ? (_openBlock(), _createBlock(_component_IconButton, {
                              key: 0,
                              color: "canvas",
                              "icon-name": "material-symbols:pause-rounded",
                              onClick: ($event: any) => (_ctx.stopTimer(idx))
                            }, null, 8, ["onClick"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createVNode(_component_IconButton, {
                                "icon-name": "jam:pen-f",
                                onClick: ($event: any) => (_ctx.openEditTimerModal(idx))
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_IconButton, {
                                "icon-name": "material-symbols:play-arrow-rounded",
                                onClick: ($event: any) => (_ctx.restartTimer(idx))
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_IconButton, {
                                color: "canvas",
                                "icon-name": "material-symbols:stop-rounded",
                                onClick: ($event: any) => (_ctx.createTimesheet(timer.theme, idx))
                              }, null, 8, ["onClick"])
                            ], 64))
                      ])
                    ], 2))
                  }), 128))
                ])
              ]),
              (_ctx.timers.length > 0)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
                : _createCommentVNode("", true),
              _createElementVNode("h2", null, _toDisplayString(_ctx.t("timesheets.themes.title")), 1),
              _createVNode(_component_BasicInput, {
                placeholder: _ctx.t('timesheets.themes.filter'),
                input: _ctx.themeFilter,
                "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.themeFilter) = $event)),
                class: "theme-filter",
                inputId: "theme-filter",
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterThemes($event.target?.value)))
              }, null, 8, ["placeholder", "input"]),
              _createVNode(_component_EntitiesList, {
                elements: _ctx.themes,
                onGoEntityDetails: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addTimerForTheme($event)))
              }, null, 8, ["elements"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.t("timesheets.title")), 1),
              _createElementVNode("h3", null, _toDisplayString(_ctx.t("timesheets.encodedTime", { time: _ctx.totalTime(_ctx.timesheets) })), 1),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timesheets, ({ duration, theme }, idx) => {
                  return (_openBlock(), _createBlock(_component_TimeBlock, {
                    key: theme.id,
                    duration: duration,
                    theme: theme,
                    onClick: ($event: any) => (_ctx.openEditTimesheetModal(idx))
                  }, null, 8, ["duration", "theme", "onClick"]))
                }), 128))
              ])
            ])
          ]))
    ]),
    (_ctx.isEditTimerModalVisible)
      ? (_openBlock(), _createBlock(_component_EditTimer, {
          key: 0,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeEditTimerModal())),
          timer: _ctx.timers[_ctx.timerIdxToEdit],
          "onUpdate:timer": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.timers[_ctx.timerIdxToEdit]) = $event))
        }, null, 8, ["timer"]))
      : _createCommentVNode("", true),
    (_ctx.isEditTimesheetModalVisible)
      ? (_openBlock(), _createBlock(_component_EditTimesheet, {
          key: 1,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeEditTimesheetModal())),
          onDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeTimesheet(_ctx.timesheetIdxToEdit))),
          timesheet: _ctx.timesheets[_ctx.timesheetIdxToEdit],
          "onUpdate:timesheet": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.timesheets[_ctx.timesheetIdxToEdit]) = $event))
        }, null, 8, ["timesheet"]))
      : _createCommentVNode("", true)
  ], 64))
}