import { getIndexFirstHigherOrEqual } from "./binarySearch";
import { Comparator, PaginatedResult } from "../typesDefinition";

function mutatedEntityIsHighest<T>(
  mutatedEntity: T,
  existingEntities: T[],
  comparator: (e1: T, e2: T) => number,
): boolean {
  return (
    comparator(mutatedEntity, existingEntities[existingEntities.length - 1]) > 0
  );
}

export function insertNewEntity<T>(
  newEntity: T,
  cacheResult: T[],
  comparator: Comparator<T, T>,
): T[] {
  const updatedEntities = Array.from(cacheResult);
  if (updatedEntities.length === 0) {
    updatedEntities.push(newEntity);
    return updatedEntities;
  }
  const indexFirstHigherOrEqual = getIndexFirstHigherOrEqual(
    updatedEntities,
    newEntity,
    comparator,
  );
  if (indexFirstHigherOrEqual < 0)
    throw new Error("Can't insert new entity in Cache");
  updatedEntities.splice(indexFirstHigherOrEqual, 0, newEntity);
  return updatedEntities;
}

export function insertNewEntityPaginated<T>(
  newEntity: T,
  cacheResult: PaginatedResult<T>,
  comparator: Comparator<T, T>,
): T[] {
  const updatedEntities = Array.from(cacheResult.items);
  if (updatedEntities.length === 0) {
    updatedEntities.push(newEntity);
    return updatedEntities;
  }
  if (mutatedEntityIsHighest(newEntity, updatedEntities, comparator)) {
    if (!cacheResult.hasMore) updatedEntities.push(newEntity);
    return updatedEntities;
  }
  return insertNewEntity(newEntity, updatedEntities, comparator);
}
