import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import ErrorPage from "@/views/ErrorPage.vue";
import AdminHome from "@/views/AdminHome.vue";
import LogIn from "@/views/LogIn.vue";
import Verify from "@/views/Verify.vue";
import InvoicingPage from "@/views/invoicing/InvoicingPage.vue";
import InvoicingNonOrdered from "@/views/invoicing/InvoicingNonOrdered.vue";
import InvoicingDeltas from "@/views/invoicing/InvoicingDeltas.vue";
import OpenedCreditNotes from "@/views/invoicing/OpenedCreditNotes.vue";

import { clientRoutes } from "./clientRoutes";
import { ProjectRoutes, projectRoutes } from "./projectRoutes";

import { getUser, isAdmin, isEmployee } from "@/helpers/router/queries";
import IndependentInvoices from "@/views/invoicing/IndependentInvoices.vue";
import TimesheetTimerPage from "@/views/TimesheetTimerPage.vue";
import EmployeeHome from "@/views/EmployeeHome.vue";
import EmployeeProjectDetails from "@/views/EmployeeProjectDetails.vue";
import ClientInvoices from "@/views/client/ClientInvoices.vue";
import OpenedInvoices from "@/views/invoicing/OpenedInvoices.vue";
import RevenueForecast from "@/views/RevenueForecast.vue";
import orchaRoutes from "@/router/orchaRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: "AdminHome",
    component: AdminHome,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/employee",
    name: "EmployeeHome",
    component: EmployeeHome,
    meta: { requiresAuth: true, requiresEmployee: true },
  },
  {
    path: "/employee/project/:id",
    name: "EmployeeProjectDetails",
    component: EmployeeProjectDetails,
    meta: { requiresAuth: true, requiresEmployee: true },
  },
  {
    path: "/timers",
    name: "TimesheetTimer",
    component: TimesheetTimerPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    name: "ClientInvoices",
    component: ClientInvoices,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoicing",
    component: InvoicingPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    props: true,
    children: [
      {
        path: "",
        name: "Invoicing",
        component: InvoicingDeltas,
        props: true,
      },
      {
        path: "non-ordered",
        name: "InvoicingNonOrdered",
        component: InvoicingNonOrdered,
        props: true,
      },
      {
        path: "independent",
        name: "IndependentInvoices",
        component: IndependentInvoices,
        props: true,
      },
      {
        path: "opened-credit-notes",
        name: "OpenedCreditNotes",
        component: OpenedCreditNotes,
        props: true,
      },
      {
        path: "opened-invoices",
        name: "OpenedInvoices",
        component: OpenedInvoices,
        props: true,
      },
    ],
  },
  {
    path: "/revenue",
    name: "RevenueForecast",
    component: RevenueForecast,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/",
    name: "Home",
    component: ErrorPage,
    beforeEnter: async () => {
      const user = await getUser();
      if (user) {
        if (isEmployee(user.accessRights)) return { name: "EmployeeHome" };
        return { name: ProjectRoutes.PROJECTS_ACTIVE };
      }
      return { name: "Login" };
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LogIn,
    beforeEnter: async () => {
      const user = await getUser();
      if (user) return { name: "Home" };
    },
  },
  {
    path: "/auth/confirm",
    name: "Verify",
    component: Verify,
    props: (route) => ({ email: route.query.email, token: route.query.token }),
    beforeEnter: async (route) => {
      const user = await getUser();
      if (user) {
        if (route.query.redirect) return { path: route.query.redirect };
        return { name: "Home" };
      }
    },
  },
];

routes.push(...clientRoutes, ...projectRoutes, ...orchaRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.meta.requiresAuth) {
    const user = await getUser();
    if (!user) {
      return {
        path: "/login",
        query: { redirect: to.fullPath },
      };
    }
    if (to.meta.requiresAdmin) {
      if (!isAdmin(user.accessRights)) {
        return {
          path: from.fullPath,
        };
      }
    }
    if (to.meta.requiresEmployee) {
      if (!isEmployee(user.accessRights)) {
        return {
          path: from.fullPath,
        };
      }
    }
  }
});

export default router;
