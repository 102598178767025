import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f586248"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mutate-entity-modal-body" }
const _hoisted_2 = { class: "formItem" }
const _hoisted_3 = { class: "formLabel" }
const _hoisted_4 = { class: "formItem" }
const _hoisted_5 = { class: "formLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { onClose: _ctx.closeModal }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("timesheets.modal.title")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          id: "mutate-form"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t("labels.project")) + " :", 1),
            _createVNode(_component_BasicInput, {
              input: _ctx.projectName,
              "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.projectName) = $event)),
              inputId: "project",
              readOnly: true
            }, null, 8, ["input"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t("labels.theme")) + " :", 1),
            _createVNode(_component_BasicInput, {
              input: _ctx.themeName,
              "onUpdate:input": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.themeName) = $event)),
              inputId: "theme",
              readOnly: true
            }, null, 8, ["input"])
          ]),
          _createVNode(_component_FormItem, {
            key: "role",
            input: _ctx.variables['role'],
            "onUpdate:input": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.variables['role']) = $event)),
            errors: _ctx.v$['role'].$errors,
            label: _ctx.t(`labels.role`),
            name: "role",
            options: _ctx.roles
          }, null, 8, ["input", "errors", "label", "options"]),
          _createVNode(_component_FormItem, {
            key: "duration",
            input: _ctx.variables['duration'],
            "onUpdate:input": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.variables['duration']) = $event)),
            errors: _ctx.v$['duration'].$errors,
            label: _ctx.t(`labels.duration`),
            name: "duration"
          }, null, 8, ["input", "errors", "label"]),
          _createVNode(_component_FormItem, {
            key: "notes",
            input: _ctx.variables['notes'],
            "onUpdate:input": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.variables['notes']) = $event)),
            errors: _ctx.v$['notes'].$errors,
            label: _ctx.t(`labels.notes`),
            name: "notes",
            "is-text-area": true
          }, null, 8, ["input", "errors", "label"])
        ], 32)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        class: "mutate-button",
        type: "submit",
        form: "mutate-form",
        disabled: 
          _ctx.rolesLoading || _ctx.creationLoading || _ctx.updateLoading || _ctx.deleteLoading
        
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.timesheet.id
            ? _ctx.t("timesheets.buttons.update")
            : _ctx.t("timesheets.buttons.create")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      (_ctx.timesheet.id)
        ? (_openBlock(), _createBlock(_component_BasicButton, {
            key: 0,
            class: _normalizeClass(['mutate-button', 'delete-button']),
            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.deleteTimesheet()), ["prevent"])),
            disabled: 
          _ctx.rolesLoading || _ctx.creationLoading || _ctx.updateLoading || _ctx.deleteLoading
        
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("timesheets.buttons.delete")), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose"]))
}