
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";
import TilePaginatedDashboard from "@/components/TilePaginatedDashboard.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { Project } from "@/helpers/project/typesDefinition";
import { GET_PROJECTS_DASHBOARD } from "@/graphql/project/queries";
import enUK from "@/locales/project/en-UK.json";
import { formatPrice } from "@/helpers/utils";
import { PaginatedResult } from "@/helpers/typesDefinition";
import { getAndFormatProjectRemaining } from "@/helpers/project/utils";
import { ProjectRoutes } from "@/router/projectRoutes";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const { result, loading, error, onResult, fetchMore } = useQuery<{
      projects: PaginatedResult<Project>;
    }>(
      GET_PROJECTS_DASHBOARD,
      () => ({
        offset: 0,
      }),
      { notifyOnNetworkStatusChange: true },
    );

    const paginatedProjects = useResult(result);

    const hasProjects = computed(
      () => paginatedProjects.value && paginatedProjects.value.items.length > 0,
    );

    const projectsWithBudgetWarning = computed(
      () =>
        paginatedProjects.value?.items.filter(
          (project) => project.budgetWarning,
        ),
    );

    const projectsWithLateWarnings = computed(
      () =>
        paginatedProjects.value?.items.filter(
          (project) =>
            !project.budgetWarning &&
            project.latePaymentInvoicesCount + project.lateApprovalOrdersCount >
              0,
        ),
    );

    const otherProjects = computed(
      () =>
        paginatedProjects.value?.items.filter(
          (project) =>
            !project.budgetWarning &&
            project.latePaymentInvoicesCount +
              project.lateApprovalOrdersCount <=
              0,
        ),
    );

    function loadMore() {
      fetchMore({
        variables: {
          offset: paginatedProjects.value?.items.length,
        },
      });
    }

    return {
      error,
      loading,
      hasProjects,
      t,
      formatPrice,
      projectsWithBudgetWarning,
      projectsWithLateWarnings,
      otherProjects,
      loadMore,
      onResult,
      getAndFormatProjectRemaining,
      paginatedProjects,
      ProjectRoutes,
    };
  },
  components: {
    TilePaginatedDashboard,
    RouterLink,
  },
});
