
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import BasicButton from "@/components/BasicButton.vue";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "lg",
    },
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
      inheritLocale: true,
    });
    return { t };
  },
  emits: ["close"],
  methods: {
    close(): void {
      this.$emit("close");
    },
  },
  components: {
    BasicButton,
  },
});
