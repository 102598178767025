
import { computed, defineComponent, PropType, toRef } from "vue";
import { required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/theme/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { UPDATE_THEME } from "@/graphql/project/mutations";
import { Theme } from "@/helpers/project/typesDefinition";
import { useRoute } from "vue-router";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup(props) {
    const route = useRoute();
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const theme = toRef(props, "theme");

    const entityProps = computed(() => {
      return {
        name: theme.value?.name,
        id: theme.value?.id,
      };
    });

    return {
      t,
      enUK,
      entityProps,
      unmutableEntityProps: {
        projectId: route.params.id,
      },
      validationRules: computed(() => ({
        name: {
          required,
        },
      })),
      messages: {
        formButtonLabel: t("buttons.edit"),
        error: t("errors.edit"),
        success: t("success.edit"),
        title: t("titles.edit"),
      },
    };
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    UPDATE_THEME() {
      return UPDATE_THEME;
    },
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
