import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MutateEntity = _resolveComponent("MutateEntity")!

  return (_openBlock(), _createBlock(_component_MutateEntity, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
    enUK: _ctx.enUK,
    entityProps: {
      id: _ctx.company.id,
      name: _ctx.company.name,
      vatNumber: _ctx.company.vatNumber,
      vatExempted: _ctx.company.vatExempted,
    },
    validationRules: _ctx.validationRules,
    updatePaginatedQueryCache: _ctx.updateCompany,
    mutation: _ctx.UPDATE_COMPANY,
    mutationName: "updateCompany",
    messages: _ctx.messages
  }, null, 8, ["enUK", "entityProps", "validationRules", "updatePaginatedQueryCache", "mutation", "messages"]))
}