import gql from "graphql-tag";
import { invoiceFragment } from "./fragments";

export const SYNC_INVOICES = gql`
  mutation syncInvoices {
    syncInvoices {
      status
      error
      data {
        ...InvoiceFragment
      }
    }
  }
  ${invoiceFragment}
`;

export const UPDATE_INVOICE_PROJECT = gql`
  mutation updateInvoiceProject($id: ID!, $projectId: ID!) {
    updateInvoice(id: $id, projectId: $projectId) {
      status
      error
      data {
        ...InvoiceFragment
      }
    }
  }
  ${invoiceFragment}
`;

export const CLOSE_CREDIT_NOTES = gql`
  mutation closeCreditNotes($ids: [ID!]!) {
    closeCreditNotes(ids: $ids) {
      status
      error
      data {
        ...InvoiceFragment
      }
    }
  }
  ${invoiceFragment}
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $comment: String, $promisedDate: Date) {
    updateInvoice(id: $id, comment: $comment, promisedDate: $promisedDate) {
      status
      error
      data {
        ...InvoiceFragment
        date
        dueDate
        promisedDate
        comment
        company {
          id
          name
          client {
            id
            name
          }
        }
      }
    }
  }
  ${invoiceFragment}
`;
