import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectCompanyFinancialData = _resolveComponent("ProjectCompanyFinancialData")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialData, (companyData) => {
      return (_openBlock(), _createBlock(_component_ProjectCompanyFinancialData, {
        key: companyData.id,
        "company-data": companyData
      }, null, 8, ["company-data"]))
    }), 128)),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true)
  ], 64))
}