import gql from "graphql-tag";
import { templateActivityFragment, activityFragment } from "./fragments";
import { templateTaskFragment } from "@/graphql/orcha/tasks/fragments";

export const CREATE_TEMPLATE_ACTIVITY = gql`
  mutation createTemplateActivity(
    $milestoneId: ID!
    $name: String!
    $rank: ID!
  ) {
    createTemplateActivity(
      milestoneId: $milestoneId
      name: $name
      rank: $rank
    ) {
      status
      error
      data {
        ...TemplateActivityFragment
        tasks {
          ...TemplateTaskFragment
        }
      }
    }
  }
  ${templateActivityFragment}
  ${templateTaskFragment}
`;

export const UPDATE_TEMPLATE_ACTIVITY = gql`
  mutation updateTemplateActivity(
    $id: ID!
    $name: String
    $rank: ID
    $description: String
    $estimation: Float
    $important: Boolean
    $typeId: ID
    $dependingOn: [ID!]
    $blocking: [ID!]
  ) {
    updateTemplateActivity(
      id: $id
      name: $name
      rank: $rank
      description: $description
      estimation: $estimation
      important: $important
      typeId: $typeId
      blocking: $blocking
      dependingOn: $dependingOn
    ) {
      status
      error
      data {
        ...TemplateActivityFragment
        tasks {
          ...TemplateTaskFragment
        }
      }
    }
  }
  ${templateActivityFragment}
  ${templateTaskFragment}
`;

export const DELETE_TEMPLATE_ACTIVITY = gql`
  mutation DeleteTemplateActivity($id: ID!) {
    deleteTemplateActivity(id: $id) {
      status
      error
      data {
        ...TemplateActivityFragment
      }
    }
  }
  ${templateActivityFragment}
`;

export const CREATE_ACTIVITY = gql`
  mutation CreateActivity(
    $name: String!
    $rank: ID!
    $projectId: ID
    $statusId: ID
    $milestoneId: ID
    $description: String
    $estimation: Float
    $important: Boolean
    $typeId: ID
    $assigneeId: ID
    $deadline: Date
  ) {
    createActivity(
      name: $name
      rank: $rank
      projectId: $projectId
      statusId: $statusId
      milestoneId: $milestoneId
      description: $description
      estimation: $estimation
      important: $important
      typeId: $typeId
      assigneeId: $assigneeId
      deadline: $deadline
    ) {
      status
      error
      data {
        ...ActivityFragment
      }
    }
  }
  ${activityFragment}
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity(
    $id: ID!
    $name: String
    $rank: ID
    $description: String
    $estimation: Float
    $important: Boolean
    $typeId: ID
    $assigneeId: ID
    $statusId: ID
    $deadline: Date
    $active: Boolean
    $projectId: ID
    $milestoneId: ID
    $dependingOn: [ID!]
    $blocking: [ID!]
    $private: Boolean
  ) {
    updateActivity(
      id: $id
      name: $name
      rank: $rank
      description: $description
      estimation: $estimation
      important: $important
      typeId: $typeId
      assigneeId: $assigneeId
      statusId: $statusId
      deadline: $deadline
      active: $active
      projectId: $projectId
      milestoneId: $milestoneId
      blocking: $blocking
      dependingOn: $dependingOn
      private: $private
    ) {
      status
      error
      data {
        ...ActivityFragment
      }
    }
  }
  ${activityFragment}
`;

export const ARCHIVE_ACTIVITY = gql`
  mutation ArchiveActivity($id: ID!) {
    archiveActivity(id: $id) {
      status
      error
      data {
        ...ActivityFragment
      }
    }
  }
  ${activityFragment}
`;

export const UNARCHIVE_ACTIVITY = gql`
  mutation UnarchiveActivity($id: ID!) {
    unarchiveActivity(id: $id) {
      status
      error
      data {
        ...ActivityFragment
      }
    }
  }
  ${activityFragment}
`;

export const CREATE_ATTACHMENT = gql`
  mutation createAttachment($fileId: String!, $activityId: ID!) {
    createAttachment(fileId: $fileId, activityId: $activityId) {
      status
      error
      data {
        id
        fileId
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: ID!) {
    deleteAttachment(id: $id) {
      status
      error
      data {
        id
        fileId
      }
    }
  }
`;
