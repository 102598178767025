import gql from "graphql-tag";
import { phaseFragment } from "./fragments";
import { milestoneFragment } from "../milestones/fragments";
import { activityFragment, attachmentFragment } from "../activities/fragments";
import { commentFragment } from "../comments/fragments";
import { taskFragment } from "../tasks/fragments";

export const GET_PHASES = gql`
  query getPhases($projectId: ID!, $includeInactive: Boolean) {
    phases(projectId: $projectId, includeInactive: $includeInactive) {
      ...PhaseFragment
      deadline
      milestones(includeInactive: $includeInactive) {
        ...MilestoneFragment
        deadline
        assignee {
          id
          firstName
          lastName
          avatarUrl
        }
        activities(includeInactive: $includeInactive) {
          ...ActivityFragment
          tasks {
            ...TaskFragment
          }
          comments {
            ...CommentFragment
          }
          attachments {
            ...AttachmentFragment
          }
        }
      }
    }
  }
  ${phaseFragment}
  ${milestoneFragment}
  ${activityFragment}
  ${taskFragment}
  ${commentFragment}
  ${attachmentFragment}
`;
