import { OpenedDeltasResponse, Delta } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { deltaComparator } from "./utils";
import { GET_OPENED_DELTAS } from "@/graphql/delta/queries";

const DelayedOpenedDeltasCacheInfoHolder: PaginatedCacheInfoHolder<
  Delta,
  OpenedDeltasResponse
> = {
  query: GET_OPENED_DELTAS,
  variables: { status: ["delayed"] },

  cachedPaginatedResult: (
    cachedResponse: OpenedDeltasResponse | null,
  ): PaginatedResult<Delta> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.openedDeltas;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<Delta>,
  ): OpenedDeltasResponse => ({
    openedDeltas: paginatedResult,
  }),

  comparator: deltaComparator,
};

export default DelayedOpenedDeltasCacheInfoHolder;
