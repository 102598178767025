import moment from "moment";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(objectSupport);

function numberToStringOf2Characters(number: number): string {
  return ("0" + number).slice(-2);
}

export function dateToString(date: Date): string {
  const month = numberToStringOf2Characters(date.getMonth() + 1);
  const day = numberToStringOf2Characters(date.getDate());
  return `${date.getFullYear()}-${month}-${day}`;
}

export function startOfMonthDate(year: number, month: number): string {
  return `${year}-${numberToStringOf2Characters(month)}-01`;
}

export function endOfMonthDate(year: number, month: number): string {
  return dayjs({ year, month: month - 1 })
    .endOf("month")
    .format("YYYY-MM-DD");
}

export function getMonthOptions(): Record<number, string> {
  return moment
    .months()
    .reduce((acc, cur, index) => ({ ...acc, [index + 1]: cur }), {});
}

export function getYearOptions(): number[] {
  const currentDate = new Date();
  return Array.from({ length: 5 }, (_, i) => currentDate.getFullYear() + i - 1);
}

export function getNextMonthDate(date: Date): {
  nextMonthYear: number;
  nextMonthMonth: number;
} {
  const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return {
    nextMonthYear: nextMonthDate.getFullYear(),
    nextMonthMonth: nextMonthDate.getMonth(),
  };
}

export function formatDuration(duration: number): string {
  const elapsedMs = duration % 1000;
  duration = (duration - elapsedMs) / 1000;
  const elapsedSeconds = duration % 60;
  duration = (duration - elapsedSeconds) / 60;
  const elapsedMinutes = duration % 60;
  const elapsedHours = (duration - elapsedMinutes) / 60;

  const hoursPart = String(elapsedHours).padStart(2, "0");
  const minutesPart = String(elapsedMinutes).padStart(2, "0");
  const secondsPart = String(elapsedSeconds).padStart(2, "0");

  return `${hoursPart}:${minutesPart}:${secondsPart}`;
}
