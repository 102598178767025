
import { computed, defineComponent, ref, onMounted } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";

import { LOGIN } from "@/graphql/user/mutations";

import enUK from "@/locales/login/en-UK.json";
import { useRoute } from "vue-router";
import { Icon } from "@iconify/vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();
    const isDisabled = ref(false);

    const route = useRoute();

    const userEmail = ref("");
    const rules = computed(() => ({
      userEmail: {
        required,
        email,
      },
    }));

    const v$ = useVuelidate(rules, { userEmail }, { $autoDirty: true });
    const isModalVisible = ref(false);
    const modalHeader = ref("");
    const modalBody = ref("");
    const iconName = ref("");

    const {
      mutate: logIn,
      onDone,
      onError,
      loading,
    } = useMutation(LOGIN, () => ({
      variables: {
        email: userEmail.value,
        redirect: route.query.redirect,
      },
      fetchPolicy: "no-cache",
    }));

    onDone((result) => {
      if (result.data?.login?.status === "ok") {
        isModalVisible.value = true;
        modalHeader.value = t("success.header");
        modalBody.value = t("success.body", { email: userEmail.value });
        iconName.value = "mdi:check-circle";
      } else {
        const errorResponse = result.data?.login?.error;
        console.log(errorResponse);
        isModalVisible.value = true;
        modalHeader.value = t("error.header");
        modalBody.value = t("error.body", { email: userEmail.value });
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.login"));
    });

    const inputRef = ref<HTMLInputElement | null>(null);

    onMounted(() => {
      inputRef.value?.focus();
    });

    return {
      isDisabled,
      userEmail,
      logIn,
      loading,
      isModalVisible,
      modalHeader,
      modalBody,
      v$,
      t,
      iconName,
      inputRef,
    };
  },
  methods: {
    showModal(): void {
      this.isModalVisible = true;
    },
    closeModal(): void {
      this.isDisabled = false;
      this.isModalVisible = false;
      this.iconName = "";
    },
    async submitForm() {
      this.iconName = "";
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.isDisabled = true;
        return this.logIn();
      }
    },
  },
  components: {
    Modal,
    BasicButton,
    Icon,
  },
});
