
import { defineComponent, PropType } from "vue";
import { formatPrice } from "@/helpers/utils";
import { CompanyFinancialData } from "@/helpers/invoice/typesDefinition";
import TileTitle from "@/components/TileTitle.vue";
import { useI18n } from "vue-i18n";
import enUK from "@/locales/invoicing/en-UK.json";
import TileBase from "../TileBase.vue";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    companyData: {
      type: Object as PropType<CompanyFinancialData>,
    },
  },
  components: {
    TileTitle,
    TileBase,
  },
  setup: () => {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    return {
      t,
      formatPrice,
    };
  },
});
