import Templates from "@/views/Templates.vue";
import ActivityFeed from "@/views/ActivityFeed.vue";
import { getTemplateRoutes } from "@26lights/orcha";
import { RouteRecordRaw } from "vue-router";

const templateBaseRoute = {
  component: Templates,
  meta: { requiresAuth: true, requiresAdmin: true },
};
const templateRoutes = getTemplateRoutes("/templates", templateBaseRoute);

const activityFeedRoute: RouteRecordRaw = {
  path: "/activity-feed/:id?",
  name: "ActivityFeed",
  component: ActivityFeed,
  props: (route) => ({
    initialActivityId: route.params.id,
  }),
  meta: { requiresAuth: true },
};

export default [...templateRoutes, activityFeedRoute];
