import gql from "graphql-tag";
import { clientFragment, companyFragment } from "./fragments";

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!) {
    createClient(name: $name) {
      status
      error
      data {
        ...ClientFragment
      }
    }
  }
  ${clientFragment}
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $name: String) {
    updateClient(id: $id, name: $name) {
      status
      error
      data {
        ...ClientFragment
      }
    }
  }
  ${clientFragment}
`;

export const ARCHIVE_CLIENT = gql`
  mutation archiveClient($id: ID!) {
    archiveClient(id: $id) {
      status
      error
      data {
        ...ClientFragment
      }
    }
  }
  ${clientFragment}
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String
    $vatNumber: String
    $clientId: ID!
    $vatExempted: Boolean
  ) {
    createCompany(
      name: $name
      clientId: $clientId
      vatNumber: $vatNumber
      vatExempted: $vatExempted
    ) {
      status
      error
      data {
        ...CompanyFragment
      }
    }
  }
  ${companyFragment}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: ID!
    $name: String
    $vatNumber: String
    $vatExempted: Boolean
  ) {
    updateCompany(
      id: $id
      name: $name
      vatNumber: $vatNumber
      vatExempted: $vatExempted
    ) {
      status
      error
      data {
        ...CompanyFragment
      }
    }
  }
  ${companyFragment}
`;
