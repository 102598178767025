import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2806d200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mutate-entity-modal-body" }
const _hoisted_2 = { class: "timer-edit-periods-list" }
const _hoisted_3 = {
  key: 0,
  class: "timer-edit-periods-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { onClose: _ctx.closeModal }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("timers.editModal.title")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          id: "mutate-form"
        }, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timer.periods, (period, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: period.id
              }, [
                _createElementVNode("li", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_ctx.t("timers.editModal.period", {
                    start: _ctx.formatTimestamp(period.startTime),
                    end: _ctx.formatTimestamp(period.stopTime),
                  })) + " ", 1),
                  _createVNode(_component_BasicInput, {
                    mask: "##:##:##",
                    input: _ctx.variables.periods[idx].duration,
                    "onUpdate:input": ($event: any) => ((_ctx.variables.periods[idx].duration) = $event),
                    inputId: period.id
                  }, null, 8, ["input", "onUpdate:input", "inputId"])
                ]),
                (
                  _ctx.v$.periods.$errors &&
                  _ctx.v$.periods.$errors[0]?.$message &&
                  _ctx.v$.periods.$errors[0].$message[idx]
                )
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.periods.$errors[0]
                    .$message[idx], (errorMessage, errorIdx) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `error_${idx}_${errorIdx}`,
                        class: "validation-error"
                      }, _toDisplayString(errorMessage), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (_ctx.timer.periods.length > 1)
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                  _createTextVNode(_toDisplayString(_ctx.t("timers.editModal.total")) + " ", 1),
                  _createVNode(_component_BasicInput, {
                    mask: "##:##:##",
                    input: _ctx.total,
                    "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.total) = $event)),
                    "read-only": true
                  }, null, 8, ["input"])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 32)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BasicButton, {
        class: "mutate-button",
        type: "submit",
        form: "mutate-form"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("timers.editModal.buttons.update")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}