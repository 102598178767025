import { Project, ProjectPaginatedResponse } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { GET_ACTIVE_PROJECTS } from "@/graphql/project/queries";
import { namedEntityComparator } from "./utils";

const ProjectsCacheInfoHolder: PaginatedCacheInfoHolder<
  Project,
  ProjectPaginatedResponse
> = {
  query: GET_ACTIVE_PROJECTS,

  cachedPaginatedResult: (
    cachedResponse: ProjectPaginatedResponse | null,
  ): PaginatedResult<Project> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.projects;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<Project>,
  ): ProjectPaginatedResponse => ({
    projects: paginatedResult,
  }),

  comparator: namedEntityComparator,
};

export default ProjectsCacheInfoHolder;
