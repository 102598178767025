
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import HeaderBlock from "@/components/HeaderBlock.vue";
import PaginatedEntitiesList from "@/components/list/PaginatedEntitiesList.vue";
import AddClient from "../../components/CRUXEntity/AddClient.vue";
import BasicButton from "../../components/BasicButton.vue";

import { GET_CLIENTS } from "@/graphql/client/queries";

import enUK from "@/locales/client/en-UK.json";
import { Client } from "@/helpers/client/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    return {
      t,
    };
  },
  data() {
    return {
      enUK,
      GET_CLIENTS,
      isModalVisible: false,
    };
  },
  methods: {
    showModal(): void {
      this.isModalVisible = true;
    },
    closeModal(): void {
      this.isModalVisible = false;
    },
    viewEntity(client: Client): void {
      this.$router.push({ name: "ClientProjects", params: { id: client.id } });
    },
  },
  components: {
    HeaderBlock,
    PaginatedEntitiesList,
    AddClient,
    BasicButton,
  },
  provide: {
    columnsOrder: {
      name: null,
    },
  },
});
