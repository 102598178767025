
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";

import { GET_ORDER } from "@/graphql/order/queries";
import {
  APPROVE_ORDER,
  CLOSE_ORDER,
  DELETE_ORDER,
  SEND_ORDER,
  UPDATE_ORDER,
} from "@/graphql/order/mutations";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import OrderLine from "@/components/order/OrderLine.vue";
import BasicButton from "@/components/BasicButton.vue";

import enUK from "@/locales/project/en-UK.json";
import { Order } from "@/helpers/order/typesDefinition";
import {
  getMonthOptions,
  getYearOptions,
  startOfMonthDate,
} from "@/helpers/dates";
import { formatPrice } from "@/helpers/utils";
import {
  isSingleMonth,
  paymentOptions,
  transformOrderLineToDisplay,
  transformUnitIfQuantityNotInteger,
} from "@/helpers/order/utils";
import { updateOrdersCache } from "@/helpers/order/updateCache";
import { removeEntity } from "@/helpers/cache/removeEntity";
import dayjs from "dayjs";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import useEditableOrder from "@/composables/useEditableOrder";
import {
  GET_FULL_PROJECT,
  GET_PROJECT_CLIENT_WAITING_ACTIONS,
} from "@/graphql/project/queries";
import { DatePickerMarker } from "@vuepic/vue-datepicker";
import { GET_ALL_CLIENT_WAITING_ACTIONS } from "@/graphql/client/queries";

type MessageSchema = typeof enUK;

export default defineComponent({
  computed: {
    paymentOptions() {
      return paymentOptions;
    },
  },
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const months = getMonthOptions();
    const years = getYearOptions();
    const legalNoticeAgreement = ref(false);
    const showApproveValidation = ref(false);
    const isSingleMonthOrder = ref(false);
    const formattedEndDate = ref();
    const canEdit = ref(false);
    const canDelete = ref(false);
    const canChangeDates = ref(false);
    const canChangePaymentMethod = ref(false);

    const { result: projectResult } = useQuery(GET_FULL_PROJECT, () => ({
      id: props.projectId,
    }));

    const project = computed(() => projectResult.value?.project);

    const { result, loading, onError, onResult, refetch } = useQuery(
      GET_ORDER,
      () => ({
        id: props.id,
      }),
    );

    const order = useResult(result, null, (data) => {
      const order = data.order as Order;
      const orderLines = order.orderLines;
      return {
        ...order,
        orderLines: orderLines.map(transformOrderLineToDisplay),
      };
    });

    const validationRules = computed(() => ({
      title: {
        required,
      },
      startYear: {
        required,
      },
      startMonth: {
        required,
      },
      endYear: {
        required,
      },
      endMonth: {
        required,
      },
      notes: {},
      paymentOption: {
        required,
      },
      companyId: {},
    }));

    const orderForm = ref();
    const orderLines = ref();
    const showDetailsOnInvoice = ref(true);

    const v$ = useVuelidate(validationRules, orderForm, {
      $autoDirty: true,
    });

    onResult((result) => {
      const resOrder = result.data.order;
      const { order: editableOrder, orderLines: editableOrderLines } =
        useEditableOrder(resOrder);
      showDetailsOnInvoice.value = resOrder.showDetailsOnInvoice;
      orderForm.value = editableOrder;
      orderLines.value = editableOrderLines;
      isSingleMonthOrder.value = isSingleMonth(resOrder);
      canEdit.value =
        !props.readOnly && props.hasAdminAccess && !resOrder.invoiced;
      canDelete.value =
        canEdit.value &&
        (["draft", "ready", "to_validate", "late_approval"].includes(
          resOrder.status,
        ) ||
          dayjs(resOrder.startDate).isAfter(dayjs()));

      canChangeDates.value =
        !props.readOnly &&
        props.hasAdminAccess &&
        ![
          "delta_draft",
          "delta_invoiced",
          "delta_reminder_1",
          "delta_reminder_2",
          "delta_reminder_3",
          "closed",
          "late_payment",
        ].includes(resOrder.status);
      canChangePaymentMethod.value =
        canEdit.value &&
        (["draft", "to_validate", "late_approval"].includes(resOrder.status) ||
          dayjs(resOrder.startDate).subtract(16, "days").isAfter(new Date()));
      if (resOrder.endDate) {
        formattedEndDate.value = dayjs(resOrder.endDate)
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.order.get"));
    });

    const {
      mutate: approveOrderMutation,
      onDone: onApprovalDone,
      onError: onApprovalError,
      loading: loadingApproval,
    } = useMutation(APPROVE_ORDER, () => ({
      variables: {
        id: props.id,
        legalNoticeAgreement: legalNoticeAgreement.value,
      },
      refetchQueries: [
        {
          query: GET_PROJECT_CLIENT_WAITING_ACTIONS,
          variables: {
            projectId: props.projectId,
          },
        },
        {
          query: GET_ALL_CLIENT_WAITING_ACTIONS,
        },
      ],
    }));

    onApprovalDone((result) => {
      if (result.data?.approveOrder?.status === "ok") {
        toast.success(t("success.order.approve"));
        context.emit("close");
        refetch();
      } else {
        const errorResponse = result.data?.approveOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.approve", { message: errorResponse }));
      }
    });

    onApprovalError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.order.approve", { message: t("errors.internal") }));
    });

    async function approveOrder() {
      if (order.value?.isFirstOrder && !legalNoticeAgreement.value) {
        showApproveValidation.value = true;
        return;
      }
      return approveOrderMutation();
    }

    const {
      mutate: sendOrder,
      onDone: onDoneSendOrder,
      onError: onErrorSendOrder,
      loading: loadingSendOrder,
    } = useMutation(SEND_ORDER, () => ({
      variables: {
        id: props.id,
      },
    }));

    onDoneSendOrder(async (result) => {
      if (result.data?.sendOrder?.status === "ok") {
        toast.success(t("success.order.send"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.sendOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.send", { message: errorResponse }));
      }
    });

    onErrorSendOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.order.send", { message: "Error with graphql query" }),
      );
    });

    const {
      mutate: deleteOrder,
      onDone: onDoneDeleteOrder,
      onError: onErrorDeleteOrder,
      loading: loadingDeleteOrder,
    } = useMutation(DELETE_ORDER, () => ({
      variables: {
        id: props.id,
      },
      update: (cache, { data }) => {
        const deleteOrderResponse = data?.deleteOrder;
        if (deleteOrderResponse?.status === "ok") {
          updateOrdersCache(
            cache,
            props.projectId,
            order.value?.startDate,
            deleteOrderResponse.data,
            removeEntity,
            -1,
          );
        }
      },
    }));

    onDoneDeleteOrder(async (result) => {
      if (result.data?.deleteOrder?.status === "ok") {
        toast.success(t("success.order.delete"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.deleteOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.delete", { message: errorResponse }));
      }
    });

    onErrorDeleteOrder((error) => {
      logErrorMessages(error);
      toast.error(
        t("errors.order.delete", { message: "Error with graphql query" }),
      );
    });

    const {
      mutate: updateOrder,
      onDone: onUpdateDone,
      onError: onUpdateError,
      loading: loadingUpdateOrder,
    } = useMutation(UPDATE_ORDER, () => {
      const updatedOrder = orderForm.value;
      const endDate = dayjs({
        year: orderForm.value.endYear,
        month: orderForm.value.endMonth - 1,
      }).add(1, "month");

      const orderLineUpdates =
        order.value?.orderLines?.map((ol, idx) => {
          const { quantity, unit } = transformUnitIfQuantityNotInteger(
            orderLines.value[idx].quantity,
            orderLines.value[idx].unit,
          );
          return {
            id: ol.id,
            quantity,
            unit,
          };
        }) || [];

      const datesUpdate = {
        startDate: startOfMonthDate(
          updatedOrder.startYear,
          updatedOrder.startMonth,
        ),
        endDate: startOfMonthDate(endDate.year(), endDate.month() + 1),
      };

      if (!canEdit.value && canChangeDates.value) {
        return {
          variables: {
            id: props.id,
            ...datesUpdate,
          },
        };
      }

      return {
        variables: {
          id: props.id,
          ...datesUpdate,
          dueDate: updatedOrder.dueDate,
          title: updatedOrder.title,
          notes: updatedOrder.notes,
          legalNotice: updatedOrder.legalNotice,
          price: totalPrice.value,
          companyId: updatedOrder.companyId,
          orderLines: orderLineUpdates,
          showDetailsOnInvoice: showDetailsOnInvoice.value,
          paymentOption: updatedOrder.paymentOption,
        },
      };
    });

    onUpdateDone((result) => {
      if (result.data?.updateOrder?.status === "ok") {
        toast.success(t("success.order.update"));
        context.emit("close");
        refetch();
      } else {
        const errorResponse = result.data?.updateOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.update", { message: errorResponse }));
      }
    });

    onUpdateError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.order.update", { message: t("errors.internal") }));
    });

    const {
      mutate: closeOrder,
      onDone: onClosingDone,
      onError: onClosingError,
      loading: loadingClosing,
    } = useMutation(CLOSE_ORDER, () => ({
      variables: {
        id: props.id,
        legalNoticeAgreement: legalNoticeAgreement.value,
      },
    }));

    onClosingDone((result) => {
      if (result.data?.closeOrder?.status === "ok") {
        toast.success(t("success.order.close"));
        context.emit("close");
        refetch();
      } else {
        const errorResponse = result.data?.closeOrder?.error;
        console.log(errorResponse);
        toast.error(t("errors.order.close", { message: errorResponse }));
      }
    });

    onClosingError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.order.close", { message: t("errors.internal") }));
    });

    const totalPrice = ref(0);

    watchEffect(() => {
      totalPrice.value = orderLines.value?.reduce(
        (partial_sum: number, line: { subtotal: number }) =>
          partial_sum + Math.round(line.subtotal),
        0,
      );
    });

    const dueDateMarkers = computed<DatePickerMarker[]>(() =>
      order.value
        ? [
            {
              date: dayjs(order.value.endDate).subtract(5, "days").toDate(),
              type: "line",
              tooltip: [{ text: t("labels.defaultDueDate"), color: "blue" }],
            },
          ]
        : [],
    );

    return {
      result,
      order,
      months,
      loading,
      loadingApproval,
      loadingSendOrder,
      loadingDeleteOrder,
      loadingUpdateOrder,
      approveOrder,
      sendOrder,
      deleteOrder,
      updateOrder,
      formatPrice,
      t,
      legalNoticeAgreement,
      showApproveValidation,
      isSingleMonthOrder,
      formattedEndDate,
      canEdit,
      canChangeDates,
      canDelete,
      v$,
      orderForm,
      years,
      project,
      totalPrice,
      orderLines,
      showDetailsOnInvoice,
      canChangePaymentMethod,
      closeOrder,
      loadingClosing,
      dueDateMarkers,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    hasAdminAccess: {
      type: Boolean,
      required: true,
    },
    hasClientAccess: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    Modal,
    FormItem,
    OrderLine,
    BasicButton,
  },
});
