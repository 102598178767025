import gql from "graphql-tag";

export const SYNC_DATA = gql`
  mutation syncData {
    syncData {
      status
      error
    }
  }
`;
