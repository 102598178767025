import { Client, ClientPaginatedResponse } from "./typesDefinition";
import {
  PaginatedCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { GET_CLIENTS } from "@/graphql/client/queries";
import { clientComparator } from "./utils";

const ClientsCacheInfoHolder: PaginatedCacheInfoHolder<
  Client,
  ClientPaginatedResponse
> = {
  query: GET_CLIENTS,

  cachedPaginatedResult: (
    cachedResponse: ClientPaginatedResponse | null,
  ): PaginatedResult<Client> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    return cachedResponse.clients;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<Client>,
  ): ClientPaginatedResponse => ({
    clients: paginatedResult,
  }),

  comparator: clientComparator,
};

export default ClientsCacheInfoHolder;
