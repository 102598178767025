import gql from "graphql-tag";
import { activityFragment, attachmentFragment } from "./fragments";
import { taskFragment } from "../tasks/fragments";
import { commentFragment } from "../comments/fragments";

export const GET_ACTIVITY = gql`
  query getActivity($id: ID!) {
    activity(id: $id) {
      ...ActivityFragment
      tasks {
        ...TaskFragment
      }
      comments {
        ...CommentFragment
      }
      attachments {
        ...AttachmentFragment
      }
    }
  }
  ${activityFragment}
  ${taskFragment}
  ${commentFragment}
  ${attachmentFragment}
`;

export const GET_BASIC_ACTIVITIES = gql`
  query getBasicActivities(
    $searchTerm: String
    $projectId: [ID]
    $typeId: [ID]
    $assigneeId: [ID]
    $statusId: [ID]
    $includesInactive: Boolean
    $onlyImportant: Boolean
  ) {
    activities(
      searchTerm: $searchTerm
      projectId: $projectId
      typeId: $typeId
      assigneeId: $assigneeId
      statusId: $statusId
      includesInactive: $includesInactive
      onlyImportant: $onlyImportant
    ) {
      id
      rank
      name
      description
      estimation
      active
      important
      type {
        id
        name
      }
      assignee {
        id
        firstName
        lastName
        avatarUrl
      }
      status {
        id
        rank
        name
      }
      deadline
      tasks {
        id
        isDone
      }
      milestone {
        id
        name
        phase {
          id
          name
        }
      }
      createdAt
      updatedAt
      deletedAt
      private
      createdBy {
        id
        firstName
        lastName
        avatarUrl
      }
      project {
        id
        name
        color
      }
    }
  }
`;

export const GET_FULL_ACTIVITIES = gql`
  query getFullActivities(
    $searchTerm: String
    $projectId: [ID]
    $typeId: [ID]
    $assigneeId: [ID]
    $statusId: [ID]
    $includesInactive: Boolean
    $onlyImportant: Boolean
  ) {
    activities(
      searchTerm: $searchTerm
      projectId: $projectId
      typeId: $typeId
      assigneeId: $assigneeId
      statusId: $statusId
      includesInactive: $includesInactive
      onlyImportant: $onlyImportant
    ) {
      ...ActivityFragment
      tasks {
        ...TaskFragment
      }
      comments {
        ...CommentFragment
      }
      attachments {
        ...AttachmentFragment
      }
    }
  }
  ${activityFragment}
  ${taskFragment}
  ${commentFragment}
  ${attachmentFragment}
`;

export const GET_ACTIVITY_STATUSES = gql`
  query GetActivityStatuses {
    activityStatuses {
      id
      name
    }
  }
`;
