
import { computed, defineComponent, PropType } from "vue";
import { ApolloCache } from "@apollo/client";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/client/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { UPDATE_COMPANY } from "@/graphql/client/mutations";

import { updateQueryCache } from "@/helpers/cache/cache";
import { updateNonPaginatedCachedEntity } from "@/helpers/cache/updateCachedEntity";
import ClientCompaniesCacheInfoHolder from "@/helpers/client/ClientCompaniesCacheInfoHolder";

import { ClientResponse, Company } from "@/helpers/client/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    function updateCompany(
      cache: ApolloCache<ClientResponse>,
      mutatedItem: Company,
    ) {
      return updateQueryCache(
        cache,
        mutatedItem,
        ClientCompaniesCacheInfoHolder,
        updateNonPaginatedCachedEntity,
        props.clientId,
      );
    }

    return { t, updateCompany };
  },
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      enUK,
      UPDATE_COMPANY,
      validationRules: computed(() => ({
        id: {},
        name: {},
        vatNumber: {},
        vatExempted: {},
      })),
      messages: {
        formButtonLabel: this.t("buttons.company.edit"),
        error: this.t("errors.company.edit"),
        success: this.t("success.company.edit"),
        title: this.t("titles.company.edit"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
