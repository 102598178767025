
import { defineComponent, reactive, Ref, ref, watchEffect } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { logErrorMessages } from "@vue/apollo-util";
import Fuse from "fuse.js";

import { GET_TIMERS, GET_THEMES } from "@/graphql/timesheets/queries";
import {
  CREATE_TIMER,
  DELETE_TIMER,
  RESTART_TIMER,
  STOP_TIMER,
  SYNC_DAILY_TIMESHEETS,
} from "@/graphql/timesheets/mutations";

import enUK from "@/locales/home/en-UK.json";
import { Theme } from "@/helpers/project/typesDefinition";
import EntitiesList from "@/components/list/EntitiesList.vue";
import dayjs from "dayjs";
import IconButton from "@/components/IconButton.vue";
import TimeBlock from "@/components/TimeBlock.vue";
import { Timer, Timesheet } from "@/helpers/client/typesDefinition";
import EditTimesheet from "@/components/CRUXEntity/EditTimesheet.vue";
import usePaginatedQuery from "@/composables/usePaginatedQuery";
import BasicInput from "@/components/form/BasicInput.vue";
import EditTimer from "@/components/CRUXEntity/EditTimer.vue";
import { formatDuration } from "@/helpers/dates";

type MessageSchema = typeof enUK;

interface ExtendedTimer extends Omit<Timer, "id" | "__typename"> {
  id?: string;
  intervalNb?: number;
  running: boolean;
  displayedValue: string;
}

const getDisplayedValue = (
  timer: Omit<ExtendedTimer, "displayedValue" | "intervalNb">,
): string => {
  const elapsedTimer = elapsedTime(timer);
  return formatDuration(elapsedTimer);
};

const extendTimer = (timer: Timer): ExtendedTimer => {
  let running = false;
  if (timer.periods.length) {
    const lastPeriod = timer.periods[timer.periods.length - 1];
    running = !lastPeriod.stopTime;
  }
  const extendedTimer = {
    ...timer,
    running,
    intervalNb: ref(),
  };
  const displayedValue = getDisplayedValue(extendedTimer);
  return reactive({ ...extendedTimer, displayedValue });
};
const elapsedTime = (timer: Omit<ExtendedTimer, "displayedValue">) =>
  timer.periods.reduce(
    (acc, period) => acc + (period.duration ?? dayjs().diff(period.startTime)),
    0,
  );

const startInterval = (timer: ExtendedTimer) => {
  const runTimer = () => {
    timer.displayedValue = getDisplayedValue(timer);
  };
  timer.intervalNb = setInterval(runTimer, 1000);
};

export default defineComponent({
  data() {
    return {
      isEditTimesheetModalVisible: false,
      isEditTimerModalVisible: false,
    };
  },
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();

    const currentTimer = ref<number | null>(null);

    const themesFuseOptions = {
      includeScore: true,
      threshold: 0.2,
      keys: ["name", "project.name"],
    };

    const themeFuse = ref(new Fuse<Theme>([], themesFuseOptions));
    const {
      paginatedResult: themesResult,
      loading: themesLoading,
      onResult: onThemesResult,
    } = usePaginatedQuery(GET_THEMES, t("errors.getThemes"));
    const allThemes = ref();
    const themes = ref();

    onThemesResult(() => {
      allThemes.value = themesResult.value.items;
      themes.value = allThemes.value;
      watchEffect(() => themeFuse.value.setCollection(allThemes.value));
    });

    const {
      mutate: syncDailyTimesheets,
      loading: timesheetsLoading,
      onError: onTimesheetsError,
      onDone: onTimesheetsResult,
    } = useMutation(SYNC_DAILY_TIMESHEETS);
    const timesheets = ref();

    syncDailyTimesheets();

    onTimesheetsResult((res) => {
      timesheets.value = res.data.syncDailyTimesheets.data;
    });
    onTimesheetsError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.getTimesheets", { message: error }));
    });

    const {
      loading: timersLoading,
      onError: onTimersError,
      onResult: onTimersResult,
    } = useQuery<{ timers: Timer[] }>(GET_TIMERS);
    const timers = ref<ExtendedTimer[]>([]);

    onTimersResult((res) => {
      timers.value = res.data.timers.map((t) => {
        const extendedTimer = extendTimer(t);
        if (extendedTimer.running) startInterval(extendedTimer);
        return reactive(extendedTimer);
      });
      const runningTimerIndex = timers.value.findIndex((t) => t.running);
      if (runningTimerIndex >= 0) {
        currentTimer.value = runningTimerIndex;
      }
    });
    onTimersError((error) => {
      logErrorMessages(error);
      toast.error(t("getTimersError"));
    });

    const timesheetIdxToEdit = ref() as Ref<number>;
    const timerIdxToEdit = ref() as Ref<number>;

    const mutationsOptions = { refetchQueries: [{ query: GET_TIMERS }] };

    const { mutate: createTimerMutation, onDone: onTimerCreated } = useMutation(
      CREATE_TIMER,
      mutationsOptions,
    );
    const { mutate: stopTimerMutation } = useMutation(
      STOP_TIMER,
      mutationsOptions,
    );
    const { mutate: restartTimerMutation } = useMutation(
      RESTART_TIMER,
      mutationsOptions,
    );
    const { mutate: deleteTimerMutation } = useMutation(
      DELETE_TIMER,
      mutationsOptions,
    );

    onTimerCreated((result) => {
      if (result.data?.createTimer?.status !== "ok") {
        const errorResponse = result.data?.createTimer?.error;
        console.log(errorResponse);
        toast.error(t("errors.createTimer", { message: errorResponse }));
      }
    });

    const themeFilter = ref("");

    return {
      allThemes,
      themes,
      timersLoading,
      themesLoading,
      timesheetsLoading,
      t,
      timers,
      createTimerMutation,
      stopTimerMutation,
      restartTimerMutation,
      deleteTimerMutation,
      currentTimer,
      timesheets,
      timesheetIdxToEdit,
      timerIdxToEdit,
      themeFilter,
      themeFuse,
    };
  },
  provide: {
    columnsOrder: {
      project: null,
      name: null,
    },
  },
  methods: {
    async stopTimer(idx: number) {
      const timer = this.timers[idx];
      const stopTime = new Date();
      await this.stopTimerMutation({ id: timer?.id, stopTime });
      timer.running = false;
      if (timer.intervalNb) {
        clearInterval(timer.intervalNb);
        timer.intervalNb = undefined;
      }
      this.currentTimer = null;
    },
    openEditTimesheetModal(idx: number): void {
      this.timesheetIdxToEdit = idx;
      this.isEditTimesheetModalVisible = true;
    },
    closeEditTimesheetModal(): void {
      this.isEditTimesheetModalVisible = false;
    },
    removeTimesheet(idx: number): void {
      this.timesheets = this.timesheets
        .slice(0, idx)
        .concat(this.timesheets.slice(idx + 1, this.timesheets.length));
    },
    openEditTimerModal(idx: number): void {
      this.timerIdxToEdit = idx;
      this.isEditTimerModalVisible = true;
    },
    closeEditTimerModal(): void {
      this.isEditTimerModalVisible = false;
    },
    async restartTimer(idx: number) {
      const timer = this.timers[idx];
      if (timer.running) return;
      if (this.currentTimer !== null) {
        await this.stopTimer(this.currentTimer);
      }
      const startTime = new Date();

      if (timer.periods.length === 0) {
        const createResult = await this.createTimerMutation({
          startTime,
          themeId: timer.theme?.id,
        });
        if (createResult?.data.createTimer.data.id) {
          timer.id = createResult.data.createTimer.data.id;
        }
      } else {
        await this.restartTimerMutation({
          id: timer.id,
          restartTime: startTime,
        });
      }
      timer.periods = [
        ...timer.periods,
        {
          __typename: "TimePeriod",
          id: "dummy",
          startTime,
        },
      ];

      startInterval(timer);
      timer.running = true;
      this.currentTimer = idx;
    },
    async addTimerForTheme(theme: Theme, startTimer = true) {
      if (!this.themes) return;
      this.allThemes = this.allThemes.filter((t: Theme) => t.id !== theme.id);
      this.themeFilter = "";
      this.filterThemes(this.themeFilter);
      const timer: ExtendedTimer = {
        periods: [],
        running: false,
        displayedValue: "00:00:00",
        date: new Date(),
        theme,
      };
      const idx = this.timers.length;
      this.timers.push(timer);
      if (startTimer) {
        await this.restartTimer(idx);
      }
    },
    async createTimesheet(theme: Theme, timerIdx: number) {
      const timer = this.timers[timerIdx];
      const elapsedMs = elapsedTime(timer);
      const nb15Min = Math.ceil(elapsedMs / (1000 * 60 * 15));
      const duration = nb15Min * 15;
      const timesheetIdx = this.timesheets.length;
      this.timesheets.push({ theme, duration });
      await this.deleteTimerMutation({ id: timer.id });
      this.timers = this.timers
        .slice(0, timerIdx)
        .concat(this.timers.slice(timerIdx + 1, this.timers.length));
      this.allThemes = [theme, ...this.allThemes];
      this.filterThemes(this.themeFilter);
      this.openEditTimesheetModal(timesheetIdx);
    },
    totalTime(timesheets: Timesheet[]) {
      const totalDuration = timesheets.reduce(
        (acc, ts) => acc + ts.duration,
        0,
      );
      return `${Math.floor(totalDuration / 60)}h${String(
        totalDuration % 60,
      ).padStart(2, "0")}`;
    },
    filterThemes(filter: string) {
      if (filter.length === 0) {
        this.themes = this.allThemes;
        return;
      }
      const searchResult = this.themeFuse.search(filter);
      this.themes = searchResult.map((res) => res.item);
    },
  },
  components: {
    BasicInput,
    TimeBlock,
    IconButton,
    EntitiesList,
    EditTimesheet,
    EditTimer,
  },
});
