import { computed, toRef, type MaybeRefOrGetter } from "vue";
import {
  type ProjectWithBudgetAndThemes,
  type Theme,
} from "@/helpers/project/typesDefinition";

export default function useProjectBudget(
  project?: MaybeRefOrGetter<ProjectWithBudgetAndThemes>,
  themes?: MaybeRefOrGetter<Theme[]>,
) {
  const projectRef = toRef(project);
  const themesRef = toRef(themes);
  const budget = computed(() => projectRef.value?.budget);

  const totalAmount = computed(() => {
    return budget.value?.amount;
  });
  const allocatedAmount = computed(() => {
    return (
      themesRef.value
        // mapping first then reducing since TS can't properly infer accumulator type when doing it all in once
        ?.map((theme) => {
          return (
            budget.value?.themes.find((t) => t.theme.id === theme.id)?.amount ??
            0
          );
        })
        .reduce((acc, themeBudget) => {
          return themeBudget + acc;
        }, 0)
    );
  });

  const invoicedAmount = computed(() => {
    return projectRef.value?.totalBilledNet;
  });

  const performedAmount = computed(() => {
    return projectRef.value?.performedAmount;
  });

  const remainingAmount = computed(() => {
    const invoiced = invoicedAmount.value;
    const total = totalAmount.value;
    if (invoiced == undefined || total == undefined) return;
    return total - invoiced;
  });
  const invoicedPercentage = computed(() => {
    const invoiced = invoicedAmount.value;
    const total = totalAmount.value;
    if (invoiced == undefined || total == undefined) return;
    return invoiced / total;
  });

  const remainingToAllocateAmount = computed(() => {
    if (totalAmount.value == undefined) return;
    return totalAmount.value - (allocatedAmount.value || 0);
  });

  const approvedAt = computed(() => budget.value?.approvedAt);

  const allocatedPercentage = computed(() => {
    const allocated = allocatedAmount.value;
    const total = totalAmount.value;
    if (allocated == undefined || total == undefined) return;
    return allocated / total;
  });
  return {
    budget,
    totalAmount,
    allocatedAmount,
    remainingAmount,
    remainingToAllocateAmount,
    approvedAt,
    allocatedPercentage,
    invoicedAmount,
    invoicedPercentage,
    performedAmount,
  };
}
