
import { defineComponent, PropType, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/home/en-UK.json";

import { Timesheet } from "@/helpers/client/typesDefinition";
import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";
import BasicInput from "@/components/form/BasicInput.vue";
import useVuelidate from "@vuelidate/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { GET_EMPLOYEE_ROLES } from "@/graphql/project/queries";
import { ProjectRole } from "@/helpers/project/typesDefinition";
import { useToast } from "vue-toastification";
import {
  CREATE_TIMESHEET,
  DELETE_TIMESHEET,
  UPDATE_TIMESHEET,
} from "@/graphql/timesheets/mutations";
import dayjs from "dayjs";
import { minValue, required } from "@vuelidate/validators";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });
    const toast = useToast();

    const variables: Ref<{
      duration: number;
      notes: string | undefined;
      role: string | undefined;
    }> = ref({
      duration: props.timesheet.duration,
      notes: props.timesheet.notes,
      role: props.timesheet.role?.id,
    });

    const roles = ref();

    const {
      loading: rolesLoading,
      onError: onRolesError,
      onResult: onRolesResult,
    } = useQuery<{ employeeRoles: ProjectRole[] }>(GET_EMPLOYEE_ROLES, {
      projectId: props.timesheet.theme.project.id,
    });
    onRolesError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.getTimersError", { message: error }));
    });
    onRolesResult((res) => {
      roles.value = res.data.employeeRoles;
      if (!variables.value.role && res.data.employeeRoles.length === 1) {
        variables.value.role = res.data.employeeRoles[0].id;
      }
    });

    const v$ = useVuelidate(
      {
        duration: { required, minValue: minValue(1) },
        notes: {},
        role: { required },
      },
      variables,
      {
        $autoDirty: true,
      },
    );

    const {
      mutate: createMutation,
      onDone: onTimesheetCreated,
      loading: creationLoading,
    } = useMutation(CREATE_TIMESHEET);

    onTimesheetCreated((res) => {
      const newTimesheet = res.data.createTimesheet.data;
      context.emit("update:timesheet", newTimesheet);
      context.emit("close");
    });

    const {
      mutate: updateMutation,
      onDone: onTimesheetUpdated,
      loading: updateLoading,
    } = useMutation(UPDATE_TIMESHEET);

    onTimesheetUpdated((res) => {
      const updatedTimesheet = res.data.updateTimesheet.data;
      context.emit("update:timesheet", updatedTimesheet);
      context.emit("close");
    });

    const {
      mutate: deleteMutation,
      onDone: onTimesheetDeleted,
      loading: deleteLoading,
    } = useMutation(DELETE_TIMESHEET);

    onTimesheetDeleted(() => {
      context.emit("delete");
      context.emit("close");
    });

    return {
      t,
      v$,
      projectName: props.timesheet.theme.project.name,
      themeName: props.timesheet.theme.name,
      roles,
      variables,
      rolesLoading,
      creationLoading,
      updateLoading,
      deleteLoading,
      createMutation,
      updateMutation,
      deleteMutation,
    };
  },
  props: {
    timesheet: {
      type: Object as PropType<Timesheet>,
      required: true,
    },
  },
  data() {
    return {
      enUK,
    };
  },
  emits: ["close", "update:timesheet", "delete"],
  methods: {
    closeModal(): void {
      if (!this.timesheet.id) {
        this.$emit("delete");
      }
      this.$emit("close");
    },
    async deleteTimesheet() {
      await this.deleteMutation({ id: this.timesheet.id });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      console.log({ isFormCorrect, v$: this.v$ });
      if (isFormCorrect) {
        const updatedVariables = {
          notes: this.variables.notes,
          duration: Number(this.variables.duration),
        };
        if (this.timesheet.id) {
          await this.updateMutation({
            ...updatedVariables,
            id: this.timesheet.id,
          });
        } else {
          const variables = {
            ...updatedVariables,
            date: dayjs().format("YYYY-MM-DD"),
            roleId: this.variables.role,
            themeId: this.timesheet.theme.id,
            projectId: this.timesheet.theme.project.id,
          };
          await this.createMutation(variables);
        }
      }
    },
  },
  components: {
    BasicInput,
    BasicButton,
    Modal,
    FormItem,
  },
});
