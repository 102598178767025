import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73086b48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-line-label role" }
const _hoisted_2 = { class: "quantity" }
const _hoisted_3 = {
  key: 0,
  class: "multiselect-custom-caret"
}
const _hoisted_4 = {
  key: 1,
  class: "multiselect-custom-caret"
}
const _hoisted_5 = { class: "order-line-label hourly-rate" }
const _hoisted_6 = { class: "order-line-label subtotal" }
const _hoisted_7 = { class: "order-line-validation-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("li", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.projectRoleName), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BasicInput, {
          input: _ctx.quantity,
          class: "order-quantity",
          inputId: "order-quantity",
          readOnly: _ctx.readOnly,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:quantity', $event.target?.valueAsNumber)))
        }, null, 8, ["input", "readOnly"]),
        _createVNode(_component_Multiselect, {
          class: "order-time-unit",
          id: "multiselect",
          modelValue: _ctx.modelUnit,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelUnit) = $event)),
          options: _ctx.unitOptions,
          closeOnSelect: true,
          canClear: false,
          disabled: _ctx.readOnly
        }, {
          caret: _withCtx(() => [
            (_ctx.readOnly)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, " > "))
          ]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formatPrice(_ctx.hourlyRate)), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.formatPrice(_ctx.computedSubtotal)), 1)
    ]),
    _createElementVNode("li", _hoisted_7, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.$errors, (error) => {
          return (_openBlock(), _createElementBlock("li", {
            key: error.$uid
          }, _toDisplayString(error.$property) + " : " + _toDisplayString(error.$message), 1))
        }), 128))
      ])
    ])
  ], 64))
}