import {
  EntityPaginatedPropCacheInfoHolder,
  PaginatedResult,
} from "@/helpers/typesDefinition";
import { GET_CLIENT_USERS } from "@/graphql/client/queries";
import { userComparator } from "../user/utils";
import { UserResponse } from "../user/typesDefinition";
import { Client, ClientResponse } from "./typesDefinition";

const UsersCacheInfoHolder: EntityPaginatedPropCacheInfoHolder<
  UserResponse,
  ClientResponse,
  Omit<Client, "projects" | "companies">
> = {
  query: GET_CLIENT_USERS,

  cachedPaginatedResult: (
    cachedResponse: ClientResponse | null,
  ): PaginatedResult<UserResponse> => {
    if (!cachedResponse) {
      return { hasMore: true, items: [] };
    }
    const accessRights = cachedResponse.client.accessRights;
    if (!accessRights) {
      return { hasMore: true, items: [] };
    }
    return accessRights;
  },

  cachedEntity: (
    cachedResponse: ClientResponse | null,
    id: string,
  ): Omit<Client, "projects" | "companies"> => {
    if (!cachedResponse)
      return {
        id,
        __typename: "Client",
      };
    return cachedResponse?.client;
  },

  cachedResponse: (
    paginatedResult: PaginatedResult<UserResponse>,
    entity: Client,
  ): ClientResponse => ({
    client: {
      ...entity,
      accessRights: paginatedResult,
    },
  }),

  comparator: userComparator,
};

export default UsersCacheInfoHolder;
