import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-033b7b7f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "invoices-menu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "menuDate" }
const _hoisted_5 = { class: "ordered" }
const _hoisted_6 = { class: "prices" }
const _hoisted_7 = { class: "paid" }
const _hoisted_8 = { class: "prices" }
const _hoisted_9 = { class: "to-be-paid" }
const _hoisted_10 = { class: "prices" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "bottom-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_OrderAndDeltaList = _resolveComponent("OrderAndDeltaList")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_AddOrder = _resolveComponent("AddOrder")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoicesMenuItems, (invoicesMenuItem, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("li", {
            class: "menu-item",
            onClick: ($event: any) => (_ctx.openMenu(index))
          }, [
            _createVNode(_component_Icon, {
              icon: "entypo:triangle-right",
              rotate: _ctx.isOpened[index] ? 1 : 0,
              class: "icon-invoices-menu"
            }, null, 8, ["rotate"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatDate(invoicesMenuItem.menuDate)), 1),
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.t("labels.ordered")) + " ", 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatPrice(invoicesMenuItem.menuOrdered)), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.t("labels.paid")) + " ", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatPrice(
                invoicesMenuItem.menuTotal - invoicesMenuItem.menuBalance,
              )), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.t("labels.toBePaid")) + " ", 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formatPrice(invoicesMenuItem.menuBalance)), 1)
            ])
          ], 8, _hoisted_3),
          (_ctx.isOpened[index])
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                _createVNode(_component_OrderAndDeltaList, {
                  projectId: _ctx.id,
                  date: invoicesMenuItem.menuDate,
                  onOpenModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openOrderDetailsModal($event)))
                }, null, 8, ["projectId", "date"])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.project && _ctx.project.active && _ctx.hasAdminAccess)
        ? (_openBlock(), _createBlock(_component_BasicButton, {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddOrderModal())),
            class: "add-button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("titles.order.add")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (
      _ctx.project &&
      _ctx.project.active &&
      _ctx.project.client &&
      _ctx.hasAdminAccess &&
      _ctx.isAddOrderModalVisible
    )
      ? (_openBlock(), _createBlock(_component_AddOrder, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeAddOrderModal())),
          project: _ctx.project,
          defaultIsRecurringOrder: _ctx.isCurrentRoute('ProjectRecurringOrders')
        }, null, 8, ["project", "defaultIsRecurringOrder"]))
      : _createCommentVNode("", true),
    (_ctx.isModalDetailsVisible)
      ? (_openBlock(), _createBlock(_component_OrderDetails, {
          key: 2,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeOrderDetailsModal())),
          id: _ctx.modalDetailsId,
          projectId: _ctx.id,
          "has-client-access": _ctx.hasClientAccess,
          hasAdminAccess: _ctx.hasAdminAccess,
          "read-only": _ctx.readOnly
        }, null, 8, ["id", "projectId", "has-client-access", "hasAdminAccess", "read-only"]))
      : _createCommentVNode("", true)
  ], 64))
}