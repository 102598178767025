import { Comparator } from "../typesDefinition";

type BinarySearchArgs = {
  start: number;
  middle: number;
  end: number;
};

function binarySearch<T, U>(
  sortedArray: U[],
  element: T,
  comparator: Comparator<T, U>,
): BinarySearchArgs {
  let start = 0;
  let end = sortedArray.length - 1;
  let middle = 0;
  while (start <= end) {
    middle = (end + start) >> 1;
    const cmp = comparator(element, sortedArray[middle]);
    if (cmp > 0) {
      start = middle + 1;
    } else if (cmp < 0) {
      end = middle - 1;
    } else {
      return { start: middle, middle, end: middle };
    }
  }
  return { start, middle, end };
}

export function getIndexFirstHigherOrEqual<T, U>(
  sortedArray: U[],
  element: T,
  comparator: Comparator<T, U>,
): number {
  const { start, middle, end } = binarySearch(sortedArray, element, comparator);
  if (start >= sortedArray.length || middle >= sortedArray.length)
    return sortedArray.length;
  if (start > middle) return start;
  if (end <= middle) return middle;
  return -1;
}
