import gql from "graphql-tag";
import { projectFragment } from "../project/fragments";

export const GET_EMPLOYEES_LOGGED_TIME = gql`
  query getEmployeesLoggedTime(
    $startDate1: Date!
    $yesterday: Date!
    $today: Date!
    $endDate1: Date!
    $startDate2: Date!
    $endDate2: Date!
    $startDate3: Date!
    $endDate3: Date!
  ) {
    employees {
      id
      email
      firstName
      lastName
      workingHours1: workingHours(startDate: $startDate1, endDate: $endDate1)
      past: loggedHours(
        startDate: $startDate1
        endDate: $yesterday
        withInternal: false
      ) {
        startDate
        endDate
        projectRole {
          id
          project {
            id
            name
          }
          hourlyRate
        }
        loggedHours
      }
      period1: loggedHours(
        startDate: $today
        endDate: $endDate1
        withInternal: false
      ) {
        startDate
        endDate
        projectRole {
          id
          project {
            id
            name
          }
          hourlyRate
        }
        plannedHours
      }
      workingHours2: workingHours(startDate: $startDate2, endDate: $endDate2)
      period2: loggedHours(
        startDate: $startDate2
        endDate: $endDate2
        withInternal: false
      ) {
        startDate
        endDate
        projectRole {
          id
          project {
            id
            name
          }
          hourlyRate
        }
        plannedHours
      }
      workingHours3: workingHours(startDate: $startDate3, endDate: $endDate3)
      period3: loggedHours(
        startDate: $startDate3
        endDate: $endDate3
        withInternal: false
      ) {
        startDate
        endDate
        projectRole {
          id
          project {
            id
            name
          }
          hourlyRate
        }
        plannedHours
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    user {
      id
      email
      firstName
      accessRights {
        ... on ClientAccessRight {
          role
        }
        ... on GlobalAccessRight {
          role
        }
      }
    }
  }
`;

export const GET_ACCESS_RIGHTS = gql`
  query getAccessRights($withInactive: Boolean) {
    user {
      id
      firstName
      accessRights {
        ... on GlobalAccessRight {
          __typename
          role
        }
        ... on ClientAccessRight {
          __typename
          client {
            id
            name
            projects(withInactive: $withInactive) {
              ...ProjectFragment
            }
          }
          role
        }
        ... on ProjectAccessRight {
          __typename
          project {
            id
            name
          }
          role
        }
      }
    }
  }
  ${projectFragment}
`;

export const GET_EMPLOYEES = gql`
  query GetEmployees {
    employees {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`;
