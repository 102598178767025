
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: false,
      default: 1,
      validator: (value: number) => value > 0 && value <= 6,
    },
  },
  setup(props) {
    const tag = computed(() => `h${props.level}`);
    return {
      tag,
    };
  },
});
