import { formatPercentage, formatPrice } from "../utils";
import { Project } from "@/helpers/project/typesDefinition";

export function namedEntityComparator<E extends { name: string }>(
  e1: E,
  e2: E,
): number {
  return e1.name.localeCompare(e2.name);
}

export const getAndFormatProjectRemaining = (project: Project): string => {
  return project.budget
    ? formatPrice(
        project.budget.amount -
          project.totalNotBilledOrdered -
          project.totalBilledNet,
      )
    : "-";
};

export const getAndFormatProjectRemainingPercentage = (
  project: Project,
): string => {
  return project.budget
    ? formatPercentage(
        (project.budget.amount -
          project.totalNotBilledOrdered -
          project.totalBilledNet) /
          project.budget.amount,
      )
    : "-";
};

export const getAndFormatProjectBudgetPercentage = (
  project: Project,
): string => {
  return project.budget
    ? formatPercentage(project.totalBilledNet / project.budget.amount)
    : "-";
};
