
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

import enUK from "@/locales/invoicing/en-UK.json";

import FormItem from "@/components/form/FormItem.vue";
import Modal from "@/components/Modal.vue";
import BasicButton from "@/components/BasicButton.vue";

import { GET_PROJECTS_FOR_COMPANY } from "@/graphql/project/queries";
import { UPDATE_INVOICE_PROJECT } from "@/graphql/invoice/mutations";

import { Invoice } from "@/helpers/invoice/typesDefinition";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import { removePaginatedEntity } from "@/helpers/cache/removeEntity";
import IndependentInvoicesCacheInfoHolder from "@/helpers/invoice/IndependentInvoicesCacheInfoHolder";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },
  setup(props, context) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();

    const {
      result,
      loading: loadingProjects,
      onError: onErrorProjects,
    } = useQuery(GET_PROJECTS_FOR_COMPANY, () => ({
      companyId: props.invoice.company.id,
    }));

    const projects = useResult(
      result,
      null,
      (result) => result?.company?.client?.projects,
    );

    const projectId = ref(props.invoice.project?.id || "");

    onErrorProjects((error) => {
      logErrorMessages(error);
      toast.error(t("errors.getInvoiceProjects"));
    });

    const {
      mutate: editInvoice,
      onDone,
      onError,
      loading,
    } = useMutation(UPDATE_INVOICE_PROJECT, () => ({
      variables: {
        id: props.invoice.id,
        projectId: projectId.value,
      },
      update: (cache, { data }) => {
        const updateInvoiceResponse = data?.updateInvoice;
        if (updateInvoiceResponse?.status === "ok") {
          updatePaginatedQueryCache(
            cache,
            updateInvoiceResponse.data,
            IndependentInvoicesCacheInfoHolder,
            removePaginatedEntity,
          );
        }
      },
    }));

    onDone(async (result) => {
      if (result.data?.updateInvoice?.status === "ok") {
        toast.success(t("success.edit"));
        context.emit("close");
      } else {
        const errorResponse = result.data?.updateInvoice?.error;
        console.log(errorResponse);
        toast.error(t("errors.edit", { message: errorResponse }));
      }
    });

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.edit", { message: t("errors.internal") }));
    });

    return {
      t,
      editInvoice,
      loading,
      projectId,
      loadingProjects,
      projects,
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    Modal,
    FormItem,
    BasicButton,
  },
});
