import gql from "graphql-tag";
import { taskFragment, templateTaskFragment } from "./fragments";

export const CREATE_TEMPLATE_TASK = gql`
  mutation CreateTemplateTask(
    $activityId: ID!
    $name: String!
    $isDone: Boolean
  ) {
    createTemplateTask(activityId: $activityId, name: $name, isDone: $isDone) {
      status
      error
      data {
        ...TemplateTaskFragment
      }
    }
  }
  ${templateTaskFragment}
`;

export const UPDATE_TEMPLATE_TASK = gql`
  mutation UpdateTemplateTask($id: ID!, $name: String, $isDone: Boolean) {
    updateTemplateTask(id: $id, name: $name, isDone: $isDone) {
      status
      error
      data {
        ...TemplateTaskFragment
      }
    }
  }
  ${templateTaskFragment}
`;

export const DELETE_TEMPLATE_TASK = gql`
  mutation DeleteTemplateTask($id: ID!) {
    deleteTemplateTask(id: $id) {
      status
      error
      data {
        ...TemplateTaskFragment
      }
    }
  }
  ${templateTaskFragment}
`;

export const CREATE_TASK = gql`
  mutation CreateTask(
    $activityId: ID!
    $name: String!
    $assigneeId: ID
    $isDone: Boolean
  ) {
    createTask(
      activityId: $activityId
      name: $name
      assigneeId: $assigneeId
      isDone: $isDone
    ) {
      status
      error
      data {
        ...TaskFragment
      }
    }
  }
  ${taskFragment}
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: ID!
    $name: String
    $assigneeId: ID
    $isDone: Boolean
  ) {
    updateTask(id: $id, name: $name, assigneeId: $assigneeId, isDone: $isDone) {
      status
      error
      data {
        ...TaskFragment
      }
    }
  }
  ${taskFragment}
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      status
      error
      data {
        ...TaskFragment
      }
    }
  }
  ${taskFragment}
`;
