
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { Icon } from "@iconify/vue";

import enUK from "@/locales/project/en-UK.json";

import OrderAndDeltaList from "@/components/list/OrderAndDeltaList.vue";
import OrderDetails from "@/components/order/OrderDetails.vue";

import { formatPrice } from "@/helpers/utils";
import { GET_PROJECT_INVOICES_MENU } from "@/graphql/project/queries";
import { useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { useToast } from "vue-toastification";
import checkHasAccess from "@/helpers/permissions/checkHasAccess";
import AddOrder from "@/components/order/AddOrder.vue";
import BasicButton from "@/components/BasicButton.vue";
import { useRoute } from "vue-router";

type MessageSchema = typeof enUK;

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    const toast = useToast();
    const readOnly = ref(true);

    const { result, loading, onError, onResult } = useQuery(
      GET_PROJECT_INVOICES_MENU,
      () => ({
        id: props.id,
      }),
    );

    const invoicesMenuItems = useResult(
      result,
      [],
      (data) => data.project.invoicesMenu,
    );

    const project = useResult(result);

    onError((error) => {
      logErrorMessages(error);
      toast.error(t("errors.getInvoicesMenu"));
    });

    const hasAdminAccess = ref(false);
    const hasClientAccess = ref(false);

    onMounted(async () => {
      hasAdminAccess.value = await checkHasAccess(
        result.value?.project?.client?.id,
        props.id,
        "admin",
        toast,
        t("errors.getUserDetails"),
      );
    });

    onResult(async (result) => {
      const clientId = result.data?.project?.client?.id;
      readOnly.value = !result.data?.project?.active;
      if (!hasAdminAccess.value)
        hasAdminAccess.value = await checkHasAccess(
          clientId,
          props.id,
          "admin",
          toast,
          t("errors.getUserDetails"),
        );
      hasClientAccess.value = await checkHasAccess(
        clientId,
        props.id,
        "client",
        toast,
        t("errors.getUserDetails"),
      );
      await nextTick();
    });

    const isOpened = ref(Array(invoicesMenuItems.value.length).fill(false));

    const isModalDetailsVisible = ref(false);
    const modalDetailsId = ref("");

    function openOrderDetailsModal(id: string): void {
      isModalDetailsVisible.value = true;
      modalDetailsId.value = id;
    }

    function closeOrderDetailsModal(): void {
      isModalDetailsVisible.value = false;
      modalDetailsId.value = "";
    }

    const route = useRoute();

    function isCurrentRoute(routeName: string): boolean {
      return routeName === route.name;
    }

    const isAddOrderModalVisible = ref(false);
    function showAddOrderModal(): void {
      isAddOrderModalVisible.value = true;
    }

    function closeAddOrderModal(): void {
      isAddOrderModalVisible.value = false;
    }

    if (props.orderId) openOrderDetailsModal(props.orderId);

    return {
      t,
      formatPrice,
      isOpened,
      invoicesMenuItems,
      loading,
      isModalDetailsVisible,
      modalDetailsId,
      openOrderDetailsModal,
      closeOrderDetailsModal,
      project,
      isAddOrderModalVisible,
      showAddOrderModal,
      closeAddOrderModal,
      isCurrentRoute,
      hasAdminAccess,
      hasClientAccess,
      readOnly,
    };
  },
  methods: {
    formatDate(date: string): string {
      return dayjs(date).format("MMMM YYYY");
    },
    openMenu(index: number): void {
      this.isOpened[index] = !this.isOpened[index];
    },
  },
  components: {
    BasicButton,
    Icon,
    OrderAndDeltaList,
    OrderDetails,
    AddOrder,
  },
});
