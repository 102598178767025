import gql from "graphql-tag";
import { commentFragment } from "./fragments";

export const CREATE_COMMENT = gql`
  mutation CreateComment($activityId: ID!, $authorId: ID!, $message: String!) {
    createComment(
      activityId: $activityId
      authorId: $authorId
      message: $message
    ) {
      status
      error
      data {
        ...CommentFragment
      }
    }
  }
  ${commentFragment}
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $message: String) {
    updateComment(id: $id, message: $message) {
      status
      error
      data {
        ...CommentFragment
      }
    }
  }
  ${commentFragment}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      status
      error
      data {
        ...CommentFragment
      }
    }
  }
  ${commentFragment}
`;
