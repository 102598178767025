import { createApp, h } from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createI18n } from "vue-i18n";
import Multiselect from "@vueform/multiselect";
import "@/themes/normalize.scss";
import "@/themes/fides.scss";
import "@/themes/multiselect/default.scss";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { vMaska } from "maska";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import App from "@/App.vue";
import "@/registerServiceWorker";
import { initDayjs } from "@/initDayjs";
import router from "@/router";

import enUK from "@/locales/en-UK.json";
import { isWorkFullScreenPlugin } from "./composables/useIsWorkFullScreen";

initDayjs();

const i18n = createI18n<false>({
  legacy: false, // you must set `false`, to use Composition API
  locale: "en-UK",
  messages: {
    "en-UK": enUK,
  },
});
createApp({
  render: () => h(App),
})
  .use(router)
  .use(i18n)
  .use(Toast)
  .use(FloatingVue, { themes: { tooltip: { delay: 50 } } })
  .use(isWorkFullScreenPlugin)
  .directive("maska", vMaska)
  .component("Multiselect", Multiselect)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
