import { ApolloCache } from "@apollo/client";
import { Template, TemplatesResponse } from "../typesDefinition";
import { GET_TEMPLATES } from "@/graphql/orcha/templates/queries";
import { orderedComparator } from "@/helpers/orcha/utils";
import { UpdateCachedEntities } from "@/helpers/cache/typesDefinition";

export function updateTemplatesCache(
  cache: ApolloCache<TemplatesResponse>,
  newItem: Template,
  updateCachedEntities: UpdateCachedEntities<Template>,
): void {
  const graphQLResponse: TemplatesResponse | null = cache.readQuery({
    query: GET_TEMPLATES,
  });

  if (graphQLResponse) {
    const templates = graphQLResponse.templates;

    const updatedItems = updateCachedEntities(
      newItem,
      templates,
      orderedComparator,
    );

    cache.writeQuery({
      query: GET_TEMPLATES,
      data: {
        templates: updatedItems,
      },
    });
  }
}
