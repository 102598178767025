export function transformEurosVariables(
  variables: Record<string, unknown> & { budget?: number },
): Record<string, unknown> {
  console.log({ trandfor: variables });
  return {
    ...variables,
    ...(variables.budget !== undefined
      ? { budget: Math.round(variables.budget * 100) }
      : {}),
  };
}
