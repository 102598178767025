
import { computed, defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { ApolloCache } from "@apollo/client";
import { useI18n } from "vue-i18n";

import enUK from "@/locales/client/en-UK.json";

import MutateEntity from "@/components/CRUXEntity/MutateEntity.vue";

import { CREATE_CLIENT } from "@/graphql/client/mutations";

import ClientsCacheInfoHolder from "@/helpers/client/ClientsCacheInfoHolder";
import { insertNewEntityPaginated } from "@/helpers/cache/insertNewEntity";
import { updatePaginatedQueryCache } from "@/helpers/cache/cache";
import {
  Client,
  ClientPaginatedResponse,
} from "@/helpers/client/typesDefinition";

type MessageSchema = typeof enUK;

export default defineComponent({
  setup() {
    const { t } = useI18n<[MessageSchema], "en-UK">({
      useScope: "global",
      inheritLocale: true,
      messages: {
        "en-UK": enUK,
      },
    });

    function insertNewClient(
      cache: ApolloCache<ClientPaginatedResponse>,
      mutatedItem: Client,
    ) {
      return updatePaginatedQueryCache(
        cache,
        mutatedItem,
        ClientsCacheInfoHolder,
        insertNewEntityPaginated,
      );
    }

    return { t, insertNewClient };
  },
  data() {
    return {
      enUK,
      CREATE_CLIENT,
      entityProps: { name: "" },
      validationRules: computed(() => ({
        name: {
          required,
        },
      })),
      messages: {
        formButtonLabel: this.t("buttons.add"),
        error: this.t("errors.add"),
        success: this.t("success.add"),
      },
    };
  },
  emits: ["close"],
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
  },
  components: {
    MutateEntity,
  },
});
