import { Client, Company } from "./typesDefinition";

export function clientComparator(e1: Client, e2: Client): number {
  if (!e1.name || !e2.name) return 0;
  return e1.name.localeCompare(e2.name);
}

export function companyComparator(e1: Company, e2: Company): number {
  return e1.name.localeCompare(e2.name);
}
