import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a5891ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "client-details"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title-labels" }
const _hoisted_4 = { class: "horizontal-container" }
const _hoisted_5 = { class: "title-value" }
const _hoisted_6 = { class: "client-menu" }
const _hoisted_7 = {
  key: 1,
  class: "loading apollo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_EditClient = _resolveComponent("EditClient")!

  return (_ctx.client)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HeaderBlock, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("labels.name")), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.client.name), 1),
                (_ctx.hasAdminAccess)
                  ? (_openBlock(), _createBlock(_component_IconButton, {
                      key: 0,
                      iconName: "jam:pen-f",
                      hasBorders: false,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditClientModal()))
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.hasAdminAccess)
              ? (_openBlock(), _createBlock(_component_BasicButton, {
                  key: 0,
                  disabled: _ctx.loadingArchive,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.archiveClient()), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("buttons.archive")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Tabs, {
            definitions: [
          {
            title: _ctx.t('titles.projects'),
            route: { name: 'ClientProjects', params: { id: _ctx.$route.params.id } },
          },
          {
            title: _ctx.t('titles.users.users'),
            route: { name: 'ClientUsers', params: { id: _ctx.$route.params.id } },
          },
          {
            title: _ctx.t('titles.companies'),
            route: {
              name: 'ClientCompanies',
              params: { id: _ctx.$route.params.id },
            },
          },
        ]
          }, null, 8, ["definitions"])
        ]),
        _createVNode(_component_router_view),
        (_ctx.hasAdminAccess && _ctx.isEditClientModalVisible)
          ? (_openBlock(), _createBlock(_component_EditClient, {
              key: 0,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeEditClientModal())),
              client: _ctx.client
            }, null, 8, ["client"]))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t("loading")), 1))
      : _createCommentVNode("", true)
}