
import { Entity } from "@/helpers/typesDefinition";
import { DatePickerMarker } from "@vuepic/vue-datepicker";
import { DocumentNode } from "graphql";
import { defineComponent, PropType } from "vue";

import BasicInput from "./BasicInput.vue";
import SelectInputFromQuery from "./SelectInputFromQuery.vue";
import dayjs from "dayjs";

export default defineComponent({
  props: {
    input: {
      type: [String, Number, Boolean, Date],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    query: {
      type: Object as PropType<DocumentNode>,
      required: false,
    },
    mode: {
      type: String as PropType<"single" | "multiple" | "tags">,
      default: "single",
    },
    options: {
      type: Object as PropType<Record<number, string> | Entity[] | number[]>,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String as PropType<
        "basic" | "textArea" | "date" | "inline-date" | "checkbox"
      >,
      default: "basic",
    },
    dateMarkers: {
      type: Array as PropType<DatePickerMarker[]>,
      required: false,
    },
  },
  emits: ["update:input"],
  computed: {
    modelInput: {
      get() {
        return this.input;
      },
      set(value: string | Date) {
        this.$emit(
          "update:input",
          ["date", "inline-date"].includes(this.inputType)
            ? dayjs(value).format("YYYY-MM-DD")
            : value,
        );
      },
    },
  },
  components: { BasicInput, SelectInputFromQuery },
});
