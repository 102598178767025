import gql from "graphql-tag";
import { deltaFragment } from "../delta/fragments";
import { invoiceFragment } from "../invoice/fragments";
import { orderFragment, recurringOrderFragment } from "./fragments";

export const GET_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      ...OrderFragment
      showDetailsOnInvoice
      notes
      legalNotice
      isFirstOrder
      invoiced
      orderLines {
        id
        projectRoleName
        quantity
        hourlyRate
        unit
      }
      company {
        id
        name
      }
    }
  }
  ${orderFragment}
`;

export const GET_RECURRING_ORDER = gql`
  query recurringOrder($id: ID!) {
    recurringOrder(id: $id) {
      ...RecurringOrderFragment
      showDetailsOnInvoice
      paymentOption
      notes
      legalNotice
      isFirstOrder
      orderLines {
        id
        projectRoleName
        quantity
        hourlyRate
        unit
      }
      company {
        id
        name
      }
    }
  }
  ${recurringOrderFragment}
`;

export const GET_ORDERS_DELTAS_AND_INVOICES = gql`
  query ordersDeltasAndInvoices($projectId: ID!, $date: Date!) {
    ordersDeltasAndInvoices(projectId: $projectId, date: $date) {
      ... on Order {
        ...OrderFragment
      }
      ... on Invoice {
        ...InvoiceFragment
        company {
          id
          name
        }
        linkedRessource {
          ... on Order {
            id
          }
          ... on Delta {
            id
          }
        }
      }
      ... on Delta {
        ...DeltaFragment
        invoice {
          ...InvoiceFragment
        }
      }
    }
  }
  ${orderFragment}
  ${deltaFragment}
  ${invoiceFragment}
`;

export const GET_USER_ORDERS = gql`
  query userOrders(
    $startDate: Date!
    $endDate: Date!
    $offset: Int = 0
    $limit: Int = 5
  ) {
    userOrders(
      offset: $offset
      limit: $limit
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      hasMore
      items {
        id
        title
        startDate
        endDate
        status
        price
        orderLines {
          id
          projectRoleName
          unit
          quantity
          hourlyRate
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_UPCOMING_USER_ORDERS = gql`
  query userOrders(
    $startDate: Date!
    $endDate: Date!
    $offset: Int = 0
    $limit: Int = 5
  ) {
    userOrders(
      offset: $offset
      limit: $limit
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      hasMore
      items {
        id
        title
        startDate
        endDate
        status
        project {
          id
        }
      }
    }
  }
`;

export const GET_UPCOMING_USER_RECURRING_ORDERS = gql`
  query userRecurringOrders(
    $startDate: Date!
    $endDate: Date!
    $offset: Int = 0
    $limit: Int = 5
  ) {
    userRecurringOrders(
      offset: $offset
      limit: $limit
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      hasMore
      items {
        id
        title
        startDate
        endDate
        status
        project {
          id
        }
      }
    }
  }
`;

export const ORDERS_TO_REJECT_IF_RECURRING_CANCELED = gql`
  query ordersToRejectIfRecurringCanceled($recurringOrderId: ID!) {
    ordersToRejectIfRecurringCanceled(id: $recurringOrderId) {
      id
      title
      startDate
      endDate
      status
    }
  }
`;
