import { useToast } from "vue-toastification";
import { useQuery, useResult } from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";
import { DocumentNode } from "graphql";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (
  query: DocumentNode,
  errorMessage: string,
  id: string,
  fieldToPagniate: string,
) {
  const toast = useToast();

  const { result, loading, onError, onResult, fetchMore } = useQuery(
    query,
    () => ({
      id,
      offset: 0,
    }),
    { notifyOnNetworkStatusChange: true },
  );

  const entity = useResult(result);

  function loadMore() {
    fetchMore({
      variables: {
        id,
        offset: entity.value?.[fieldToPagniate]?.items?.length,
      },
    });
  }

  onError((error) => {
    logErrorMessages(error);
    toast.error(errorMessage);
  });

  return {
    entity,
    loading,
    loadMore,
    onResult,
    result,
  };
}
