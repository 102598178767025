import { stringCompareWithNullsLast } from "../utils";
import { User, UserResponse } from "./typesDefinition";

export function userComparator(e1: UserResponse, e2: UserResponse): number {
  const user1 = e1.user;
  const user2 = e2.user;
  const firstNameComparator = stringCompareWithNullsLast(
    user1.firstName,
    user2.firstName,
  );
  if (firstNameComparator !== 0) return firstNameComparator;
  const lastNameComparator = stringCompareWithNullsLast(
    user1.lastName,
    user2.lastName,
  );
  if (lastNameComparator !== 0) return lastNameComparator;
  return e1.user.email.localeCompare(e2.user.email);
}

export function fullName(user?: User): string {
  if (!user) return "-";
  return `${user.firstName} ${user.lastName}`;
}

export function userAndUserResponseComparator(
  user: User,
  userResponse: UserResponse,
): number {
  return userComparator({ user }, userResponse);
}
