import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "formLabel" }
const _hoisted_2 = {
  key: 0,
  class: "multiselect-custom-caret"
}
const _hoisted_3 = {
  key: 1,
  class: "multiselect-custom-caret"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectInputFromQuery = _resolveComponent("SelectInputFromQuery")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_BasicInput = _resolveComponent("BasicInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["formItem", _ctx.name])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label) + " :", 1),
    (_ctx.query)
      ? (_openBlock(), _createBlock(_component_SelectInputFromQuery, {
          key: 0,
          query: _ctx.query,
          placeholder: _ctx.placeholder,
          disabled: _ctx.readOnly,
          input: _ctx.modelInput,
          "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelInput) = $event))
        }, null, 8, ["query", "placeholder", "disabled", "input"]))
      : (_ctx.options)
        ? (_openBlock(), _createBlock(_component_Multiselect, {
            key: 1,
            id: "multiselect",
            modelValue: _ctx.modelInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelInput) = $event)),
            mode: _ctx.mode || 'single',
            placeholder: _ctx.placeholder,
            options: _ctx.options,
            closeOnSelect: true,
            canClear: false,
            disabled: _ctx.readOnly,
            valueProp: "id",
            label: "name"
          }, {
            caret: _withCtx(() => [
              (_ctx.readOnly)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, " > "))
            ]),
            _: 1
          }, 8, ["modelValue", "mode", "placeholder", "options", "disabled"]))
        : (_openBlock(), _createBlock(_component_BasicInput, {
            key: 2,
            input: _ctx.modelInput,
            "onUpdate:input": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelInput) = $event)),
            inputId: _ctx.name,
            readOnly: _ctx.readOnly,
            "input-type": _ctx.inputType,
            "date-markers": _ctx.dateMarkers
          }, null, 8, ["input", "inputId", "readOnly", "input-type", "date-markers"])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
      return (_openBlock(), _createElementBlock("p", {
        key: error.$uid
      }, _toDisplayString(error.$message), 1))
    }), 128))
  ], 2))
}